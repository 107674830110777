<template>
  <div class="agent">
    <span class="agent-title">优秀顾问</span>
    <template v-for="a in list" :key="a.id">
      <div class="item">
        <img :src="a.photo" alt="" @click="goShop(a.id)" />
        <div class="info">
          <p>
            <span class="name">{{ a.name.slice(0, 3) }}</span>
            <span class="tag" v-if="a.annoyCommentCount">{{ (a.annoyComment || 0) / (a.annoyCommentCount || 1) }}分</span>
          </p>
          <div class="lev" style="font-size: 12px">主营：{{ a.mainBuildingUse || '住宅,公寓,商铺' }}</div>
          <div class="tel" @click="isMobile = a.id">
            <span class="icon icon-dianhua"></span>
            <span>拨打电话</span>
            <store-mobile v-if="isMobile === a.id" class="mobile-ps" :mobile="a.mobile" :orCode="a.orCode"></store-mobile>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'agent',
  data() {
    return {
      list: [],
      isMobile: false,
    };
  },
  async mounted() {
    const { list } = await http('agent/list', { pageSize: 4 });
    this.list = list;
  },
  methods: {
    goShop(id) {
      const { href } = this.$router.resolve({ name: this.root.name + '.store', query: { id } });
      window.open(href, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.agent {
  padding-top: 80px;
  gap: 30px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  position: relative;
  margin-bottom: 20px;

  &-title {
    position: absolute;
    font-size: 24px;
    font-weight: bold;
    padding-top: 20px;
  }

  .item {
    width: 240px;
    height: 120px;
    box-shadow: 0 0 10px #eee;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .info {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-left: 10px;

      .tag {
        color: var(--color);
        background: var(--color10);
        border-radius: 5px;
        padding: 0 3px;
      }

      .name {
        font-size: 16px;
        font-weight: 600;
        margin-right: 10px;
      }

      .tel {
        position: relative;
        color: var(--color);
        background-color: var(--color10);
        border-radius: 4px;
        padding: 3px 10px;

        .mobile-ps {
          position: absolute;
          left: -100px;
          top: -100px;
        }
      }

      .lev {
        width: 125px;
        padding: 5px 0;
        color: gray;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-align: left;
      }
    }

    img {
      height: 95px;
      width: 75px;
      cursor: pointer;
    }
  }
}
</style>
