<template>
  <div class="detail py-4 mb-2">
    <a-bamboo :bamboo="bamboo" />
    <div class="info">
      <img :src="photo || logo" alt="" class="info-left" />
      <div class="info-right">
        <div class="name">
          <div class="left">
            <div class="left-content">
              <span class="name-label">{{ name || companyName }}</span>
              <span class="agentType">{{ agentType }}</span>
              <div class="mobile" @click.stop="isMobile = true">
                <span class="icon icon-dianhua"></span>
                拨打电话
                <div class="mobile-ps" v-if="isMobile">
                  <store-mobile class="mobile-pv" :mobile="mobile || companyPhone" :orCode="orCode"></store-mobile>
                </div>
              </div>
            </div>
            <p class="info-right-detail-item">
              <span>客户评分:</span>
              <span v-if="annoyCommentCount">{{ parseInt((annoyComment || 0) / (annoyCommentCount || 1)) }}分</span>
              <span v-else>暂无评分</span>
            </p>
          </div>
          <div class="right">
            <div class="right-item" @click="copy">
              <span class="icon icon-fenxiang"></span>
              <span class="right-item-label">分享</span>
            </div>
            <div class="right-item">
              <span class="icon icon-fabulous"></span>
              <span class="right-item-label">关注</span>
            </div>
          </div>
        </div>
        <div class="line"></div>
        <div class="info-right-detail">
          <p class="info-right-detail-item" v-if="id"><span>从业年限:</span>{{ info?.[0]?.value }}</p>
          <p class="info-right-detail-item"><span>服务人数:</span>{{ info?.[4]?.value || customerNum }}</p>
          <p class="info-right-detail-item" v-if="id"><span>所属店铺:</span>{{ company?.aliasName }}-{{ depName }}</p>
          <p class="info-right-detail-item"><span>主营业务:</span>{{ info?.[1]?.value || '住宅、公寓、写字楼等' }}</p>
          <p class="info-right-detail-item" style="grid-column: 1/3" v-if="info?.[3]?.value"><span>主营小区:</span>{{ info?.[3]?.value }}</p>
        </div>
      </div>
    </div>
    <div class="service">
      <p class="service-title">服务承诺</p>
      <section class="item" v-for="a in service.filter((a) => a.isOn)" :key="a.img">
        <img class="item-img" :src="a.img" alt="" />
        <div class="item-body">
          <h1>{{ a.name }}</h1>
          <p>{{ a.desc }}</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'header',
  props: {
    bamboo: Array,
    id: Number,
    annoyComment: Number,
    agentType: String,
    photo: String,
    name: String,
    companyName: String,
    info: Array,
    service: Array,
    company: Object,
    depName: String,
    companyPhone: String,
    mobile: String,
    logo: String,
    customerNum: String,
    annoyCommentCount: String,
    orCode: String,
  },
  data() {
    return {
      isMobile: false,
    };
  },
  methods: {
    copy() {
      this.$copy(location.href);
      alert('链接复制成功');
    },
  },
};
</script>

<style lang="scss" scoped>
.service {
  padding-top: 80px;
  gap: 20px 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 220px);
  position: relative;
  &-title {
    position: absolute;
    font-size: 24px;
    font-weight: bold;
    padding-top: 20px;
  }
  .item {
    width: 220px;
    height: 105px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    padding-top: 2em;
    padding-left: 1em;
    color: white;
    &-img {
      width: inherit;
      height: inherit;
      position: absolute;
      inset: 0;
    }
    &-body {
      position: absolute;
      top: 20px;
      left: 20px;
      text-align: left;
      opacity: 0.9;
      h1 {
        font-size: 18px;
        margin-bottom: 12px;
      }
      p {
        font-size: 16px;
      }
    }
  }
}

.detail {
  padding: 1em 0;
  background-size: cover;
  .info {
    display: flex;
    &-left {
      width: 112px;
      height: 135px;
    }
    &-right {
      padding-left: 4em;
      flex: 1;
      p {
        text-align: left;
      }
      .name {
        font-size: 20px;
        line-height: 30px;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-label {
          display: inline-block;
          margin-right: 4px;
        }
        .agentType {
          font-size: 12px;
          color: #9d9d9d;
        }
        .right {
          display: flex;
          &-item {
            height: 34px;
            border: 1px solid #f4f4f4;
            border-radius: 3px;
            margin-left: 10px;
            padding: 0 15px;
            font-size: 12px;
            cursor: pointer;
            display: flex;
            align-items: center;
            .icon {
              margin-top: 1px;
              margin-right: 3px;
              color: gray;
            }
          }
        }
      }
      &-detail {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1em;
        &-item {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 12px;
          span {
            color: gray;
            display: inline-block;
            margin-right: 8px;
          }
        }
      }
    }
  }
}
.line {
  height: 1px;
  border-bottom: 1.5px dashed #e6e6e6;
  margin: 2rem 0;
}
.mobile {
  display: inline-block;
  width: 112px;
  height: 36px;
  line-height: 36px;
  color: #f8513c;
  font-size: 14px;
  background: #fff7e5;
  text-align: center;
  margin-left: 1em;
  cursor: pointer;
  position: relative;
  .icon {
    color: #ffb501;
    font-size: 20px;
    display: inline-block;
    margin-top: -1px;
  }
  &-ps {
    position: absolute;
    top: -100px;
    left: 118px;
  }
  &-pv {
    position: relative;
    &:after {
      content: '';
      border: 10px solid transparent;
      border-right-color: white;
      position: absolute;
      top: 105px;
      left: -20px;
      filter: drop-shadow(0 0 1rem #666);
    }
    &:before {
      content: '';
      top: 0;
      left: 0;
      right: 0;
      width: 2rem;
      height: 200px;
      position: absolute;
      z-index: 999;
      background: #fff;
      border-radius: 6px;
    }
  }
}
</style>
