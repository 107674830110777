<template>
  <div class="house-box body-item">
    <ul class="house-box-bar">
      <li class="house-box-bar-item" :class="{ isActive: i === menuIndex }" v-for="(a, i) in divisionData" :key="a.title" @click="changeMenu(i)">
        <span class="title">{{ a.title }}</span>
        <span class="num">{{ a.total }}套</span>
      </li>
    </ul>
    <div class="filter">
      <div class="filter-item">照片</div>
      <div class="filter-item">
        <span>面积</span>
        <div class="icon-box">
          <i class="icon icon-arrow-up-filling" :class="{ iconActive: areaSort === 1 }" @click="sort('buildArea', 1)"></i>
          <i class="icon icon-arrow-down-filling" :class="{ iconActive: areaSort === 2 }" @click="sort('buildArea', 2)"></i>
        </div>
      </div>
      <div class="filter-item">
        <span class="price">售价</span>
        <div class="icon-box">
          <i class="icon icon-arrow-up-filling" :class="{ iconActive: priceSaleSort === 1 }" @click="sort('salePrice', 1)"></i>
          <i class="icon icon-arrow-down-filling" :class="{ iconActive: priceSaleSort === 2 }" @click="sort('salePrice', 2)"></i>
        </div>
      </div>
      <div class="filter-item">
        <span class="price">租价</span>
        <div class="icon-box">
          <i class="icon icon-arrow-up-filling" :class="{ iconActive: priceRentSort === 1 }" @click="sort('rentPrice', 1)"></i>
          <i class="icon icon-arrow-down-filling" :class="{ iconActive: priceRentSort === 2 }" @click="sort('rentPrice', 2)"></i>
        </div>
      </div>
      <div class="filter-item" v-if="root.name !== 'factory'">装修</div>
      <div class="filter-item">
        <span>更新</span>
        <div class="icon-box">
          <i class="icon icon-arrow-up-filling" :class="{ iconActive: updateSort === 1 }" @click="sort('createdAt', 1)"></i>
          <i class="icon icon-arrow-down-filling" :class="{ iconActive: updateSort === 2 }" @click="sort('createdAt', 1)"></i>
        </div>
      </div>
    </div>
    <div class="house-show">
      <office-edifice-house-card v-for="a in divisionList" :key="a.id" :item="a" />
      <a href="javascript:void (0)" class="lookMore" v-if="totalDivision[menuIndex]?.data.length > divisionList.length" @click="lookMore">查看更多</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'houseType',
  props: { divisionData: Array },
  data() {
    return {
      menuIndex: 0,
      areaSort: '',
      priceSaleSort: '',
      priceRentSort: '',
      updateSort: '',
      divisionList: [],
      isMore: false,
      size: 10,
      totalDivision: [],
    };
  },
  watch: {
    divisionData: {
      handler(v) {
        if (!v) return false;
        this.divisionList = v[this.menuIndex].data.slice(0, 10);
        this.totalDivision = v;
      },
      immediate: true,
    },
  },
  methods: {
    changeMenu(i) {
      this.menuIndex = i;
      this.divisionList = this.totalDivision[this.menuIndex].data.slice(0, 10);
    },
    lookMore() {
      let size = this.size;
      this.size += 10;
      this.divisionList = this.divisionList.concat(this.totalDivision[this.menuIndex]?.data.slice(size, this.size));
    },
    sort(m, n) {
      if (m === 'buildArea') this.areaSort = n;
      if (m === 'createdAt') this.updateSort = n;
      if (m === 'salePrice') this.priceSaleSort = n;
      if (m === 'rentPrice') this.priceRentSort = n;
      this.totalDivision[this.menuIndex].data.sort(this.compare(m, n));
      this.divisionList = this.totalDivision[this.menuIndex].data.slice(0, 10);
    },
    compare(property, n) {
      return function (a, b) {
        let value1 = a[property];
        let value2 = b[property];
        if (n === 1) return parseInt(value1) - parseInt(value2);
        if (n === 2) return parseInt(value2) - parseInt(value1);
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.body-item {
  background: white;
  padding: 0 30px 20px 30px;
  ul {
    display: flex;
    border-bottom: 1px solid var(--color);
    .isActive {
      background-color: var(--color);
      > * {
        color: white !important;
      }
    }
    li {
      width: 100px;
      cursor: pointer;
      text-align: center;
      padding: 32px 0 10px 0;
      list-style-type: none;
      .title {
        color: #000000;
        font-size: 12px;
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        display: block;
        &:hover {
          color: var(--color);
        }
      }
      .num {
        color: #b2b2b2;
        font-size: 12px;
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        display: block;
        padding-top: 5px;
      }
    }
  }
  .filter {
    width: 100%;
    margin-top: 20px;
    display: grid;
    grid-template-columns: 100px 1fr 1fr 1fr 60px 60px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    &-item {
      height: 40px;
      color: #000000;
      font-size: 12px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon-box {
        display: flex;
        flex-direction: column;
        padding-left: 4px;
      }
      .icon {
        font-size: 7px;
        color: #b2b2b2;
        cursor: pointer;
      }
      .iconActive {
        color: var(--color);
      }
      .price {
        cursor: pointer;
      }
      .dot {
        font-size: 8px;
        margin: 0 3px;
      }
    }
  }
  .lookMore {
    display: block;
    width: 400px;
    height: 36px;
    line-height: 34px;
    margin: 30px auto 0 auto;
    text-align: center;
    font-size: 12px;
    color: #666;
    border-radius: 6px;
    border: 1px solid #ccc;
    &:hover {
      color: var(--color);
      border: 1px solid var(--color);
    }
  }
}
</style>
