<template>
  <div class="match">
    <h3>商务中心配套</h3>
    <ul class="match-box">
      <li class="item" v-for="a in matchingName" :key="a.name">
        <img class="item-img" :src="a.url" alt="" />
        <span class="item-name">{{ a.name }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'introduce',
  props: { matchingName: Array },
};
</script>

<style lang="scss" scoped>
.match {
  padding: 0 30px 30px 30px;
  background-color: #fff;
  margin-bottom: 2rem;
  h3 {
    padding: 18px 0;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 14px;
    font-weight: 600;
    text-align: left;
  }
  &-box {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    .item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      &-img {
        width: 32px;
        height: 32px;
      }
      &-name {
        font-size: 12px;
        color: #353535;
        margin-left: 4px;
      }
    }
  }
}
</style>
