<template>
  <div class="fd card" @click="detail">
    <div class="card-img">
      <img :src="obj.fileImg" alt="封面" />
      <img v-if="obj.panorama" src="https://static.ajl.cn/web/360.png" class="panorama" alt="全景图标" />
    </div>
    <div class="card-body">
      <div class="line">
        <div class="line-name" v-if="isOne || type === 'first'">
          <span>{{ obj.title }}</span>
          <b v-bg:blue>{{ obj.projectStatusName }}</b>
          <b v-bg:orange>{{ obj.buildingUseName }}</b>
        </div>
        <span class="line-name" v-else> {{ obj.title || obj.buildingName }} </span>
      </div>
      <div class="line">
        <span class="icon icon-office"></span>
        <template v-if="!isOne && type !== 'first'">
          <span>{{ obj.room }}</span>
          <span>{{ obj.office }}</span>
          <span>{{ obj.toilet }}</span>
          <span>|</span>
        </template>
        <span>{{ obj.buildArea || obj.area }}</span>
        <template v-if="!isOne && type !== 'first'">
          <span class="px-1">·</span>
          <span>{{ obj.orientationName }}</span>
          <span class="px-1">·</span>
          <span>{{ obj.floor }}|{{ obj.totalFloor }}层</span>
          <span class="px-1">·</span>
          <span>{{ obj.renovationName }}</span>
        </template>
        <template v-if="isOne || type === 'first'">
          <span class="line-price">
            <span>{{ $cov(obj.priceSingleSet).num || '-' }}</span>
            <sub>{{ $cov(obj.priceSingleSet).unit }}</sub>
          </span>
        </template>
        <span class="line-price" v-else>
          <template v-if="$route.name.includes('rent') || type === 'rent'">
            <span>{{ $cov(obj.rentPrice).num || '-' }}</span>
            <sub>{{ $cov(obj.rentPrice).unit }}</sub>
          </template>
          <template v-else>
            <span>{{ $cov(obj.salePrice).num || '-' }}</span>
            <sub>{{ $cov(obj.salePrice).unit }}</sub>
          </template>
        </span>
      </div>
      <div class="line">
        <span class="icon icon-map"></span>
        <span class="line-text">
          <span class="btn">{{ obj.regionName }}</span>
          <span class="px-1">·</span>
          <span class="btn">{{ obj.cateCircleName }}</span>
          <template v-if="!isOne">
            <span class="px-1">·</span>
            <span>{{ obj.buildingName }}</span>
          </template>
        </span>
      </div>
      <div class="line">
        <span class="icon icon-chart-pie"></span>
        <span>{{ obj.releaseTime }}</span>
        <span class="px-1">·</span>
        <span>近30天内共有{{ obj.collectNum }}人关注</span>
        <span style="margin-left: auto" v-if="$route.name.match('sale') || type === 'sale'">
          <span v-if="isOne || type === 'first'">均价:{{ obj.averagePrice || '-' }}</span>
          <span v-else>{{ obj.saleSinglePrice }}</span>
        </span>
      </div>
      <div class="line" v-if="root.name !== 'house'">
        <template v-if="obj.division?.length">
          <span class="line-area btn" v-for="a in obj.division.slice(0, 6)" :key="a" @click="childDetail(a.id)">{{ a.area }}</span>
          <span class="line-area btn line-area-more" @click="detail">···</span>
        </template>
      </div>
      <div class="line">
        <b class="line-tag" v-for="a in tags(obj.houseTagName)" :key="a">{{ a }}</b>
        <span class="btn more">查看详情</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'house-list-card',
  props: { obj: Object, type: String },
  methods: {
    tags(a) {
      if (a) return a.split(',');
      return ['随时看'];
    },
    detail() {
      let from = this.$route.name ?? '';
      if (this.type === 'sale' || this.type === 'rent') from = this.root.name + '.' + this.type;
      if (this.type === 'first') from = this.root.name + '.' + 'first-sale';
      const query = { id: this.obj.id, from };
      if (query.from.includes('first')) return this.$router.push({ name: 'house.building-detail', query });
      const { href } = this.$router.resolve({ name: 'house.house-detail', query });
      window.open(href, '_blank');
    },
    childDetail(id) {
      let name = 'house.room-detail';
      let from = this.$route.name ?? '';
      if (this.type === 'sale' || this.type === 'rent') from = this.root.name + '.' + this.type;
      if (this.type === 'first') from = this.root.name + '.' + 'first-sale';
      if (from.includes('first')) name = 'house.first-detail';
      if (this.root.name === 'office' && this.type === 'rent') name = 'office.room-detail';
      const { href } = this.$router.resolve({ name, query: { id, from } });
      window.open(href, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  flex: 1;
  height: 200px;
  margin-bottom: 2rem;
  display: grid;
  padding: 2rem;
  grid-template-columns: 230px 1fr;
  place-content: center;
  align-content: stretch;
  border-bottom: 1px solid rgba(black, 0.15);
  cursor: pointer;
  &:hover {
    background-color: white;
    border-bottom-color: transparent;
  }

  &-img {
    border-radius: 6px;
    overflow: hidden;
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }

    .panorama {
      width: 30px;
      height: 30px;
      position: absolute;
      left: 10px;
      bottom: 10px;
      animation-name: ani;
      animation-duration: 2.6s;
      animation-iteration-count: infinite;
    }
  }

  &-body {
    padding: 1rem 2rem;

    &:hover {
      .line > .more {
        opacity: 1;
      }
    }

    .line {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;
      font-size: 2rem;

      &-name {
        width: 0;
        flex: 1;
        font-size: 3rem !important;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-weight: bold;
        text-align: left;
        b {
          font-size: 1rem;
          font-weight: normal;
          color: white;
          margin: auto 1rem;
          padding: 0.5rem;
          border-radius: 4px;
        }
      }

      &-price {
        margin-left: auto;
        display: flex;
        align-items: flex-end;
        color: red;
        height: 0;
        transform: translateY(4rem);
        span {
          margin-right: 0.5rem;
          font-weight: bold;
          font-size: 4rem;
        }

        sub {
          font-size: 2rem;
          padding-bottom: 6px;
        }
      }

      &-area {
        padding: 0.5rem 1rem;
        border: 1px solid #ccc;
        margin: 0 -1px -1px 0;
        opacity: 0.6;

        &:hover {
          color: var(--color);
        }

        &:nth-child(1) {
          border-radius: 0.5rem 0 0 0.5rem;
        }

        &:nth-last-child(2) {
          font-weight: bold;
          border-radius: 0 0.5rem 0.5rem 0;
        }

        &:hover {
          border-color: var(--color);
        }
      }

      &-tag {
        color: var(--color);
        background-color: var(--color10);
        margin: 2px 0.5em;
        padding: 2px 4px;
        font-size: 1rem;
      }

      .icon {
        margin-right: 0.5rem;
        font-size: 3rem;
      }

      .more {
        @extend .dye;
        margin-left: auto;
        opacity: 0;
        padding: 1rem 2rem;
        border-radius: 6px;
      }
    }
  }
}

@keyframes ani {
  70% {
    transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(360deg) scale(1.2);
  }
}
</style>
