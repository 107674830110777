<template>
  <ul class="bamboo">
    <li v-for="a in bamboo" :key="a" @click="change(a)">{{ a.title }}</li>
  </ul>
</template>

<script>
export default {
  props: { bamboo: Array },
  methods: {
    change(a) {
      let name = '';
      let i = this.bamboo.indexOf(a);
      if (i === 3) return false;
      const base = this.$route.matched[0].name;
      if (a.title === '首页') return this.$router.push({ name: base });
      if (this.root.name === 'house') {
        if (a.title.match('楼盘')) name = 'first-sale';
        if (a.title.match('二手房')) name = 'sale';
        if (a.title.match('租房')) name = 'rent';
      } else {
        if (a.title.match('出售')) {
          name = a.title.match('一手') ? 'first-sale' : 'sale';
        }
        if (a.title.match('出租')) {
          name = a.title.match('一手') ? 'first-rent' : 'rent';
        }
      }

      let query = {};
      if (a.params.region) query.region = a.params.region;
      if (a.params.cateCircle) query.cateCircle = a.params.cateCircle;
      this.$router.push({ name: base + '.' + name, query });
    },
  },
};
</script>

<style lang="scss" scoped>
.bamboo {
  display: flex;
  width: 1050px;
  margin: auto;
  font-size: 2rem;
  list-style: none;
  padding: 1rem 1rem 1rem 0;
  li {
    cursor: default;
    &:not(:last-child) {
      cursor: pointer;
      &:hover {
        color: var(--color);
      }
      &:after {
        content: '>';
        margin: auto 2rem;
      }
    }
  }
}
</style>
