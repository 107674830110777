<template>
  <div class="filter">
    <div class="line">
      <span class="line-name">位置</span>
      <div class="box">
        <ul class="in" v-show="isRegion">
          <li class="btn" :class="{ isActive: regionIndex === '' }" @click="choose('region', '', '')">全市区域</li>
          <li class="btn" :class="{ isActive: regionIndex === i }" v-for="(a, i) in region" :key="a.code" @click="getCircle(a.code, i)">{{ a.name }}</li>
        </ul>
        <hr v-show="cateCircleList.length" />
        <ul class="in" v-show="cateCircleList.length">
          <template v-for="(a, i) in cateCircleList" :key="a.code">
            <li class="btn" :class="{ isActive: cateCircleIndex === i }" @click="chooseCircle(a.code, i)">{{ a.name }}</li>
          </template>
        </ul>
      </div>
    </div>
    <div class="line">
      <span class="line-name">面积</span>
      <ul class="in">
        <li class="btn" :class="{ isActive: areaIndex === '' }" @click="choose('area', '', '')">空间不限</li>
        <li class="btn" :class="{ isActive: areaIndex === i }" @click="choose('area', i, a)" v-for="(a, i) in area" :key="i">{{ a.name }}</li>
      </ul>
    </div>
    <div class="line">
      <span class="line-name">{{ isSale ? '售价' : '租价' }}</span>
      <ul class="in">
        <li class="btn" :class="{ isActive: priceIndex === '' }" @click="choose('price', '', '')">{{ isSale ? '售价' : '租价' }}不限</li>
        <li class="btn" :class="{ isActive: priceIndex === i }" @click="choose('price', i, a)" v-for="(a, i) in isSale ? salePrice : rentPrice" :key="i">{{ a.name }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'factory-filter',
  data() {
    return {
      isArea: true,
      isRegion: true,
      isSale: false,
      area: [], //面积
      areaIndex: '',
      renovation: [], //装修
      renovationIndex: '',
      salePrice: [], //售价
      rentPrice: [], //租价
      priceIndex: '',
      people: [], //人数
      peopleIndex: '',
      region: [], //区域一级
      regionIndex: '',
      cateCircleBase: [], //所有商圈
      cateCircleList: [], //展示的商圈
      cateCircleIndex: '',
      metro: [], //地铁一级
      obj: {
        page: '',
        pageSize: '',
        tradeType: 1,
        buildingUse: '7,9',
        region: '', //区域
        cateCircle: '', //商圈
        houseUse: '', //联合办公/开放工位/写字楼houseUse4
        buildArea: [], //写字楼面积
        renovation: '', //装修
        price: [], //出售价格
        rentPrice: [], //出租价格
      },
    };
  },
  methods: {
    search() {
      clearTimeout(this.oisId);
      this.oisId = setTimeout(() => {
        this.$emit('search', this.obj);
      }, 50);
    },
    choose(m, i, a) {
      if (m === 'region') {
        this.cateCircleList = [];
        this.obj.region = '';
        this.obj.cateCircle = '';
        this.regionIndex = i;
      }
      if (m === 'area') {
        this.areaIndex = i;
        this.obj.buildArea = a ? [{ min: a.min, max: a.max }] : [];
      }
      if (m === 'price') {
        this.priceIndex = i;
        let v = this.isSale ? 'price' : 'rentPrice';
        this.obj[v] = a ? [{ min: a.min, max: a.max }] : [];
      }
      if (m === 'renovation') {
        this.renovationIndex = i;
        this.obj.renovation = a.code ?? '';
      }
      this.search();
    },
    async getBaseData() {
      app.dict.BaseData?.map(({ dicCode, subData }) => {
        if (dicCode === 'renovation') this.renovation = subData;
        if (dicCode === 'areaList') this.area = subData;
        if (dicCode === 'salePrice') this.salePrice = subData;
        if (dicCode === 'rentPrice') this.rentPrice = subData;
        if (dicCode === 'people') this.people = subData;
      });
      this.region = app.dict.AreaData ?? [];
      this.cateCircleBase = app.dict.TradingAreaData ?? [];
    },
    getCircle(code, i) {
      this.obj.cateCircle = '';
      this.cateCircleIndex = null;
      const arr = [];
      this.cateCircleBase.map((a) => a.parentCode === code && arr.push(a));
      this.cateCircleList = arr;
      this.regionIndex = i;
      this.obj.region = code;
      this.search();
    },
    chooseCircle(code, i) {
      this.cateCircleIndex = i;
      this.obj.cateCircle = code;
      this.search();
    },
    async init() {
      await this.getBaseData();
      if (this.$route.fullPath.includes('sale')) this.isSale = true;
      if (this.$route.query.regionIndex) {
        this.regionIndex = this.$route.query.regionIndex * 1;
        this.obj.region = this.region[this.regionIndex].code;
      }
      if (this.$route.query.areaIndex) {
        this.areaIndex = this.$route.query.areaIndex * 1;
        this.obj.buildArea = [{ min: this.area[this.areaIndex].min, max: this.area[this.areaIndex].max }];
      }
      if (this.$route.query.region) {
        this.obj.region = this.$route.query.region;
        this.region.map((_) => {
          if (_.code !== this.obj.region) return false;
          let index = this.region.indexOf(_);
          this.regionIndex = index;
          if (this.$route.query.cateCircle) {
            this.getCircle(_.code, index);
            this.obj.cateCircle = this.$route.query.cateCircle;
            this.cateCircleList.map((a) => {
              if (a.code === this.obj.cateCircle) this.cateCircleIndex = this.cateCircleList.indexOf(a);
            });
          }
        });
      }
      if (this.$route.query.content) this.obj.content = this.$route.query.content;
      if (this.$route.name.includes('sale')) this.obj.tradeType = 2;
      this.search();
    },
  },
  created() {
    this.init();
  },
};
</script>
