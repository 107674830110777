<template>
  <div class="introduce p-4">
    <div class="introduce-title f-5 px-2 mb-4" v-col:1-5>
      <b v-if="type === 'room'">所属-{{ $attrs.buildingName }}</b>
      <b v-else>{{ root.projectName }}简介</b>
      <span class="btn" @click="showMore">查看更多</span>
    </div>
    <img class="introduce-img br-5 mr-2" v-size:180*120 :src="buildingData?.fileImg" alt="fileImg" />
    <div class="introduce-item" v-col:2-5>
      <span>地理位置</span>
      <span>{{ buildingData?.address || buildingAddress }}</span>
    </div>
    <div class="introduce-item">
      <span>交房时间</span>
      <span>{{ $attrs.handingDate || '暂无数据' }}</span>
    </div>
    <div class="introduce-item">
      <span>物业用途</span>
      <span>{{ $attrs.buildingUseName || '暂无数据' }}</span>
    </div>
    <div class="introduce-item">
      <span>物业类型</span>
      <span :title="buildingData?.buildingTypeName">{{ buildingData?.buildingTypeName || '暂无数据' }}</span>
    </div>
    <div class="introduce-item">
      <span>供水供电</span>
      <span>
        {{ buildingData?.waterSupplyName || '暂无数据' }}
        <i>|</i>
        {{ buildingData?.powerSupplyName || '暂无数据' }}
      </span>
    </div>
    <div class="introduce-item">
      <span>总户数</span>
      <span>{{ buildingData?.totalHouseholdNum || '暂无数据' }}</span>
    </div>
    <div class="introduce-item">
      <span>总栋数</span>
      <span>{{ buildingData?.totalTungNum || '暂无数据' }}</span>
    </div>
  </div>
</template>

<script>
import ProjectInfo from './sub/project-info';
export default {
  name: 'introduce',
  props: {
    buildingData: Object,
    buildingAddress: String,
  },
  methods: {
    detail() {
      this.$router.push({ name: this.root.name + '.edifice-detail', query: { id: this.buildingData.buildingId } });
    },
    async showMore() {
      const obj = { ...this.$attrs, ...this.$props };
      console.log(obj);
      await this.$modal(ProjectInfo, obj);
    },
  },
};
</script>
<style lang="scss" scoped>
.introduce {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  background-color: white;
  &-title {
    display: flex;
    position: relative;
    .btn {
      font-size: 12px;
      color: var(--color);
      cursor: pointer;
      margin-left: auto;
    }
    &:before {
      content: '';
      position: absolute;
      top: 4px;
      bottom: 4px;
      left: 0;
      width: 2px;
      background-color: var(--color);
    }
  }
  &-img {
    grid-column: 1/2;
    grid-row: 2/5;
  }
  &-item {
    padding-top: 1rem;
    padding-left: 1rem;
    text-align: left;
    span:first-of-type {
      color: gray;
      min-width: 10em;
    }
    span:last-of-type {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>
