<template>
  <div class="layout bg-light">
    <div class="layout-nav">
      <template v-for="(a, k) in layout" :key="k">
        <span class="py-2 my-2 mx-4" :class="{ ac: k === showList[0].name && showList !== list }" @click="showList = a">{{ k }}({{ a.length }})</span>
      </template>
      <span class="py-2 my-2 mx-4 end" :class="{ ac: showList === list }" @click="showList = list">全部户型</span>
    </div>
    <div class="layout-body pb-4">
      <div class="card" v-for="a in showList" :key="a.id">
        <img :src="a.url" :alt="a.layout" />
        <hr />
        <p class="px-4 py-1 d-r d-sb">
          <span>户型</span>
          <span>{{ a.layout }}</span>
        </p>
        <p class="px-4 py-1 d-r d-sb">
          <span>面积</span>
          <span>{{ a.area }}</span>
        </p>
        <p class="px-4 py-1 d-r d-sb">
          <span>朝向</span>
          <span>{{ a.orientationName }}</span>
        </p>
        <p class="px-4 py-1 d-r d-sb">
          <span>估算总价</span>
          <span v-color:red>{{ (parseFloat(a.area) * parseFloat($attrs.price)) / 10000 }}万/套</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'layout-list',
  props: { list: Array },
  data() {
    return {
      showList: [],
    };
  },
  computed: {
    layout() {
      const obj = {};
      const list = this.list ?? [];
      for (const a of list) {
        if (!/\d(?=室)/g.test(a.layout)) a.name = '未知';
        else if (/\d(?=室)/g.exec(a.layout)[0] === '1') a.name = '一室';
        else if (/\d(?=室)/g.exec(a.layout)[0] === '2') a.name = '两室';
        else if (/\d(?=室)/g.exec(a.layout)[0] === '3') a.name = '三室';
        else if (/\d(?=室)/g.exec(a.layout)[0] === '4') a.name = '四室';
        else if (/\d(?=室)/g.exec(a.layout)[0] === '5') a.name = '五室';
        else if (/\d(?=室)/g.exec(a.layout)[0] === '6') a.name = '六室';
        else if (/\d(?=室)/g.exec(a.layout)[0] === '7') a.name = '七室';
        else if (/\d(?=室)/g.exec(a.layout)[0] === '8') a.name = '八室';
        else if (/\d(?=室)/g.exec(a.layout)[0] === '9') a.name = '九室';
        else if (/\d(?=室)/g.exec(a.layout)[0] > 9) a.name = '多室';
      }
      for (const a of list.sort((a, b) => parseInt(a.layout) - parseInt(b.layout))) {
        if (!obj[a.name]) obj[a.name] = [];
        obj[a.name].push(a);
      }
      return obj;
    },
  },
  created() {
    this.showList = Object.values(this.layout)[0];
  },
};
</script>

<style lang="scss" scoped>
.layout {
  &-nav {
    display: flex;
    span {
      display: inline-block;
      &.ac {
        color: var(--color);
        border-bottom: 2px solid var(--color);
      }
    }
    .end {
      margin-left: auto;
    }
  }
  &-body {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    .card {
      width: 200px;
      height: 300px;
      box-shadow: 0 0 1rem #eee;
      img {
        width: inherit;
        height: 200px;
        object-fit: contain;
        background-color: white;
      }
    }
  }
}
</style>
