<template>
  <ul class="a-pager" v-if="pages">
    <li class="icon icon-arrow-left-filling" @click="page - 1 !== 0 && change(page - 1)" :class="{ not: page - 1 === 0 }"></li>
    <li v-for="a in pages" :key="a" @click="change(a)" :class="{ ac: page === a }">{{ a }}</li>
    <li class="icon icon-arrow-right-filling" :class="{ not: page - size === 0 }" @click="page - size !== 0 && change(page + 1)"></li>
  </ul>
</template>

<script>
export default {
  name: 'a-pager',
  props: {
    page: Number,
    count: Number,
    pageSize: Number,
    topEl: Element,
  },
  computed: {
    size() {
      return this.count % this.pageSize === 0 ? this.count / this.pageSize : Math.floor(this.count / this.pageSize) + 1;
    },
    pages() {
      if (this.pageSize < 1) return false;
      const size = this.count % this.pageSize === 0 ? this.count / this.pageSize : Math.floor(this.count / this.pageSize) + 1;
      if (size <= 12) return size;
      const arr = [1];
      if (this.page - 2 > 2) arr.push('···');
      if (this.page - 2 > 1) arr.push(this.page - 2);
      if (this.page - 1 >= 2) arr.push(this.page - 1);
      if (this.page !== 1 && this.page !== size) arr.push(this.page);
      if (this.page + 1 < size) arr.push(this.page + 1);
      if (this.page + 2 < size) arr.push(this.page + 2);
      if (this.page + 4 < size) arr.push('···');
      if (size > 1) arr.push(size);
      return arr;
    },
  },
  methods: {
    async change(a) {
      if (this.topEl) {
        this.topEl.scrollIntoView();
      } else {
        window.goTop();
      }
      if (typeof a === 'number') this.$emit('update:page', a);
    },
  },
};
</script>

<style lang="scss" scoped>
.a-pager {
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  list-style: none;
  padding: 1rem;
  margin-bottom: 1rem;
  max-width: 960px;
  overflow: hidden;

  li {
    padding: 1rem 2rem;
    font-size: 2rem;
    cursor: pointer;
    border-radius: 0.5rem;
    margin: 1px;
    &:hover {
      background-color: rgba(black, 0.1);
      color: var(--color);
    }
    &.ac {
      background-color: rgba(black, 0.1);
      color: var(--color);
    }

    &.not {
      opacity: 0.5;

      &:hover {
        background-color: unset;
        cursor: not-allowed;
      }
    }
  }
}
</style>
