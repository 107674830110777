import modal from './modal/modal';
export default function (app) {
  app.use(modal);
  app.directive('size', (e, c) => {
    let [w, h] = c.arg.toString().split('*');
    if (undefined === h) h = w;
    e.style.width = /%/.test(w) ? w : w + 'px';
    e.style.height = h + 'px';
  });
  app.directive('color', (e, c) => {
    e.style.color = c.arg;
  });
  app.directive('bg', (e, c) => {
    e.style.backgroundColor = c.arg;
  });
  app.directive('col', (e, c) => {
    let [start, end] = c.arg.toString().split('-');
    e.style.gridColumn = start + '/' + end;
  });
  app.config.globalProperties.$cov = (str = '') => {
    str = String(str);
    const num = /[\d-\s]+/.exec(str)?.[0] ?? '';
    return { num, unit: str.replace(num, '') };
  };
  app.config.globalProperties.$copy = function (text) {
    const el = document.createElement('textarea');
    el.setAttribute('readonly', 'readonly');
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    el.remove();
  };
}
