<template>
  <div class="info">
    <div class="info-title f-4 p-4 bg-gray">项目详情</div>
    <div class="px-2 pb-4">
      <b class="d-r p-2">基础信息</b>
      <div class="info-body">
        <div class="info-body-item">
          <span>楼盘名称</span>
          <span>{{ $attrs.buildingName }}</span>
        </div>
        <div class="info-body-item">
          <span>楼盘别名</span>
          <span>{{ $attrs.buildingData.buildingAlias }}</span>
        </div>
        <div class="info-body-item">
          <span>楼盘特色</span>
          <span>{{ $attrs.sellingPoint }}</span>
        </div>
        <div class="info-body-item">
          <span>参考均价</span>
          <span>{{ $attrs.averagePrice }}</span>
        </div>
        <div class="info-body-item">
          <span>参考总价</span>
          <span>{{ $attrs.priceSingleSet }}</span>
        </div>
        <div class="info-body-item">
          <span>物业类型</span>
          <span>{{ $attrs.buildingData?.buildingTypeName }}</span>
        </div>
        <div class="info-body-item">
          <span>物业用途</span>
          <span>{{ $attrs.buildingUseName }}</span>
        </div>
        <div class="info-body-item">
          <span>建筑年份</span>
          <span>{{ $attrs.buildingData?.year }}</span>
        </div>
        <div class="info-body-item">
          <span>建筑结构</span>
          <span>{{ $attrs.architecturalTypeName }}</span>
        </div>
        <div class="info-body-item">
          <span>建筑类型</span>
          <span>{{ $attrs.buildingData?.blockTypeName }}</span>
        </div>
        <div class="info-body-item">
          <span>装修标准</span>
          <span>{{ $attrs.renovationName }}</span>
        </div>
        <div class="info-body-item">
          <span>产权年限</span>
          <span>70</span>
        </div>
        <div class="info-body-item">
          <span>开发商</span>
          <span>{{ $attrs.blackHole }}</span>
        </div>
        <div class="info-body-item">
          <span>所在区域</span>
          <span>{{ $attrs.regionName }}-{{ $attrs.cateCircleName }}</span>
        </div>
        <div class="info-body-item" style="grid-column: 1/4">
          <span>楼盘地址</span>
          <span>{{ $attrs.buildingData.mapName }}</span>
        </div>
      </div>
      <hr />
      <b class="d-r p-2">销售信息</b>
      <div class="info-body">
        <div class="info-body-item">
          <span>项目状态</span>
          <span> 在售 </span>
        </div>

        <div class="info-body-item">
          <span>售卖楼栋</span>
          <span> 暂无 </span>
        </div>

        <div class="info-body-item">
          <span>售卖户型</span>
          <span> 暂无 </span>
        </div>
        <div class="info-body-item">
          <span>推荐标签</span>
          <span> {{ $attrs.houseTagName }} </span>
        </div>
        <div class="info-body-item">
          <span>最新开盘</span>
          <span> {{ $attrs.openningDate }}</span>
        </div>
        <div class="info-body-item">
          <span>最近交房</span>
          <span> {{ $attrs.handingDate }} </span>
        </div>
        <div class="info-body-item" style="grid-column: 1/4">
          <span>售楼地址</span>
          <span> {{ $attrs.buildingAddress }} </span>
        </div>
      </div>
      <hr />
      <b class="d-r p-2">小区概况</b>
      <div class="info-body">
        <div class="info-body-item">
          <span>占地面积</span>
          <span> {{ $attrs.buildingData?.totalFloorArea }}</span>
        </div>
        <div class="info-body-item">
          <span>建筑面积</span>
          <span> {{ $attrs.buildingData?.totalBuildingArea }} </span>
        </div>
        <div class="info-body-item">
          <span>容积率</span>
          <span> {{ $attrs.buildingData?.plotRatio }}</span>
        </div>

        <div class="info-body-item">
          <span>绿化率</span>
          <span>{{ $attrs.buildingData?.greeningRate }}%</span>
        </div>

        <div class="info-body-item">
          <span>地上车位数</span>
          <span>{{ $attrs.buildingData?.parkingNumUp }}个</span>
        </div>

        <div class="info-body-item">
          <span>地下车位数</span>
          <span> {{ $attrs.buildingData?.parkingNumBottom }}个</span>
        </div>

        <div class="info-body-item">
          <span>车位配比</span>
          <template v-if="$attrs.totalHouseholdParkingNum">
            <span>{{ $attrs.totalHouseholdParkingNum }}</span>
          </template>
        </div>

        <div class="info-body-item">
          <span>规划户数</span>
          <span>{{ $attrs.buildingData?.totalHouseholdNum }}</span>
        </div>

        <div class="info-body-item">
          <span>规划栋数</span>
          <span> {{ $attrs.buildingData?.totalTungNum }}</span>
        </div>

        <div class="info-body-item">
          <span>物业公司</span>
          <span>{{ $attrs.buildingData?.propertyName }}</span>
        </div>

        <div class="info-body-item">
          <span>物业费用</span>
          <span>{{ $attrs.buildingData?.propertyPrice }}</span>
        </div>

        <div class="info-body-item">
          <span>供水</span>
          <span>{{ $attrs.buildingData?.waterSupplyName }}</span>
        </div>

        <div class="info-body-item">
          <span>供电</span>
          <span>{{ $attrs.buildingData?.propertyPrice }}</span>
        </div>

        <div class="info-body-item">
          <span>供暖</span>
          <span>{{ $attrs.buildingData?.warmSupplyName }}</span>
        </div>

        <div class="info-body-item">
          <span>门窗材质</span>
          <span>{{ $attrs.buildingData?.doorProfileName }}</span>
        </div>

        <div class="info-body-item">
          <span>建筑外墙</span>
          <span>{{ $attrs.buildingData?.exteriorWallBuildingName }}</span>
        </div>

        <div class="info-body-item">
          <span>电梯品牌</span>
          <span>{{ $attrs.buildingData?.elevatorBrand }}</span>
        </div>
        <div class="info-body-item">
          <span>安全配置</span>
          <span>{{ $attrs.buildingData?.securityConfigurationName }}</span>
        </div>
      </div>
      <hr />
      <b class="d-r p-2">售卖信息</b>
      <table style="width: 100%" class="f-2">
        <tr>
          <th>预售证号</th>
          <th>预售证有效期</th>
          <th>批准上市套数</th>
          <th>批准上市面积</th>
          <th>批准上市楼栋</th>
        </tr>
        <tr v-for="(a, i) in $attrs.presell" :key="i">
          <td>{{ a.certificateNo }}</td>
          <td>{{ a.validityData }}</td>
          <td>{{ a.marketNum }}</td>
          <td>{{ a.marketArea }}</td>
          <td>{{ a.marketBlock }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'project-info',
  methods: {
    mod(a, b) {
      if (!a) a = 1;
      if (!b) b = 1;
      const A0 = a;
      const B0 = b;
      while (a !== b) {
        if (a > b) {
          a = a - b;
        } else {
          b = b - a;
        }
      }
      return `${A0 / a}:${B0 / a}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.info {
  width: 800px;
  height: 500px;
  background-color: white;
  text-align: left;
  overflow: auto;
  &-title {
    position: sticky;
    top: 0;
  }
  &-body {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    &-item {
      display: flex;
      padding: 1rem;
      span:first-of-type {
        font-size: 1rem;
        min-width: 10em;
        color: gray;
      }
    }
  }
  table {
    border-collapse: collapse;
    width: 100%;
    border: 1px solid gray;
    tr {
      line-height: 2;
      &:nth-child(odd) {
        background-color: #eee;
      }
      th {
        border-collapse: collapse;
        border-right: 1px solid gray;
        border-bottom: 1px solid gray;
        background-color: var(--color10);
        text-align: center;
      }
      td {
        border-collapse: collapse;
        border-right: 1px solid gray;
        border-bottom: 1px solid gray;
        text-align: center;
        word-break: break-all;
      }
    }
  }
}
</style>
