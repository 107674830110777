<template>
  <div class="call-our">
    <span class="call-our-title">我要委托</span>
    <span class="call-our-sub">提交您的需求后，客服将很快与您联系</span>
    <div class="line">
      <div class="line-item">
        <span class="icon icon-edit"></span>
        <span class="text">提交需求</span>
      </div>
      <div class="line-item">
        <span class="icon icon-arrow-right"></span>
      </div>
      <div class="line-item">
        <span class="icon icon-telephone"></span>
        <span class="text">客服联系</span>
      </div>
      <div class="line-item">
        <span class="icon icon-arrow-right"></span>
      </div>
      <div class="line-item">
        <span class="icon icon-comment"></span>
        <span class="text">经纪人跟进</span>
      </div>
      <div class="line-item">
        <span class="icon icon-arrow-right"></span>
      </div>
      <div class="line-item">
        <span class="icon icon-strike"></span>
        <span class="text">成交</span>
      </div>
    </div>
    <div class="line sort">
      <div class="line-sort" v-for="(a, i) in sortList" :key="a.type" @click="chooseSort(a, i)">
        <b class="dot-btn"> <i class="select-btn" v-show="i === sortIndex"></i> </b>
        <span>{{ a.name }}</span>
      </div>
    </div>
    <select class="call-our-comment" placeholder="用途" v-model="obj.buildingUse">
      <option :value="i + 1" v-for="(a, i) in useList" :key="i">{{ a }}</option>
    </select>
    <input class="call-our-comment" placeholder="手机号码" v-model="obj.mobile" />
    <div class="code-box">
      <input class="call-our-comment" placeholder="验证码" v-model="obj.code" />
      <span class="code btn" :class="{ activeCode: /^1[3-9]\d{9}$/g.test(obj.mobile) }" @click="getCode">{{ codeText }}</span>
    </div>
    <span class="btn call-our-submit" @click="submit">马上找房</span>
  </div>
</template>

<script>
export default {
  name: 'call-our',
  data() {
    return {
      useList: ['住宅', '公寓', '别墅', '办公', '商铺', '仓库', '厂房', '车位', '土地'],
      sortList: [
        { name: '出售', type: 2 },
        { name: '出租', type: 1 },
        { name: '求购', type: 4 },
        { name: '求租', type: 3 },
      ],
      comment: '',
      mobile: '',
      code: '',
      sortIndex: 0,
      obj: {
        entrustType: 2,
        type: 2,
        buildingUse: 1,
        mobile: '',
        code: '',
      },
      isSend: false, //是否发送过验证码
      codeTime: 0,
    };
  },
  computed: {
    codeText() {
      if (this.isSend) {
        return this.codeTime > 0 ? this.codeTime + 's后重新发送' : '重新获取';
      } else {
        return this.codeTime > 0 ? this.codeTime + 's后重新发送' : '获取验证码';
      }
    },
  },
  methods: {
    chooseSort(a, i) {
      this.sortIndex = i;
      this.obj.type = a.type;
    },
    async getCode() {
      if (this.codeTime === 0) {
        if (!/^1[3-9]\d{9}$/g.test(this.obj.mobile)) return false;
        const obj = {
          mobile: this.obj.mobile,
          situation: 'entrust',
        };
        await http('token/sms-captcha', obj);
        this.codeTime = 60;
        const tt = setInterval(() => {
          this.codeTime > 0 ? this.codeTime-- : clearInterval(tt);
        }, 1000);
        this.isSend = true;
      }
    },
    async submit() {
      await http('entrust/add', this.obj);
      alert('发布成功');
      this.$emit('hide');
    },
  },
};
</script>

<style lang="scss" scoped>
.call-our {
  width: 450px;
  max-height: 90vh;
  overflow: auto;
  background-color: white;
  padding: 2rem 4rem;
  display: flex;
  flex-direction: column;
  &-title {
    font-size: 3.5rem;
    display: block;
    padding: 1rem 0;
  }

  &-sub {
    max-height: 90vh;
    font-size: 2rem;
  }
  &-body {
    flex: 1;
    overflow: auto;
  }

  &-comment {
    width: 100%;
    padding: 2rem 4rem;
    resize: none;
    border-radius: 6px;
    border: none;
    background-color: $bg-gray;
    margin-bottom: 2rem;
    height: 50px;
    &:focus {
      outline: var(--color) solid 2px;
    }
    option {
      font-size: 2rem;
    }
  }
  &-submit {
    width: 90%;
    margin: 1rem auto;
    color: white;
    background-color: var(--color);
    font-size: 2rem;
    padding: 2rem;
    border-radius: 6px;
    cursor: pointer;
  }
}
.line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4rem;

  &-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    opacity: 0.7;

    .icon {
      font-size: 3rem;
      &.icon-arrow-right {
        font-size: 2rem;
      }
    }

    .text {
      font-size: 1.5rem;
    }
  }
  &-sort {
    display: flex;
    align-items: center;
    .dot-btn {
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      background-color: #f6f6f6;
      display: flex;
      justify-content: center;
      align-items: center;
      .select-btn {
        width: 1.3rem;
        height: 1.3rem;
        border-radius: 50%;
        background-color: var(--color);
      }
    }
    span {
      font-size: 2rem;
      padding-left: 1.5rem;
    }
  }
}
.sort {
  padding: 0 4rem 2rem 4rem !important;
}
.code-box {
  position: relative;

  .code {
    position: absolute;
    top: 0;
    right: 0;
    padding: 2rem;
    font-size: 2rem;
    color: #ccc;
  }
  .activeCode {
    color: var(--color);
  }
}
.region-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  .select-region {
    display: flex;
    align-items: center;
  }
  .region-label {
    font-size: 2rem;
  }
  .sanjiao-icon {
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid #000;
    margin-left: 20px;
    margin-right: 10px;
  }
  ul {
    background-color: $bg-gray;
    position: absolute;
    left: 0;
    top: 36px;
    z-index: 99;
    li {
      padding: 1rem;
      font-size: 2rem;
      cursor: pointer;
      list-style-type: none;
    }
  }
  input {
    outline: none;
    border: none;
    background: none;
    flex: 1;
  }
}
.ipt-box {
  position: relative;
  .unit {
    position: absolute;
    right: 2rem;
    top: 2rem;
    font-size: 2rem;
  }
}
</style>
