import { createRouter, createWebHistory } from 'vue-router';

const routes = [];
const requireJs = require.context('./', false, /\w+\.js$/);
requireJs.keys().forEach((fileName) => {
  const config = requireJs(fileName);
  if (fileName.indexOf('index') === -1) {
    for (const a of config.default) {
      a.children?.push({
        name: 'about',
        component: () => import('@/views/About'),
      });
      a.children?.push({
        name: 'tools',
        component: () => import('@/views/Tools'),
      });
      a.children?.push({
        name: 'store',
        component: () => import('@/views/Store'),
      });
    }
    routes.push(...config.default);
  }
});
routes.unshift({ path: '/', redirect: '/house' });
for (const a of routes) {
  if (!a.path) a.path = a.name;
  if (a.children) {
    for (const b of a.children) {
      if (!b.path) b.path = b.name;
      b.name = a.name + '.' + b.name;
    }
  }
}
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
export default router;
