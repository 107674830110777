<template>
  <div class="call-our">
    <img class="call-our-img" src="https://static.ajl.cn/web/findOffice.jpg" width="500" />
    <div class="call-our-box">
      <p class="call-our-box-title">委托找房，省心找房屋</p>
      <p class="call-our-box-desc">10分钟内响应，全程免佣，专业看房顾问带看</p>
      <span class="call-our-box-btn" @click="show">免费委托</span>
    </div>
  </div>
</template>

<script>
import entrust from '@/components/a-entrust';

export default {
  name: 'call-our',
  data() {
    return {
      title: '纪家好房',
    };
  },
  methods: {
    show() {
      this.$modal(entrust);
    },
  },
};
</script>

<style lang="scss" scoped>
.call-our {
  width: 1050px;
  display: flex;
  justify-content: space-between;
  margin: 8rem auto 0;

  &-img {
    flex: 1;
    align-self: end;
    position: relative;
  }

  &-box {
    flex: 1;
    padding: 4rem;

    &-title {
      font-size: 3rem;
      padding: 2rem;
    }

    &-desc {
      font-size: 2rem;
    }

    &-btn {
      margin: 2rem auto;
      width: 260px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--color);
      padding: 1rem;
      font-size: 2rem;
      color: white;
      border-radius: 6px;
      cursor: pointer;
    }
  }
}
</style>
