<template>
  <i class="nb" ref="nb"></i>
  <b class="icon icon-top-filling goTop" @click="goTop"></b>
  <div class="nav" style="height: 0" v-show="isHome || isAbout">
    <div class="nav-bar">
      <router-link class="nav-logo lnk" :to="homePath"></router-link>
      <div class="nav-switch">
        <span class="lnk icon icon-menu" @click="show"></span>
        <div class="menu animate__animated animate__zoomIn animate__faster" v-show="more" @mouseleave="hide" @mouseenter="show">
          <router-link class="lnk" to="/house">住宅公寓</router-link>
          <router-link class="lnk" to="/shop">商铺门面</router-link>
          <router-link class="lnk" to="/office">商业办公</router-link>
          <router-link class="lnk" to="/factory">厂房土地</router-link>
        </div>
      </div>
      <div class="nav-lnk">
        <template v-for="a in list" :key="a.name">
          <template v-if="a.path">
            <span class="lnk" @click="$router.push(a.path)"> {{ a.name }} </span>
          </template>
          <template v-if="a.eve">
            <span class="lnk" @click="onClick(a.eve)"> {{ a.name }} </span>
          </template>
        </template>
        <span class="lnk" @click="$router.push({ name: $route.matched[0].name + '.about' })">关于我们</span>
        <span class="lnk tel">
          <span class="icon icon-telephone-out"></span>
          <span class="pl-1">{{ obj.tel }}</span>
        </span>
      </div>
    </div>
  </div>
  <!--  ************************************************************************************************************-->
  <div class="top-bar" v-show="!isHome && !isAbout">
    <router-link :to="homePath">首页</router-link>
    <router-link v-if="$route.fullPath.includes('office')" to="/office/rent">找办公室</router-link>
    <span @click="callOur">在线委托</span>
    <div class="lnk link-box">
      <span @click="goTools">工具箱</span>
      <span class="mini-label">
        <span>小程序</span>
        <nav-mini class="nav-mini" v-bind="obj" />
      </span>
      <span class="tel">
        <span class="icon icon-telephone-out"></span>
        <span>{{ obj.tel }}</span>
      </span>
    </div>
  </div>
  <div class="nav sticky" v-show="!isHome && !isAbout">
    <div class="nav-bar dark">
      <router-link class="nav-logo lnk" :to="homePath"></router-link>
      <div class="nav-lnk">
        <template v-for="a in list" :key="a.name">
          <template v-if="a.path">
            <router-link class="lnk" :to="a.path">{{ a.name }}</router-link>
          </template>
        </template>
        <div class="search">
          <input class="search-input" v-model="content" placeholder="请输入名称或区域" @keypress.enter="search" @blur="search" />
          <div class="btn" @click="search">
            <span class="icon icon-search"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AEntrust from '@/components/a-entrust';
import Color from 'color';

export default {
  name: 'top-nav',
  data() {
    return {
      more: false,
      house: [
        { name: '首页', path: '/house' },
        { name: '二手房', path: '/house/sale' },
        { name: '新房', path: '/house/first-sale' },
        { name: '租房', path: '/house/rent' },
        { name: '小区', path: '/house/building' },
        { name: '在线委托', eve: 'callOur' },
        { name: '工具箱', eve: 'goTools' },
      ],
      office: [
        { name: '写字楼出售', path: '/office/sale' },
        { name: '写字楼出租', path: '/office/rent' },
        { name: '一手写字楼出售', path: '/office/first-sale' },
        { name: '一手写字楼出租', path: '/office/first-rent' },
        { name: '工具箱', eve: 'goTools' },
      ],
      factory: [
        { name: '厂房土地出售', path: '/factory/sale' },
        { name: '厂房土地出租', path: '/factory/rent' },
        { name: '一手厂房土地出售', path: '/factory/first-sale' },
        { name: '一手厂房土地出租', path: '/factory/first-rent' },
        { name: '工具箱', eve: 'goTools' },
      ],
      shop: [
        { name: '商铺出售', path: '/shop/sale' },
        { name: '商铺出租', path: '/shop/rent' },
        { name: '一手商铺出售', path: '/shop/first-sale' },
        { name: '一手商铺出租', path: '/shop/first-rent' },
        { name: '工具箱', eve: 'goTools' },
      ],
      content: '',
      obj: {},
    };
  },
  computed: {
    isAbout() {
      return this.$route.name?.match('about');
    },
    homePath() {
      return this.root.path ?? '';
    },
    list() {
      if (this.root.name) return this[this.root.name];
      return [];
    },
  },
  watch: {
    $route() {
      window.requestAnimationFrame(() => window.goTop());
    },
  },
  methods: {
    goTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    onClick(e) {
      this[e]();
    },
    goTools() {
      this.$router.push({ name: this.root.name + '.tools' });
    },
    show() {
      clearTimeout(this.moreId);
      this.more = true;
    },
    hide() {
      this.moreId = setTimeout(() => (this.more = false), 600);
    },
    async callOur() {
      const el = event.target;
      el.classList.add('router-link-exact-active');
      await this.$modal(AEntrust);
      el.classList.remove('router-link-exact-active');
    },
    search() {
      let name = this.$route.name;
      if (this.$route.query.from) name = this.$route.query.from;
      this.$router.push({ name, query: { content: this.content } });
    },
    async getConfig() {
      const url = 'office/company-config';
      const { data } = await http(url, {}, 86400);
      this.obj = data ?? { companyPhone: '', wxLoginImage: '', styleConfig: '' };
      const onlineColor = isQian ? [0, 128, 0] : data.styleConfig.split(',').map((a) => parseInt(a));
      const color = new Color.rgb(onlineColor);
      document.documentElement.style.setProperty('--color', color.alpha(1).rgb());
      document.documentElement.style.setProperty('--color10', color.alpha(0.1).rgb());
      document.documentElement.style.setProperty('--color50', color.alpha(0.5).rgb());
      document.documentElement.style.setProperty('--color60', color.alpha(0.6).rgb());
      document.documentElement.style.setProperty('--color90', color.alpha(0.9).rgb());
      document.documentElement.style.setProperty('--lgColor', `linear-gradient(to right ,${color.alpha(1).rgb()},${color.alpha(0.5).rgb()})`);
      document.documentElement.style.setProperty('--logo', `url(${isQian ? location.origin + '/logo.png' : data.wxLoginImage})`);
      this.obj.tel = data.companyPhone;
      document.title = data.companyName;
      if (!isQian) document.querySelector('link[rel="icon"]').href = data.wxLoginImage;
    },
  },
  mounted() {
    window.nav = this;
    this.getConfig();
    const obs = new IntersectionObserver(function (els) {
      document.querySelector('.nav').className = !els[0].intersectionRatio ? 'nav hasBg animate__bounceInDown' : 'nav';
      document.querySelector('.nav-bar').className = !els[0].intersectionRatio ? 'nav-bar animate__animated animate__bounceInDown' : 'nav-bar';
      document.querySelector('.goTop').hidden = !!els[0].intersectionRatio;
    });
    obs.observe(this.$refs.nb);
  },
};
</script>

<style lang="scss" scoped>
.nb {
  height: 90vh;
  width: 1px;
  z-index: -1;
  position: absolute;
}

.goTop {
  position: fixed;
  z-index: 9999;
  bottom: 60px;
  right: 50px;
  font-size: 2rem;
  color: white;
  background-color: black;
  padding: 6px;
  cursor: pointer;
  border-radius: 6px;
}

.top-bar {
  margin: auto;
  width: 1050px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    left: -100vw;
    right: -100vw;
    height: 100%;
    background-color: #333;
  }

  span,
  a {
    padding: 1rem;
    color: white;
    font-size: 2rem;
    cursor: pointer;

    &:hover {
      color: var(--color);
    }
  }

  .lnk {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.nav {
  top: 0;
  z-index: 1000;
  &.sticky {
    position: sticky;
    border-bottom: 1px solid #eee;
    background-color: white;
  }
  &.hasBg {
    position: sticky;
    .nav-bar {
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: -100vw;
        right: -100vw;
        height: inherit;
        backdrop-filter: blur(5px);
        background-color: rgba(black, 0.5);
      }
    }
  }
  &-bar {
    margin: auto;
    width: 1050px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    &.dark {
      padding: 4rem 0;

      .nav-logo {
        background-image: var(--logo);
        background-position-x: 1rem;
      }

      .lnk {
        color: black;
      }

      .search {
        width: 280px;
        background-color: $bg-gray;
        padding: 1rem 2rem;
        display: flex;
        align-items: center;
        border-radius: 6px;

        input {
          flex: 1;
          border: none;
          background-color: $bg-gray;
          font-size: 2rem;
          outline: none;
        }

        .btn {
          padding-left: 2rem;
        }
      }
    }
  }

  &-logo {
    min-width: 80px;
    background-image: var(--logo);
    background-size: auto 40px;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 99;
  }

  &-switch {
    position: relative;
    z-index: 99;
    .lnk {
      font-size: 2rem !important;
      line-height: 1.8 !important;
    }

    .menu {
      position: absolute;
      top: 4rem;
      left: 2rem;
      width: 20rem;
      display: flex;
      flex-direction: column;
      background-color: rgba(black, 0.7);
      border-radius: 0.5rem;
      padding: 1rem;
    }
  }

  &-lnk {
    flex: 1;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 99;
  }

  .lnk {
    font-size: 2rem;
    height: inherit;
    line-height: 50px;
    color: white;
    cursor: pointer;
    padding: 0 2rem;

    &:hover {
      color: var(--color);
    }

    &.tel {
      font-weight: bold;
      font-size: 2rem;
    }
  }
}

.link-box {
  position: relative;

  .mini-label {
    position: relative;

    .nav-mini {
      position: absolute;
      z-index: 9999;
      top: 52px;
      left: -48px;
      transform: scale(0);
    }

    &:hover {
      .nav-mini {
        transform: scale(1);
      }
    }
  }
}
</style>
