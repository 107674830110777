<template>
  <div class="store-body-wrap">
    <div class="ach-box">
      <div class="item">
        <div class="item-left">
          <p class="label">买卖成交</p>
          <p class="num">{{ saleTrade }}<span class="unit">套</span></p>
        </div>
        <span class="item-right"></span>
      </div>
      <div class="item">
        <div class="item-left">
          <p class="label">租赁成交</p>
          <p class="num">{{ rentTrade }}<span class="unit">套</span></p>
        </div>
        <span class="item-right"></span>
      </div>
      <div class="item">
        <div class="item-left">
          <p class="label">30天带看</p>
          <p class="num">{{ takeLookNum }}<span class="unit">次</span></p>
        </div>
        <span class="item-right"></span>
      </div>
      <div class="item">
        <div class="item-left">
          <p class="label">关注人数</p>
          <p class="num">99+<span class="unit">人</span></p>
        </div>
        <span class="item-right"></span>
      </div>
    </div>
    <template v-if="root.name === 'house'">
      <p class="title">
        <span>主营小区</span>
        <span class="more" v-if="edificeList.length > 4" @click="toEdifice">查看更多</span>
      </p>
      <div class="list-box">
        <div class="list-box-item" v-for="a in edificeList.slice(0, 4)" :key="a.id" @click="buildingDetail(a.id)">
          <img :src="a.fileImg" alt="" class="list-box-item-photo" />
          <div class="item-intro">
            <span class="item-intro-name">{{ a.buildingName }}</span>
            <span class="item-intro-price">{{ a.averagePrice }}</span>
          </div>
        </div>
      </div>
    </template>
    <!--    置业顾问-->
    <store-agent v-if="!id" />
    <div ref="topEl"></div>
    <div class="tag-box-top">
      <template v-for="a in menu" :key="a.title">
        <div class="tag-box-top-item" :class="{ active: a.title === cur }" @click="changeTab(a.title, $event)">{{ a.title }}({{ a.count }})</div>
      </template>
    </div>
    <template v-if="cur">
      <store-house-list :qs="menu.find((a) => a.title === cur)" :key="cur" :topEl="topEl" :id="id" />
    </template>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'body',
  setup() {
    const topEl = ref(null);
    return { topEl };
  },
  props: {
    id: Number,
    mainBuilding: Array,
    saleTrade: Number,
    rentTrade: Number,
    takeLookNum: Number,
    menu: Array,
  },
  data() {
    return {
      edificeList: [], //主营小区
      cur: '',
    };
  },
  computed: {
    type() {
      if (this.tagIndex === 1) return 'sale';
      if (this.tagIndex === 2) return 'rent';
      if (this.tagIndex === 3) return 'first';
      return '';
    },
  },
  methods: {
    changeTab(a, e) {
      this.cur = a;
      if (e.target.getBoundingClientRect().x > 500) {
        e.target.nextElementSibling?.scrollIntoView();
        e.target.nextElementSibling?.nextElementSibling?.scrollIntoView();
      } else {
        e.target.previousElementSibling?.scrollIntoView();
        e.target.previousElementSibling?.previousElementSibling?.scrollIntoView();
      }
      this.topEl.scrollIntoView();
    },
    toEdifice() {
      const id = this.id ? this.mainBuilding.join(',') : '';
      this.$router.push({ name: 'house.building', query: { id } });
    },
    buildingDetail(id) {
      this.$router.push({ name: this.root.name + '.edifice-detail', query: { id, from: this.root.name + '.building' } });
    },
    async getEdificeList() {
      if (this.id) {
        const { list } = await http('house-building/list', { id: this.mainBuilding.join(','), buildingUse: '1,2,3' });
        this.edificeList = list;
      } else {
        const { list } = await http('house-building/list', { buildingUse: '1,2,3' });
        this.edificeList = list;
      }
    },
    async getEvaluateList() {
      const { list } = await http('mini-comment/list', { agentId: this.id });
      this.evaluateList = list;
    },
  },
  mounted() {
    this.getEdificeList();
    this.cur = this.menu[0].title;
  },
};
</script>

<style lang="scss" scoped>
.store-body-wrap {
  .ach-box {
    padding-bottom: 3em;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
    .item {
      padding: 1em 2em;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #f8f8f8;
      &-left {
        text-align: left;
        .label {
          font-size: 16px;
          color: #888;
          margin-bottom: 5px;
        }
        .num {
          color: red;
          font-size: 28px;
          margin-right: 4px;
          .unit {
            font-size: 14px;
          }
        }
      }
      &-right {
        display: block;
        width: 48px;
        height: 46px;
        background-image: url(https://static.ajl.cn/web/shop/icon-inform.png);
        background-size: 48px auto;
      }
      $i: 4;
      @while $i>0 {
        &:nth-child(#{$i}) span {
          background-position-y: -56px * ($i - 1);
        }
        $i: $i - 1;
      }
    }
  }
  .title {
    font-size: 24px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .more {
      color: gray;
      font-size: 16px;
      font-weight: normal;
      &:hover {
        color: var(--color);
      }
    }
  }
  .list-box {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 2em 0;
    gap: 2em;
    &-item {
      cursor: pointer;
      &-photo {
        width: 100%;
        height: 174px;
      }
      .item-intro {
        display: flex;
        justify-content: space-between;
        padding: 1em 0;
        &-price {
          color: red;
        }
      }
    }
    .live-item {
      position: relative;
    }
    .live-info {
      display: flex;
      align-items: center;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 20px;
      background: rgba(0, 0, 0, 0.2);
      font-size: 12px;
    }
  }
  .tag-box {
    text-align: left;

    &-top {
      display: flex;
      width: 1050px;
      overflow-x: auto;
      overflow-y: hidden;
      border-bottom: 1px solid #e6e6e6;
      padding: 10px;
      //&:hover {
      //  &::-webkit-scrollbar {
      //    display: initial;
      //  }
      //}
      &-item {
        white-space: nowrap;
        font-size: 15px;
        line-height: 42px;
        margin-right: 36px;
      }
      .active {
        font-weight: bold;
        color: var(--color);
        position: relative;
      }
      .active:after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 4px;
        background: var(--color);
      }
    }
    .sort {
      display: inline-block;
      border: 1px solid #ededed;
      border-radius: 4px;
      margin: 2em 0;
      &-item {
        display: inline-block;
        line-height: 28px;
        width: 76px;
        text-align: center;
        border-right: 1px solid #ededed;
        cursor: pointer;
        margin-right: -1px;
        > span:last-of-type {
          opacity: 0;
        }
      }
      .current {
        color: var(--color);
        > span:last-of-type {
          opacity: 1;
        }
      }
    }
  }
}
</style>
