<template>
  <span class="a-not"><slot></slot>暂无数据</span>
</template>

<script>
export default { name: 'a-not' };
</script>

<style scoped>
.a-not {
  padding: 6rem;
  display: grid;
  place-content: center;
  font-size: 3rem;
  background-color: white;
  color: #ccc;
}
</style>
