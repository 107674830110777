<template>
  <div class="vr"></div>
</template>

<script>
export default {
  name: 'v-r',
};
</script>

<style lang="scss" scoped>
.vr {
  border-right: 1px solid #eee;
}
</style>
