export default [
  {
    path: '/house',
    name: 'house',
    component: () => import('@/views/House/index'),
    children: [
      {
        name: 'sale',
        component: () => import('@/views/House/list'),
      },
      {
        name: 'rent',
        component: () => import('@/views/House/list'),
      },
      {
        name: 'first-sale',
        component: () => import('@/views/House/list'),
      },
      {
        name: 'first-rent',
        component: () => import('@/views/House/list'),
      },
      {
        name: 'building', //小区列表
        component: () => import('@/views/House/building'),
      },
      {
        name: 'building-detail',
        component: () => import('@/views/House/buildingDetail'),
      },
      {
        name: 'house-detail',
        component: () => import('@/views/House/houseDetail'),
      },
      {
        name: 'room-detail',
        component: () => import('@/views/Office/roomDetail'),
      },
      {
        name: 'first-detail',
        component: () => import('@/views/Office/houseDetail'),
      },
      {
        name: 'edifice-detail', //大厦详情
        component: () => import('@/views/Office/buildingDetail'),
      },
    ],
  },
];
