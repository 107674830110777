<template>
  <div class="min" ref="min">
    <img v-if="orCode" :src="orCode" alt="" />
    <span v-else>暂无二维码</span>
    <span class="label">微信扫码打电话</span>
    <span class="tips">-扫码失败可拨打-</span>
    <span class="number">{{ showMobile }}</span>
    <teleport to="body">
      <div class="mask" style="left: 0; right: 0; top: 0; bottom: 0; position: fixed; z-index: 9999" @click="$parent.isMobile = false"></div>
    </teleport>
  </div>
</template>

<script>
export default {
  name: 'mobile',
  props: {
    mobile: String,
    orCode: String,
  },
  data() {
    return {
      isMobile: false,
    };
  },
  computed: {
    showMobile() {
      if (this.mobile) return this.mobile.replace(',', '转');
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.min {
  width: 210px;
  padding: 20px 0;
  position: relative;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  filter: drop-shadow(0 0 1rem #666);
  img {
    width: 110px;
    height: 110px;
    margin-bottom: 4px;
  }
  .label {
    font-size: 14px;
    color: #333;
    line-height: 34px;
  }
  .tips {
    font-size: 12px;
    color: #bebebe;
    line-height: 20px;
  }
  .number {
    font-size: 16px;
    color: var(--color);
    line-height: 20px;
    margin-top: 6px;
    font-weight: bold;
  }
}
</style>
