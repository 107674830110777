<template>
  <div class="banner">
    <div class="line">
      <a-carousel v-size:680*400 :fileImg="props.fileImg" v-if="props.fileImg" />
      <office-house-info v-bind="$attrs" />
    </div>
    <div class="tips py-2">
      <template v-if="panorama">
        <img class="tips-vr mr-2" src="https://static.ajl.cn/web/vr.png" alt="全景看房" />
        <span class="f-4">本楼盘已支持VR看房，在线看房，省时省力</span>
        <span class="btn color f-4 end" @click="look">立即看房</span>
      </template>
      <template v-else>
        <span class="f-4">咨询经纪人，马上带您看房</span>
        <span class="btn color f-4 end" @click="orCode">立即看房&gt;&gt;</span>
      </template>
    </div>
  </div>
</template>
<script setup>
const props = defineProps({
  fileImg: Array,
  panorama: String,
});
const look = () => {
  window.open(props.panorama);
};
const orCode = () => {
  document.querySelector('.mobile').click();
};
</script>
<style lang="scss" scoped>
.banner {
  margin: auto;
  width: 1050px;
}
.line {
  display: flex;
  justify-content: space-between;
}

.tips {
  width: 680px;
  display: flex;
  align-items: center;

  &-vr {
    height: 4rem;
    list-style: none;
  }
}
</style>
