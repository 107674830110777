<template>
  <div class="service-promise">
    <span>真实房源</span>
    <span class="gap"></span>
    <span>全程免佣</span>
    <span class="gap"></span>
    <span>VR看房 </span>
    <span class="gap"></span>
    <span>1对1贴心服务</span>
  </div>
</template>

<script>
export default {
  name: 'service-promise',
};
</script>

<style lang="scss" scoped>
.service-promise {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1050px;
  padding: 2rem;
  span {
    font-size: 3rem;
  }
  .gap {
    width: 1px;
    height: 3rem;
    background-color: black;
  }
}
</style>
