<template>
  <div class="order">
    <div class="order-box">
      <h3>立即预约</h3>
      <p class="order-box-num">该大厦30天共有211关注</p>
      <div class="item-input">
        <input type="text" v-model="obj.mobile" class="text" placeholder="手机号码" />
      </div>
      <div class="item-input">
        <input type="text" v-model="obj.code" class="text" placeholder="验证码" maxlength="6" />
        <a class="code-label" :class="{ activeCode: /^1[3-9]\d{9}$/g.test(obj.mobile) }" href="javascript:void (0)" @click="getCode">{{ codeText }}</a>
      </div>
      <span class="btn" @click="submit">预约看房</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'order',
  props: { relationId: Number, agentUserId: Number, type: Number },
  data() {
    return {
      obj: {
        mobile: '',
        code: '',
        dateType: 4,
      },
      isSend: false, //是否发送过验证码
      codeTime: 0,
      isQR: false,
      QRImg: '',
    };
  },
  computed: {
    codeText() {
      if (this.isSend) {
        return this.codeTime > 0 ? this.codeTime + 's后重新发送' : '重新获取';
      } else {
        return this.codeTime > 0 ? this.codeTime + 's后重新发送' : '获取验证码';
      }
    },
  },
  methods: {
    async getCode() {
      if (this.codeTime == 0) {
        if (!/^1[3-9]\d{9}$/g.test(this.obj.mobile)) return false;
        const obj = {
          mobile: this.obj.mobile,
          situation: 'subscribe',
        };
        await http('token/sms-captcha', obj);
        this.codeTime = 60;
        const tt = setInterval(() => {
          this.codeTime > 0 ? this.codeTime-- : clearInterval(tt);
        }, 1000);
        this.isSend = true;
      }
    },
    async submit() {
      this.obj.relationId = this.relationId;
      this.obj.type = this.type;
      this.obj.agentUserId = this.agentUserId;
      const { msg } = await http('subscribe/add', this.obj);
      alert(msg);
      await this.$emit('hide');
    },
  },
};
</script>

<style lang="scss" scoped>
.order {
  background: #ffffff;
  padding: 20px;
  text-align: left;
  margin-top: 2rem;
  z-index: 99;
  &-box {
    h3 {
      margin-top: 10px;
      font-size: 14px;
      font-weight: 600;
    }
    &-num {
      color: #666;
      font-size: 12px;
      margin: 10px 0 20px 0;
    }
    .item-input {
      position: relative;
      .text {
        width: 320px;
        border: 0;
        height: 48px;
        margin-bottom: 10px;
        padding: 14px;
        border-radius: 6px;
        background: #f5f5f5;
        color: #b2b2b2;
      }
      .code-label {
        display: block;
        color: #b2b2b2;
        font-size: 12px;
        position: absolute;
        right: 10px;
        top: 15px;
        cursor: pointer;
      }
      .activeCode {
        color: var(--color);
      }
    }
    .btn {
      margin-top: 10px;
      background: var(--color);
      height: 48px;
      line-height: 48px;
      display: block;
      color: #ffffff;
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
      text-align: center;
    }
  }
}
</style>
