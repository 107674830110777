<template>
  <div class="side">
    <div class="wt fd" @click="callOur">
      <img style="height: 30px; width: auto" :src="obj.wxLoginImage" alt="在线委托" />
      <span>在线委托</span>
      <svg width="30" height="16" viewBox="0 0 39 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M34.275 9.60147L0.0908198 9.60147L0.0908199 11.9323L34.7383 11.9323L26.8613 19.8573L28.483 21.4889L38.9085 11L28.483 0.511065L26.8613 2.14267L34.275 9.60147Z"
          fill="white"
        />
      </svg>
    </div>
    <div class="warp">
      <img class="px-8 pt-4 pb-10" :src="obj.url" alt="小程序" />
      <span class="san">扫码关注随时随地看房</span>
    </div>
    <div class="fd" style="display: flex; align-items: center; width: 200px; background-color: white; padding: 2rem; margin: auto auto 2rem">
      <svg width="35px" height="35px" viewBox="0 0 1024 1024">
        <g>
          <path
            fill="var(--color)"
            d="M850.965,507.358c0-194.996-151.68-352.492-338.974-352.492c-187.286,0-338.956,157.497-338.956,352.492
		c0,195.006,151.67,352.502,338.956,352.502C699.285,859.86,850.965,702.364,850.965,507.358z M511.991,815.927
		c-164.893,0-298.241-138.204-298.241-308.568c0-170.355,133.349-309.637,298.241-309.637
		c164.902,0,298.241,138.213,298.241,309.637C810.231,678.78,675.875,815.927,511.991,815.927z"
          />
          <path
            fill="var(--color50)"
            d="M929.34,431.293C894.732,224.506,721.697,67,512,67C302.313,67,129.277,224.506,94.661,431.293
		C40.714,438.794,0,485.931,0,543.789c0,62.15,48.857,113.564,107.893,112.487c2.036,0,3.054,0,5.089,0
		c1.018,3.229,2.036,5.387,3.054,7.5c4.071,11.813,16.295,17.159,26.474,12.869c11.196-4.291,16.286-17.14,12.214-27.853
		c-17.304-44.99-25.447-92.154-25.447-141.435c0-219.636,171-397.503,382.723-397.503c210.715,0,381.715,177.867,382.732,396.434
		c0,161.797-94.66,305.367-234.106,366.42c-10.179-9.618-23.411-17.118-38.697-15.007c-29.519,0-52.929,24.662-52.929,54.669
		c0,29.985,23.41,54.627,52.929,54.627c28.519,0,51.929-23.563,52.947-53.549C785.822,865.207,870.304,771.993,910,657.354
		c2.036,0,3.054,0,5.09,0c60.053,0,108.91-51.414,108.91-113.564C1024,485.931,982.268,438.794,929.34,431.293z"
          />
          <path
            fill="var(--color90)"
            d="M690.144,554.503c-2.036,1.078-5.089,3.212-11.196,7.501c-10.179,5.369-21.375,11.792-35.625,17.141
		c-37.68,15.001-82.465,24.658-131.322,24.658c-49.875,0-93.643-9.657-133.339-24.658c-13.242-5.349-25.456-10.697-35.625-17.141
		c-6.117-3.212-9.17-5.365-11.206-7.501c-16.286-11.79-37.661,4.289-31.553,24.642c29.518,94.29,114.009,158.571,210.705,158.571
		c96.715,0,181.198-65.361,210.715-158.571C727.804,559.871,706.43,542.713,690.144,554.503z"
          />
        </g>
      </svg>
      <div style="padding-left: 1rem; display: flex; flex-direction: column; align-items: flex-start">
        <span style="margin-bottom: 1rem">房产交易新选择</span>
        <span style="font-weight: 600">{{ obj.tel }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';

function callOur() {
  window.nav.callOur();
}
const obj = computed(function () {
  return window.nav.obj;
});
</script>

<style lang="scss" scoped>
.side {
  width: 260px;
  padding: 0 2rem;
  position: sticky;
  top: 60px;
  height: min-content;
  .wt {
    margin: 0 auto 2rem;
    width: 200px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    background-image: var(--lgColor);
    overflow: hidden;
    span {
      color: white;
      font-size: 3rem;
    }
    img {
      border-radius: 6px;
    }
  }
  .warp {
    img {
      @extend .fd;
      width: 200px;
      border-radius: 6px;
      cursor: pointer;
      margin-bottom: 2rem;
      background-color: $bg-color;
    }

    position: relative;
    .san {
      position: absolute;
      bottom: 4rem;
      left: 0;
      right: 0;
      font-size: 2rem;
    }
    .tel {
      position: absolute;
      left: 3rem;
      top: 6rem;
      font-size: 2.2rem;
    }
  }
}
</style>
