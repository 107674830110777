<template>
  <div class="a-title f-5 px-2 mb-4">
    <slot></slot>
  </div>
  <hr />
</template>

<script>
export default { name: 'a-title' };
</script>

<style lang="scss" scoped>
.a-title {
  display: flex;
  align-items: flex-end;
  position: relative;
  .tip {
    font-size: 12px;
    color: gray;
    padding-left: 1rem;
  }
  .btn {
    font-size: 12px;
    color: var(--color);
    cursor: pointer;
    margin-left: auto;
  }
  &:before {
    content: '';
    position: absolute;
    top: 4px;
    bottom: 4px;
    left: 0;
    width: 2px;
    background-color: var(--color);
  }
}
</style>
