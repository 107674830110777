import { createApp } from 'vue';
import modal from './index';
export default {
  install: function (app) {
    function fnModal(c, p) {
      return new Promise((resolve) => {
        const hide = () => {
          resolve();
          Modal.unmount();
        };
        const Modal = createApp(modal, { p, c, hide });
        const parent = document.createElement('div');
        Modal.mount(parent);
        document.body.appendChild(parent);
      });
    }
    app.config.globalProperties.$modal = fnModal.bind(this);
  },
};
