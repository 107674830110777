<template>
  <main>
    <div class="bg-light ps-6">
      <ul class="nav" ref="nav">
        <li class="btn color" :class="{ ac: a === ac }" v-for="a in menu" :key="a" @click="change(a)">{{ a }}</li>
      </ul>
    </div>
    <div class="bg-gray">
      <div class="detail-body pd-8">
        <div class="body">
          <div class="flag" data-flag="商务中心配套"></div>
          <div class="base pb-4 mb-4">
            <div class="base-title f-5 px-2 mb-4">商务中心配套</div>
            <div class="base-list">
              <div class="base-list-item" v-for="a in matchingName" :key="a.name">
                <div class="pic"><img :src="a.url" :alt="a.name" /></div>
                <span>{{ a.name }}</span>
              </div>
            </div>
          </div>
          <div class="flag" data-flag="所属大厦"></div>
          <div class="base pb-4 mb-4">
            <div class="base-title f-5 px-2 mb-4">
              <span>{{ buildingName }}</span>
              <span class="btn" @click="checkMore">查看{{ root.buildingName }}详情></span>
            </div>
            <hr class="my-2" />
            <div class="building-box">
              <div class="building-box-left">
                <div class="building-box-left-item">
                  <span class="label">租金范围</span>
                  <span class="con" style="color: #cc2929">{{ buildingData?.rentPrice }}</span>
                </div>
                <div class="building-box-left-item">
                  <span class="label">建筑时间</span>
                  <span class="con">{{ buildingData?.year || '暂无' }}</span>
                </div>
                <div class="building-box-left-item">
                  <span class="label">车位数量</span>
                  <span class="con">{{ buildingData?.parking || '暂无' }}</span>
                </div>
                <div class="building-box-left-item">
                  <span class="label">物业费</span>
                  <span class="con">{{ buildingData?.propertyPrice || '暂无' }}</span>
                </div>
                <div class="building-box-left-item">
                  <span class="label">在租户型</span>
                  <span class="con">{{ buildingData?.rentHouseCount }}套</span>
                </div>
              </div>
              <img :src="buildingData?.fileImg" class="img" alt="" />
            </div>
          </div>
          <div class="flag" data-flag="10分钟步行圈"></div>
          <div class="base pb-4 mb-4">
            <div class="base-title f-5 px-2 mb-4">十分钟步行圈</div>
            <a-map style="width: 100%; height: 400px" :lat="lat" :lng="lng" />
          </div>
          <div class="flag" data-flag="周边商务中心"></div>
          <office-building-surrounding :list="list" :lat="lat" :lng="lng" :id="id" />
        </div>
        <div class="side">
          <a-agent v-for="a in maintainUser" v-bind="a" :key="a.userId" :type="1" :relationId="relationId" />
          <a-mobile />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: 'body',
  props: { id: Number, matchingName: Array, buildingData: Object, buildingName: String, cateCircle: String, houseId: Number, maintainUser: Array, lat: String, lng: String },
  data() {
    return {
      ac: '商务中心配套',
      menu: ['商务中心配套', '所属大厦', '10分钟步行圈', '周边商务中心'],
      list: [],
    };
  },
  watch: {
    cateCircle: {
      handler(v) {
        if (!v) return false;
        this.getList();
      },
      immediate: true,
    },
  },
  methods: {
    async checkMore() {
      const { href } = await this.$router.resolve({
        name: this.$route.matched[0].name + '.edifice-detail',
        query: { id: this.buildingData?.buildingId, from: this.root.name + '.building' },
      });
      window.open(href, '_blank');
    },
    async change(a) {
      this.ac = a;
      const flag = document.querySelector(`.flag[data-flag="${a}"]`).getBoundingClientRect();
      await this.$nextTick();
      const top = flag.top - document.documentElement.getBoundingClientRect().top - 140;
      document.body.scrollTop = top.toFixed(0) * 1;
    },
    listen() {
      requestAnimationFrame(() => {
        for (const a of this.menu) {
          const flag = document.querySelector(`.flag[data-flag="${a}"]`).getBoundingClientRect();
          if (flag.top * -1 + flag.height + 160 > 0) this.ac = a;
        }
      });
    },
    async getList() {
      let url = 'office/list';
      const { list } = await http(url, { isHot: 1, cateCircle: this.cateCircle, tradeType: 1, buildingUse: this.root.buildingUse });
      this.list = list;
    },
  },
  mounted() {
    document.addEventListener('scroll', this.listen, false);
  },
  unmounted() {
    document.removeEventListener('scroll', this.listen, false);
  },
};
</script>

<style lang="scss" scoped>
.bg-light {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 99;
  .nav {
    margin: auto;
    width: 1050px;
    display: flex;
    background-color: white;
    li {
      list-style: none;
      font-size: 2rem;
      padding: 2rem 0;
      margin-right: 6rem;
      &.ac {
        color: var(--color);
      }
    }
  }
}
.detail-body {
  margin: auto;
  width: 1050px;
  display: flex;
  justify-content: space-between;
  .body {
    width: 680px;
    .flag {
      padding: 1rem;
    }
  }
}
.side {
  width: 360px;
  position: sticky;
  height: 720px;
  top: 93px;
  index: 99;
}
.map-box {
  background-color: #ffffff;
  .top {
    padding: 0 30px;
  }
  h3 {
    padding: 18px 0;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    text-align: left;
    font-size: 14px;
    font-weight: 600;
  }
}
//新增样式
.base {
  width: 680px;
  text-align: left;
  border-bottom: 1px solid #eee;
  background-color: white;
  padding: 30px;
  &-title {
    display: flex;
    align-items: flex-end;
    position: relative;
    .tip {
      font-size: 12px;
      color: gray;
      padding-left: 1rem;
    }
    .btn {
      font-size: 12px;
      color: var(--color);
      cursor: pointer;
      margin-left: auto;
    }
    &:before {
      content: '';
      position: absolute;
      top: 4px;
      bottom: 4px;
      left: 0;
      width: 2px;
      background-color: var(--color);
    }
  }
  &-list {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 1rem;
    &-item {
      display: inline-grid;
      place-content: center;
      margin-bottom: 2rem;
      .pic {
        width: 30px;
        height: 30px;
        margin: 1rem auto;
        overflow: hidden;
        img {
          width: inherit;
          height: inherit;
          overflow: hidden;
          filter: drop-shadow(30px 0 var(--color));
          transform: translateX(-30px);
        }
      }
      span {
        text-align: center;
      }
    }
  }
}
.building-box {
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    width: 200px;
    height: 125px;
    border-radius: 6px;
    margin-top: 16px;
  }
}

.building-box-left-item {
  margin: 20px 0 10px 0;

  .label {
    display: inline-block;
    width: 78px;
    height: 17px;
    font-size: 12px;
    color: #b2b2b2;
  }

  .con {
    display: inline-block;
    font-size: 12px;
    height: 17px;
  }
}
</style>
