<template>
  <div class="house-box body-item" v-if="divisionData?.length">
    <ul class="house-box-bar">
      <template v-for="(a, i) in divisionData" :key="a.title">
        <li class="house-box-bar-item p-4" :class="{ isActive: i === menuIndex }" @click="changeMenu(i)">
          <span>{{ a.title }}</span>
          <span>{{ a.total }}套</span>
        </li>
      </template>
    </ul>
    <div class="filter my-2">
      <div class="filter-item">照片</div>
      <div class="filter-item">
        <span>面积</span>
        <div class="icon-box">
          <i class="icon icon-arrow-up-filling" :class="{ iconActive: areaSort === 1 }" @click="sort('area', 1)"></i>
          <i class="icon icon-arrow-down-filling" :class="{ iconActive: areaSort === 2 }" @click="sort('area', 2)"></i>
        </div>
      </div>
      <div class="filter-item">
        <span class="price" :class="{ gray: priceType === 2 }" @click="priceType = 1">单价</span>
        <span class="gray dot">·</span>
        <span class="price" :class="{ gray: priceType !== 2 }" @click="priceType = 2">总价</span>
        <div class="icon-box">
          <i class="icon icon-arrow-up-filling" :class="{ iconActive: priceSort === 1 }" @click="sort('price', 1)"></i>
          <i class="icon icon-arrow-down-filling" :class="{ iconActive: priceSort === 2 }" @click="sort('price', 2)"></i>
        </div>
      </div>
      <div class="filter-item">朝向</div>
      <div class="filter-item">
        <span>更新</span>
        <div class="icon-box">
          <i class="icon icon-arrow-up-filling" :class="{ iconActive: updateSort === 1 }" @click="sort('update', 1)"></i>
          <i class="icon icon-arrow-down-filling" :class="{ iconActive: updateSort === 2 }" @click="sort('update', 2)"></i>
        </div>
      </div>
    </div>
    <div class="house-show">
      <template v-for="a in divisionList" :key="a.id">
        <office-building-card :item="a" :price-type="priceType" :tradeType="tradeType"></office-building-card>
      </template>
      <div class="lookMore p-4" v-if="totalDivision[menuIndex].data.length !== divisionList.length" @click="hasMore = false">查看更多</div>
    </div>
  </div>
  <a-not v-else>{{ tradeType - 1 ? '在售户型' : '在租户型' }}</a-not>
</template>

<script>
export default {
  name: 'houseType',
  props: { divisionData: Array, tradeType: String },
  data() {
    return {
      menuIndex: 0,
      areaSort: '',
      priceType: 1,
      priceSort: '',
      updateSort: '',
      totalDivision: [],
      hasMore: true,
    };
  },
  computed: {
    divisionList() {
      if (this.hasMore) return this.totalDivision[this.menuIndex].data.slice(0, 5);
      return this.totalDivision[this.menuIndex].data;
    },
  },
  watch: {
    divisionData: {
      handler(v) {
        if (!v) return false;
        this.totalDivision = v;
      },
      immediate: true,
    },
  },
  methods: {
    changeMenu(i) {
      this.menuIndex = i;
      this.hasMore = true;
    },
    sort(m, n) {
      if (m === 'area') {
        this.areaSort = n;
        this.totalDivision[this.menuIndex].data.sort(this.compare('area', n));
      }
      if (m === 'update') {
        this.updateSort = n;
        this.totalDivision[this.menuIndex].data.sort(this.compare('createdAt', n));
      }
      if (m === 'price') {
        this.priceSort = n;
        if (this.priceType === 1) this.totalDivision[this.menuIndex].data.sort(this.compare('averagePrice', n));
        if (this.priceType === 2) this.totalDivision[this.menuIndex].data.sort(this.compare('consultPrice', n));
      }
    },
    compare(property, n) {
      return function (a, b) {
        if (n === 1) return parseInt(a[property]) - parseInt(b[property]);
        if (n === 2) return parseInt(b[property]) - parseInt(a[property]);
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.body-item {
  background: white;
  ul {
    display: flex;
    border-bottom: 1px solid var(--color);
    li {
      width: 100px;
      cursor: pointer;
      display: inline-grid;
      &.isActive {
        color: white;
        background-color: var(--color);
      }
    }
  }
  .filter {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    &-item {
      height: 40px;
      color: #000000;
      font-size: 12px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon-box {
        display: flex;
        flex-direction: column;
        padding-left: 4px;
      }
      .icon {
        font-size: 7px;
        color: #b2b2b2;
        cursor: pointer;
      }
      .iconActive {
        color: var(--color);
      }
      .price {
        cursor: pointer;
      }
      .dot {
        font-size: 8px;
        margin: 0 3px;
      }
      .gray {
        color: #b2b2b2;
      }
    }
  }
  .lookMore {
    color: #666;
    &:hover {
      color: var(--color);
    }
  }
}
</style>
