<template>
  <div class="exp" @click="exp">
    <span v-show="!modelValue">
      <span>展开选项</span>
      <i class="icon icon-decline-filling"></i>
    </span>
    <span v-show="modelValue">
      <span>收起选项</span>
      <i class="icon icon-rise-filling"></i>
    </span>
  </div>
</template>

<script setup>
const emit = defineEmits(['exp']);
const props = defineProps({ modelValue: Boolean });
function exp() {
  emit('update:modelValue', !props.modelValue);
}
</script>
<style lang="scss" scoped>
.exp {
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  i {
    opacity: 0.8;
  }
}
</style>
