<template>
  <div v-size:350*400 class="info">
    <!--    二手房-->
    <template v-if="$route.name.includes('house-detail')">
      <div class="info-price pb-6">
        <template v-if="$route.query.from.includes('rent')">
          <span class="mr-1 f-10">{{ $cov($attrs.rentPrice).num || '--' }}</span>
          <div class="pb-1 f-4">
            <span class="mr-4">{{ $cov($attrs.rentPrice).unit || '--' }}</span>
          </div>
        </template>
        <template v-else>
          <span class="mr-1 f-10">{{ $cov($attrs.salePrice).num || '--' }}</span>
          <div class="pb-1 f-4">
            <span class="mr-4">{{ $cov($attrs.salePrice).unit }}</span>
          </div>
          <div class="pos-re d-sb d-f1">
            <span class="pos-ab b-7">单价 {{ $attrs.saleSinglePrice || '--' }} </span>
            <span style="font-size: 12px" v-color:gray>首付及税费情况请咨询经纪人</span>
            <router-link style="font-size: 12px" v-color:blue :to="'tools?price=' + $attrs.salePrice" target="_blank">贷款计算器</router-link>
          </div>
        </template>
      </div>
    </template>
    <div class="info-price pb-6" v-else>
      <span class="mr-1 f-10">{{ $cov($attrs.consultPrice).num }}</span>
      <div class="pb-1 f-4">
        <span class="mr-4">{{ $route.query.from.includes('rent') ? '元/月' : '万' }}</span>
        <span>
          单价 {{ parseFloat($attrs.buildArea) * 1 > 0 ? parseFloat(((parseFloat($attrs.consultPrice) * multiple) / parseFloat($attrs.buildArea)).toFixed(2)) : 0
          }}{{ $route.query.from.includes('rent') ? '元/月/㎡' : '元/㎡' }}
        </span>
      </div>
    </div>
    <hr />
    <div class="info-prop py-6">
      <template v-if="root.name === 'house'">
        <div class="info-prop-item">
          <span class="f-6">{{ $attrs.houseLayout || '--' }}</span>
          <span class="f-4 mt-1 sub" v-if="$attrs.floor">{{ $attrs.floor || '--' }}|共{{ $attrs.totalFloor }}层</span>
          <span class="f-4 mt-1 sub" v-else>{{ $attrs.houseUseName }}</span>
        </div>
        <vr />
        <div class="info-prop-item" v-if="!isOne">
          <span class="f-6">{{ $attrs.orientationName || '--' }}</span>
          <span class="f-4 mt-1 sub">{{ $attrs.renovationName || '--' }}|{{ $attrs.architecturalTypeName }}</span>
        </div>
        <div class="info-prop-item" v-if="isOne">
          <span class="f-6">{{ $attrs.buildArea || '--' }}</span>
          <span class="f-4 mt-1 sub">{{ $attrs.orientationName || '--' }}</span>
        </div>
        <vr />
      </template>
      <!--  二手房源显示建筑面积    -->
      <div class="info-prop-item" v-if="!isOne">
        <span class="f-6">{{ $attrs.buildArea || '--' }}</span>
        <span class="f-4 mt-1 sub">建筑面积</span>
      </div>
      <vr v-if="root.name !== 'house' && !isOne"></vr>
      <template v-if="root.name === 'office'">
        <template v-if="isOne">
          <div class="info-prop-item">
            <span class="f-6">{{ $attrs.buildArea || '--' }}</span>
            <span class="f-4 mt-1 sub">建筑面积</span>
          </div>
        </template>
        <div class="info-prop-item" v-if="!isOne">
          <span class="f-6">{{ $attrs.renovationName || '--' }}</span>
          <span class="f-4 mt-1 sub">装修</span>
        </div>
        <vr />
        <div class="info-prop-item">
          <span class="f-6">{{ station || '--' }}</span>
          <span class="f-4 mt-1 sub">可容纳工位</span>
        </div>
      </template>
      <template v-if="root.name === 'factory'">
        <div class="info-prop-item" v-if="!isOne">
          <span class="f-6">{{ $attrs.buildingUseName || '--' }}</span>
          <span class="f-4 mt-1 sub">用途</span>
        </div>
        <div class="info-prop-item" v-else>
          <span class="f-6">{{ $attrs.buildArea || '--' }}</span>
          <span class="f-4 mt-1 sub">建筑面积</span>
        </div>
        <vr />
        <div class="info-prop-item">
          <span class="f-6">{{ $attrs.houseUseName || '--' }}</span>
          <span class="f-4 mt-1 sub">房屋类型</span>
        </div>
      </template>
      <template v-if="root.name === 'shop'">
        <div class="info-prop-item" v-if="!isOne">
          <span class="f-6">{{ $attrs.renovationName || '--' }}</span>
          <span class="f-4 mt-1 sub">{{ $attrs.orientationName || '--' }}|{{ $attrs.architecturalTypeName }}</span>
        </div>
        <div class="info-prop-item" v-else>
          <span class="f-6">{{ $attrs.buildArea || '--' }}</span>
          <span class="f-4 mt-1 sub">建筑面积</span>
        </div>
        <vr />
        <div class="info-prop-item">
          <span class="f-6">{{ $attrs.houseUseName || '--' }}</span>
          <span class="f-4 mt-1 sub">房屋类型</span>
        </div>
      </template>
      <vr v-if="root.name !== 'house' && isOne"></vr>
      <!--只有一手房源才显示状态-->
      <div class="info-prop-item" v-if="isOne">
        <span class="f-6">{{ $attrs.firstHandHouseStatusName || '--' }}</span>
        <span class="f-4 mt-1 sub">房源状态</span>
      </div>
    </div>
    <hr />
    <div class="info-desc py-6">
      <div class="info-desc-item f-4 pb-2" v-if="!isOne">
        <span class="title">小区名称</span>
        <p class="sub">{{ $attrs.buildingName || '--' }}</p>
      </div>
      <div class="info-desc-item f-4 pb-2" v-if="!isQian">
        <span class="title">房源编号</span>
        <p class="sub">{{ $attrs.houseNo || $attrs.firstHouseNo }}</p>
      </div>
      <div class="info-desc-item f-4 pb-2" v-if="$attrs.buildingUse">
        <span class="title">房源用途</span>
        <p class="sub">{{ $attrs.buildingUseName }}</p>
      </div>
      <div class="info-desc-item f-4 pb-2" v-if="!isOne">
        <span class="title">
          <span>实用率</span>
          <span title="该信息由业主方提供，仅供参考，具体请以实际为准" class="icon icon-help mx-1"></span>
        </span>
        <span class="sub">{{ $attrs.utilityRate }}（详情咨询服务顾问）</span>
      </div>
      <template v-if="['office', 'shop'].includes(root.name) && isOne">
        <div class="info-desc-item f-4 pb-2">
          <span class="title">户型结构</span>
          <p class="sub">{{ $attrs.room + '-' + $attrs.office + '-' + $attrs.toilet }}</p>
        </div>
        <div class="info-desc-item f-4 pb-2">
          <span class="title">窗面朝向</span>
          <p class="sub">{{ $attrs.orientationName }}</p>
        </div>
      </template>
      <div class="info-desc-item f-4">
        <span class="title">地理位置</span>
        <p class="sub">{{ $attrs.regionName }}-{{ $attrs.circleName }} {{ $attrs.address }}</p>
      </div>
    </div>
    <hr />
  </div>
</template>
<script>
export default {
  computed: {
    station() {
      if (this.$attrs.buildArea) {
        let arr = this.$attrs.buildArea.split('-');
        if (arr.length === 1) {
          if (parseFloat(arr[0]) % 4 === 0) return `${parseFloat(arr[0]) / 4}-${parseFloat(arr[0]) / 4 + 1}人`;
          return `${Math.floor(parseFloat(arr[0]) / 4)}-${Math.ceil(parseFloat(arr[0]) / 4)}`;
        }
        return `${Math.ceil(parseFloat(arr[0]) / 4)}-${Math.ceil(parseFloat(arr[1]) / 4)}人`;
      }
      return '';
    },
    multiple() {
      if (this.$route.query.from.includes('sale')) return 10000;
      return 1;
    },
    isQian() {
      return isQian;
    },
  },
};
</script>

<style lang="scss" scoped>
.info {
  &-price {
    color: red;
    display: flex;
    align-items: flex-end;
  }

  &-prop {
    display: flex;
    justify-content: space-between;

    &-item {
      display: flex;
      flex-direction: column;
      text-align: left;
      .sub {
        font-size: 12px;
        color: #4a4a4a;
      }
    }
  }

  &-desc {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &-item {
      width: 100%;
      display: flex;

      .title {
        flex: 0 0 6em;
        text-align: left;
        font-size: 12px;
        color: #b2b2b2;
      }

      .sub {
        flex: 1;
        text-align: left;
        font-size: 12px;
        color: #4a4a4a;
      }
    }
  }

  .agent {
    position: sticky;
    top: 40px;
    height: 0;
  }
}
</style>
