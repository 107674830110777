<template>
  <div class="edifice-card" @click="detail">
    <img :src="obj.fileImg" alt="" />
    <div class="buildingName" v-if="$route.name.includes('building-detail') || $route.name.includes('first-detail')">
      {{ obj.title }}
    </div>
    <div class="buildingName" v-else>
      <p>{{ obj.buildingName }}</p>
      <p v-if="$route.fullPath.includes('house.building') && !$route.fullPath.includes('house-detail')" class="average-price">
        <span v-color:gray class="pr-4">均价</span>
        <span v-color:red>{{ obj.averagePrice }}</span>
      </p>
    </div>
    <template v-if="obj.tradeType">
      <div class="price" v-if="obj.tradeType - 1 === 0">
        {{ $cov(obj.rentPrice).num || $cov(obj.priceSingleSet).num }}{{ $cov(obj.rentPrice).unit || $cov(obj.priceSingleSet).unit }}
      </div>
      <div class="price" v-if="obj.tradeType - 2 === 0">
        {{ $cov(obj.salePrice).num || $cov(obj.priceSingleSet).num }}{{ $cov(obj.salePrice).unit || $cov(obj.priceSingleSet).unit }}
      </div>
      <div class="order-num" v-if="obj.distance">距离此楼盘{{ obj.distance }}米</div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'edifice-card',
  props: { obj: Object, lat: String, lng: String },
  methods: {
    detail() {
      let name = 'house-detail';
      if (this.$route.name.includes('house-detail')) name = 'house-detail';
      if (this.$route.name.includes('room-detail')) name = 'house-detail';
      if (this.$route.name.includes('building-detail')) name = 'building-detail';
      if (this.$route.name.includes('first-detail')) name = 'building-detail';
      if (this.$route.name.includes('edifice-detail')) name = 'house-detail'; //大厦详情推荐房源
      if (this.$route.query.from === 'house.building') name = 'edifice-detail'; //小区详情推荐小区
      this.$router.push({ name: this.root.name + '.' + name, query: { id: this.obj.id, from: this.$route.query.from } });
    },
  },
};
</script>

<style lang="scss" scoped>
.edifice-card {
  width: 200px;
  height: 180px;
  text-align: left;
  cursor: pointer;
  img {
    width: 200px;
    height: 140px;
    border-radius: 6px;
  }

  .buildingName {
    padding: 5px 0 2px 0;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .average-price {
    font-size: 12px;
    margin-top: 3px;
  }
  .price {
    padding: 1px 0 3px 1px;
    color: #cc2929;
    font-size: 12px;
  }

  .order-num {
    color: #b2b2b2;
    font-size: 12px;
  }
}
</style>
