<template>
  <div class="banner">
    <div class="line">
      <a-carousel style="width: 680px; height: 400px" :fileImg="props.fileImg || props.imgList" />
      <a-map style="width: 350px; height: 400px" :lat="lat" :lng="lng" />
    </div>
    <div class="tips py-2" v-if="panorama">
      <img class="tips-vr mr-2" src="https://static.ajl.cn/web/vr.png" alt="全景看房" />
      <span class="f-4">本楼盘已支持VR看房，在线看房，省时省力</span>
      <span class="btn color f-4 end" @click="look">立即看房</span>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({ fileImg: Array, imgList: Array, panorama: String, lat: String, lng: String });
const look = () => {
  window.open(props.panorama);
};
</script>
<style lang="scss" scoped>
.banner {
  margin: auto;
  width: 1050px;

  .line {
    display: flex;
    justify-content: space-between;
  }

  .tips {
    width: 680px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ccc;

    &-vr {
      height: 4rem;
      list-style: none;
    }
  }
}
</style>
