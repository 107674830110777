<template>
  <div class="hot-circle-warp" ref="el">
    <!-- *********************** 精选二手房 *********************** -->
    <div class="hot-circle">
      <div class="hot-circle-name">
        <span>优选出售二手房</span>
        <span>似曾相识，想要的家</span>
        <router-link to="/house/sale">更多&gt;</router-link>
      </div>
      <div class="hot-circle-body c-4">
        <div class="item" v-for="a in secondHouse?.slice(0, 4)" :key="a.photo" @click="detail(a.id, a.tradeTypeName)">
          <img :src="a.photo" alt="" class="item-photo" />
          <div class="item-intro">
            <div class="inline">
              <span>{{ a.buildingName }}</span>
              <span>{{ a.regionName }}-{{ a.cateCircleName }}</span>
            </div>
            <div class="inline">
              <span v-color:gray>{{ a.layout }} <small v-color:#ccc>|</small> {{ a.area || a.buildArea }}</span>
              <span v-color:red>{{ a.salePrice }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- *********************** 精选小区 *********************** -->
    <div class="hot-circle">
      <div class="hot-circle-name">
        <span>小区精选</span>
        <span>真实信息准确同步，楼盘动态一手掌握</span>
        <router-link to="/house/building">更多&gt;</router-link>
      </div>
      <div class="hot-circle-body c-3">
        <template v-for="a in building?.slice(0, 3)" :key="a.photo">
          <div class="item one" @click="buildingDetail(a.buidlingId)" :style="`background-image:url(${a.photo})`">
            <div class="info">
              <span>{{ a.buildingName }}</span>
              <span>{{ a.averagePrice }}</span>
            </div>
          </div>
        </template>
      </div>
    </div>
    <!-- *********************** 精选新房 *********************** -->
    <div class="hot-circle">
      <div class="hot-circle-name">
        <span>优选新房</span>
        <span>为你精挑细选好楼盘</span>
        <router-link to="/house/first-sale">更多&gt;</router-link>
      </div>
      <div class="hot-circle-body c-3">
        <template v-for="a in (project || projectManagement)?.slice(0, 3)" :key="a.photo">
          <div class="item one" @click="projectDetail(a.id, a.tradeTypeName)" :style="`background-image:url(${a.photo})`">
            <div class="info">
              <span>{{ a.buildingName }}</span>
              <span>{{ a.averagePrice }}</span>
            </div>
          </div>
        </template>
      </div>
    </div>
    <!-- *********************** 精选租房 *********************** -->
    <div class="hot-circle">
      <div class="hot-circle-name">
        <span>优选出租房</span>
        <span>高品质租房体验，打造温馨入住</span>
        <router-link to="/house/rent">更多&gt;</router-link>
      </div>

      <div class="hot-circle-body c-4">
        <div class="item" v-for="a in rentHouse?.slice(0, 4)" :key="a.photo" @click="detail(a.id, a.tradeTypeName)">
          <img :src="a.photo" alt="" class="item-photo" />
          <div class="item-intro">
            <div class="inline">
              <span>{{ a.buildingName }}</span>
              <span>{{ a.regionName }}-{{ a.cateCircleName }}</span>
            </div>
            <div class="inline">
              <span v-color:gray>{{ a.layout }} <small v-color:#ccc>|</small> {{ a.area || a.buildArea }}</span>
              <span v-color:red>{{ a.rentPrice }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ************************************************************* -->
  </div>
</template>

<script>
export default {
  name: 'hot-circle',
  props: {
    hotTradingArea: Array,
    building: Array,
    secondHouse: Array,
    rentHouse: Array,
    project: Array,
    projectManagement: Array,
  },
  methods: {
    buildingDetail(buidlingId) {
      this.$router.push({ name: 'house.edifice-detail', query: { id: buidlingId, from: 'house.building' } });
    },
    projectDetail(id, tradeTypeName) {
      let from = 'house.first-sale';
      if (tradeTypeName === '出租') from = 'house.first-rent';
      this.$router.push({ name: 'house.building-detail', query: { id, from: from } });
    },
    detail(id, tradeTypeName) {
      let from = 'house.sale';
      if (tradeTypeName === '出租') from = 'house.rent';
      this.$router.push({ name: 'house.house-detail', query: { id, from: from } });
    },
  },
  mounted() {
    this.$lazy(this.$el.querySelectorAll('img'));
  },
};
</script>

<style lang="scss" scoped>
.hot-circle {
  width: 1050px;
  margin: auto;

  &-warp {
    background-color: $bg-gray;
  }

  &-name {
    position: relative;
    text-align: left;
    padding-top: 4rem;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
    span:first-of-type {
      font-size: 4rem;
    }
    span:last-of-type {
      font-size: 2.5rem;
      color: #766;
    }
    a {
      position: absolute;
      right: 0;
      padding: 2rem;
      font-size: 2rem;
      letter-spacing: 3px;
      color: gray;
      &:hover {
        color: var(--color);
      }
    }
  }

  &-body {
    display: grid;
    gap: 2rem;

    &.c-4 {
      grid-template-columns: repeat(4, 1fr);
    }

    &.c-3 {
      grid-template-columns: repeat(3, 1fr);
    }

    .left {
      grid-area: 1/1/3/2;
    }

    .item {
      @extend .fd;
      position: relative;
      cursor: pointer;
      &.one {
        height: 260px;
        width: 100%;
        background-size: cover;
        display: grid;
        place-content: center;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(black, 0.5);
        }
        .info {
          display: grid;
          place-content: center;
          position: relative;
          z-index: 9;
          color: #fff;
          span:first-of-type {
            font-size: 3rem;
          }
          span:last-of-type {
            font-size: 2rem;
            line-height: 4rem;
          }
        }
      }
      &-photo {
        height: 174px;
        width: 100%;
        object-fit: cover;
      }
      &-intro {
        .inline {
          display: flex;
          justify-content: space-between;
          padding: 1rem 2rem;
          span {
            font-size: 2rem;
          }
        }
      }
    }
  }
}
</style>
