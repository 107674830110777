<template>
  <div class="mobile">
    <div class="contact-box">
      <div class="title">
        <i class="icon icon-telephone-out"></i>
        <p class="title-name">免费咨询</p>
      </div>
      <p class="tel">{{ obj.tel }}</p>
      <p class="time">（早9:00-晚8:30）</p>
    </div>
    <div class="contact-box follow-box">
      <div class="collect" @click="getQR">
        <i class="icon icon-fabulous"></i>
        <span class="collect-label">关注</span>
      </div>
      <div class="QR-box">
        <div class="collect-box-img" v-if="isQR" @click="isQR = false">
          <span class="label">扫码关注</span>
          <img :src="QRImg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'a-mobile',
  data() {
    return {
      isQR: false,
      QRImg: '',
    };
  },
  computed: {
    obj() {
      return window.nav.obj;
    },
  },
  methods: {
    async getQR() {
      this.isQR = !this.isQR;
      if (this.isQR && !this.QRImg) {
        let url = 'office/company-config';
        const { data } = await http(url);
        this.QRImg = data.url;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile {
  background: #ffffff;
  padding: 20px;
  text-align: left;
  margin-top: 2rem;
  z-index: 99;
  .contact-box {
    .title {
      display: flex;
      align-items: center;
      .icon {
        font-size: 18px;
        color: #000;
      }
      &-name {
        color: #000;
        font-weight: 600;
        font-size: 14px;
        margin-left: 4px;
      }
    }
    .tel {
      padding-left: 22px;
      margin: 10px 0 0 0;
      height: 42px;
      line-height: 40px;
      color: #d0021b;
      font-weight: 500;
      font-size: 28px;
    }
    .time {
      padding-left: 22px;
      margin: 5px 0 0 0;
      color: #b2b2b2;
      font-size: 12px;
      line-height: 17px;
    }
    .collect {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      border: 1px solid #ccc;
      color: #666;
      font-size: 16px;
      cursor: pointer;
      .icon-fabulous {
        font-size: 20px;
        margin-right: 4px;
        color: #666;
      }
    }
  }
  .follow-box {
    position: relative;
    margin: 30px 0 0 0;
    padding: 30px 0 0 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    .QR-box {
      position: absolute;
      left: 85px;
      top: -130px;
    }
    .collect-box-img {
      position: relative;
      padding: 10px;
      width: 150px;
      height: 150px;
      background-color: white;
      filter: drop-shadow(0 0 1rem #666);
      border-radius: 6px;
      &:after {
        content: '';
        border: transparent 15px solid;
        border-top-color: white;
        position: absolute;
        bottom: -30px;
        left: 62px;
        filter: drop-shadow(0 0 1rem #666);
      }
      &:before {
        content: '';
        bottom: 0;
        left: 0;
        right: 0;
        height: 2rem;
        position: absolute;
        z-index: 11;
        background: #fff;
        border-radius: 6px;
      }
      .label {
        font-size: 2rem;
        display: block;
        text-align: center;
        margin-bottom: 10px;
      }
      img {
        width: 100px;
        height: 100px;
        background-color: red;
        display: block;
        margin: 0 auto;
      }
    }
  }
}
</style>
