<template>
  <div class="detail py-4">
    <a-bamboo :bamboo="bamboo" />
    <div class="line">
      <div class="name mb-2">
        <span v-if="root.name === 'house'" class="color">{{ _title }}</span>
        <span v-else class="color" :class="{ btn: isOne }" @click="detail">{{ buildingName || _title }}</span>
        <template v-if="root.name !== 'house'">
          <span class="mx-2">·</span>
          <span>{{ buildArea }}</span>
        </template>
      </div>
      <ul class="tag">
        <li class="mr-2 f-2" :style="a" v-for="a in tag" :key="a.name">{{ a.name }}</li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    bamboo: Array,
    _title: String,
    room: String,
    buildArea: String,
    houseTagName: String,
    buildingData: Object,
    buildingName: String,
  },
  computed: {
    tag() {
      if (!this.houseTagName) return [];
      return this.houseTagName.split(',').map((name) => {
        const randomColor = () => `hsl(${Math.floor(Math.random() * 360)},50%,50%)`;
        const backgroundColor = randomColor();
        return { name, backgroundColor };
      });
    },
  },
  methods: {
    detail() {
      this.$router.push({ name: this.root.name + '.building-detail', query: { id: this.buildingData.buildingId } });
    },
  },
};
</script>
<style lang="scss" scoped>
.detail {
  padding: 2rem 0;
  .line {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 1050px;
    margin: auto;
    .name {
      font-size: 3rem;
    }
    .tag {
      display: flex;
      align-items: center;
      li {
        color: white;
        padding: 0 1rem;
      }
    }
  }
}
</style>
