import md5 from 'md5';
const queued = new Map();
const isDev = process.env.NODE_ENV !== 'production';
const baseUrl = '/api/v2/';
const headers = { shopSite: isDev ? 'ajl.qfbj.cn' : location.host };
window.http = async function (url = '', obj = {}, expired = 3600) {
  const key = md5(JSON.stringify(url) + JSON.stringify(obj));
  if (!isDev) {
    if (store.has(key)) return store.get(key);
  }
  if (queued.has(key)) return queued.get(key);
  const handler = async (rq, resolve, reject) => {
    const jsonObj = await rq;
    const { Data, List, Msg, State } = await jsonObj.json();
    if (Data.title) {
      Data._title = Data.title;
      delete Data.title; //去除翰墨提示
    }
    if (Data.isShow === false) {
      alert('资源已下架');
    }
    const rs = { data: Data, list: List, msg: Msg };
    if (State === 1) {
      store.set(key, rs, expired);
      return resolve(rs);
    }
    alert(Msg ?? '系统错误');
    reject(Msg);
  };
  const err = (reject) => {
    alert('请求异常');
    reject('请求异常');
  };
  const will = new Promise((resolve, reject) => {
    url = baseUrl + url;
    try {
      const body = JSON.stringify(obj);
      headers.isSale = location.href.match('sale') ? '1' : '0';
      const rq = fetch(url, { mode: 'cors', method: 'POST', body, headers });
      handler(rq, resolve, reject);
      setTimeout(() => queued.delete(key), 300);
    } catch (e) {
      err(reject);
      queued.delete(key);
    }
  });
  queued.set(key, will);
  return will;
};
