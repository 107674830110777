<template>
  <div class="container">
    <div class="container-body">
      <slot></slot>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: center;
  background-color: $bg-gray;
  padding-top: 1rem;
  &-body {
    width: 1050px;
    display: flex;
    justify-content: space-between;
  }
}
</style>
