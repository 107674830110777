// import AOS from 'aos';
// import 'aos/dist/aos.css';
// AOS.init({
//   offset: 200,
//   duration: 600,
//   easing: 'ease-in-sine',
//   delay: 20,
// });
window.goTop = function () {
  window.scrollTo({ top: 0, behavior: 'auto' });
};
