<template>
  <div class="foot">
    <a :href="`tel:${obj.tel}`" class="icon icon-customer-service foot-tel">&ensp;{{ obj.tel }}</a>
    <div class="line" v-if="root.name === 'house'">
      <router-link to="/house/sale" class="lnk">二手房</router-link>
      <router-link to="/house/first-sale" class="lnk">新房</router-link>
      <router-link to="/house/rent" class="lnk">租房</router-link>
      <router-link to="/house/building" class="lnk">小区</router-link>
    </div>
    <div class="line" v-if="root.name === 'office'">
      <router-link to="/office/sale" class="lnk">写字楼出售</router-link>
      <router-link to="/office/rent" class="lnk">写字楼出租</router-link>
      <router-link to="/office/first-sale" class="lnk">一手写字楼出租</router-link>
      <router-link to="/office/first-rent" class="lnk">一手写字楼出售</router-link>
    </div>
    <div class="line" v-if="root.name === 'factory'">
      <router-link to="/factory/sale" class="lnk">厂房土地出售</router-link>
      <router-link to="/factory/rent" class="lnk">厂房土地出租</router-link>
      <router-link to="/factory/first-sale" class="lnk">一手厂房土地出售</router-link>
      <router-link to="/factory/first-rent" class="lnk">一手厂房土地出租</router-link>
    </div>
    <div class="line" v-if="root.name === 'shop'">
      <router-link to="/shop/sale" class="lnk">商铺出售</router-link>
      <router-link to="/shop/rent" class="lnk">商铺出租</router-link>
      <router-link to="/shop/first-sale" class="lnk">一手商铺出售</router-link>
      <router-link to="/shop/first-rent" class="lnk">一手商铺出租</router-link>
    </div>
    <a class="foot-copyright">
      <span>Copyright © 2016 - 至今 {{ obj.companyName }}. All Rights Reserve</span>
      <a href="https://beian.miit.gov.cn">苏ICP备2021051892号</a>
    </a>
  </div>
  <div class="share" @click="share">
    <span class="icon icon-fenxiang"></span>
    <span>门店</span>
  </div>
</template>

<script setup>
import { computed } from 'vue';
const share = () => window.nav.$router.push({ name: window.nav.root.name + '.store' });
const obj = computed(function () {
  return window.nav.obj;
});
</script>

<style lang="scss" scoped>
.share {
  position: fixed;
  top: 100px;
  right: 20px;
  background-color: white;
  border-radius: 20px;
  padding: 5px;
}
.foot {
  background-color: #333;
  padding: 6rem 0;
  &-tel {
    font-size: 3rem;
  }
  &-copyright {
    font-size: 2rem;
    opacity: 0.5;
  }
  .lnk {
    &:hover {
      color: var(--color) !important;
    }
  }
}
.line {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  a {
    font-size: 2rem;
    padding: 1rem;
  }
}
a {
  color: white !important;
}
</style>
