<template>
  <div class="agent">
    <div class="agent-info">
      <img :src="photo" alt="" class="head" @click="store" />
      <div class="agent-info-box">
        <div class="name-box">
          <span class="name">{{ name }}</span>
          <span class="company" v-if="companyName">{{ companyName }}</span>
        </div>
        <div class="evaluate" v-if="isLook">{{ content }}</div>
        <div class="evaluate" v-else>{{ introduce }}</div>
        <span class="mobile" @click.stop="isMobile = true">
          <span class="icon icon-dianhua"></span>
          <span>拨打电话</span>
        </span>
        <span class="mobile order" @click.stop="order" v-if="!noOrder">
          <span class="icon icon-clock-filling"></span>
          <span>立即预约</span>
        </span>
      </div>
    </div>
    <store-mobile v-if="isMobile" class="mobile-ps" :mobile="mobile" :orCode="orCode"></store-mobile>
  </div>
</template>

<script>
import aOrder from '@/components/a-order';
export default {
  name: 'a-agent',
  props: {
    userId: Number,
    photo: String,
    name: String,
    mobile: String,
    orCode: String,
    content: String,
    isLook: Boolean,
    companyName: String,
    relationId: Number,
    type: String,
    noOrder: Boolean,
  },
  data() {
    return {
      isMobile: false,
    };
  },
  computed: {
    introduce() {
      let txt = '';
      switch (this.root.name) {
        case 'office':
          txt = '优秀经纪人，熟悉本写字楼详情';
          break;
        case 'shop':
          txt = '优秀经纪人，熟悉本商铺详情';
          break;
        case 'factory':
          txt = '优秀经纪人，熟悉本厂房土地详情';
          break;
        default:
          txt = '优秀经纪人，熟悉本详情';
          break;
      }
      return txt;
    },
    showMobile() {
      if (this.mobile) return this.mobile.replace(',', '转');
      return '';
    },
  },
  methods: {
    store() {
      if (isQian) return;
      this.$router.push({ name: this.root.name + '.store', query: { id: this.userId } });
    },
    order() {
      this.$modal(aOrder, { agentUserId: this.userId, relationId: this.relationId, type: this.type });
    },
  },
};
</script>
<style lang="scss" scoped>
.agent {
  background-color: white;
  position: relative;
  &-info {
    display: flex;
    padding: 20px;
    .head {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      cursor: pointer;
    }

    &-box {
      margin-left: 10px;
      flex: 1;
      text-align: left;

      .name {
        font-size: 12px;
        font-weight: 550;
        line-height: 20px;
        margin-bottom: 6px;
        margin-right: 6px;
      }
      .company {
        height: 18px;
        line-height: 18px;
        padding: 0 4px;
        color: #849aae;
        font-size: 12px;
        background-color: #f4f7f9;
      }
      .tel {
        font-size: 16px;
        color: #d0021b;
      }

      .label {
        line-height: 17px;
        font-size: 12px;
        color: #b2b2b2;
      }

      .evaluate {
        margin: 5px 0;
        color: #b2b2b2;
        font-size: 12px;
      }
    }
  }
  .mobile {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 3px 6px;
    color: var(--color);
    font-size: 12px;
    background: var(--color10);
    text-align: center;
    cursor: pointer;
  }
  .mobile-ps {
    position: absolute;
    top: 0;
    left: -215px;
  }
  .order {
    display: inline-block;
    margin-left: 10px;
    background: rgba(152, 241, 143, 0.3);
    color: #4bbb70;
  }
}
</style>
