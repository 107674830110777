<template>
  <div class="detail py-4">
    <a-bamboo :bamboo="$attrs.bamboo" />
    <div class="detail-body">
      <div class="line">
        <span class="line-name f-6">
          <span class="room-label" v-if="$route.name.includes('room-detail')" @click="detail">{{ $attrs.buildingName }}</span>
          <span v-if="$route.name.includes('edifice-detail')">{{ $attrs.buildingName }}</span>
          <span v-if="$route.name.includes('building-detail')">
            <span>{{ $attrs._title }}</span>
            <b class="br-2 ml-2 f-2 px-1" v-bg:red v-color:#fff>{{ $attrs.projectStatusName }}</b>
          </span>
          <span v-if="$attrs.roomArea">·{{ $attrs.roomArea }}</span>
        </span>
        <span class="" style="margin-left: auto">
          <template v-if="$route.name.includes('room-detail')">
            <b class="f-8" v-color:red>{{ $cov($attrs.rentPrice).num }}</b>
            <span v-color:red>{{ $cov($attrs.rentPrice).unit }} </span>
          </template>
          <template v-else>
            <span class="f-2" v-color:gray>参考均价</span>
            <b class="f-8" v-color:red>{{ $cov($attrs.averagePrice).num }}</b>
            <span v-color:red>{{ $cov($attrs.averagePrice).unit }} </span>
          </template>
        </span>
        <template v-if="isOne">
          <span class="pl-4 pos-re">
            <span class="f-2" v-color:gray>参考总价</span>
            <b class="f-8" v-color:red>{{ $cov($attrs.priceSingleSet || $attrs.rentPrice).num }}</b>
            <span v-color:red>{{ $cov($attrs.priceSingleSet || $attrs.rentPrice).unit }}</span>
            <span class="pos-ab t-10 pt-2 l-4 f-2">
              <span v-color:gray>开盘日期</span>
              <b class="pl-2 f-4">{{ $attrs.openDate || $attrs.openningDate }}</b>
            </span>
          </span>
        </template>
      </div>
      <div class="line">
        <span class="icon icon-map"></span>
        <span class="line-text">
          <span class="btn room-label" @click="list('region')">{{ $attrs.regionName }}</span> -
          <span class="btn room-label" @click="list('cateCircle')">{{ $attrs.cateCircleName }}</span>
        </span>
      </div>
      <div class="line">
        <span class="icon icon-office"></span>
        <span>{{ $attrs.address || $attrs.buildingAddress }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    detail() {
      this.$router.push({ name: this.root.name + '.house-detail', query: { id: this.$attrs.houseId } });
    },
    list(v) {
      let query = {};
      query.region = this.$attrs.region;
      if (v === 'cateCircle') query.cateCircle = this.$attrs.cateCircle;
      this.$router.push({ name: this.root.name + '.' + this.$route.query.from, query });
    },
  },
};
</script>

<style lang="scss" scoped>
.detail {
  margin: auto;
  width: 1050px;
  &-body {
    .line {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;
      font-size: 2rem;
      .icon {
        margin-right: 0.5rem;
        font-size: 3rem;
      }
    }
  }
}
.room-label {
  &:hover {
    cursor: pointer;
    color: var(--color);
  }
}
</style>
