<template>
  <div class="hot-circle-warp">
    <!-- *********************** 热门商圈 *********************** -->
    <div class="hot-circle">
      <div class="hot-circle-name">热门商圈</div>
      <div class="hot-circle-body">
        <div class="item" :class="{ left: i === 0 }" v-for="(a, i) in hotTradingArea" :key="i" @click="go(a)">
          <img :src="a.img" alt="" />
          <span class="item-name">{{ a.name }}</span>
        </div>
      </div>
    </div>
    <!-- *********************** 不容错过的办公室 *********************** -->
    <div class="hot-circle">
      <div class="hot-circle-name">不容错过的办公室</div>
      <div class="hot-circle-body c-12">
        <div class="item" v-for="{ photo, buildingName, regionName, cateCircleName, id, tradeTypeName } in officeList || rentHouse" :key="photo" @click="detail(id, tradeTypeName)">
          <img :src="photo" alt="" class="officeImg" />
          <div class="item-intro">
            <h3>{{ buildingName }}</h3>
            <span>{{ regionName }}-{{ cateCircleName }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- ************************************************************* -->
  </div>
</template>

<script>
export default {
  name: 'hot-circle',
  props: {
    hotTradingArea: Array,
    officeList: Array,
    rentHouse: Array,
  },
  methods: {
    go(a) {
      this.$router.push({
        path: '/office/rent',
        query: {
          region: a.region,
          cateCircle: a.cateCircle,
        },
      });
    },
    async detail(id, tradeTypeName) {
      let from = 'office.sale';
      if (tradeTypeName === '出租') from = 'office.rent';
      const { href } = await this.$router.resolve({ name: 'office.house-detail', query: { id, from: from } });
      window.open(href, '_blank');
    },
  },
  mounted() {
    this.$lazy(this.$el.querySelectorAll('img'));
  },
};
</script>

<style lang="scss" scoped>
.hot-circle {
  width: 1050px;
  margin: auto;

  &-warp {
    padding: 4rem 0;
    background-color: $bg-gray;
  }

  &-name {
    display: inline-block;
    font-size: 4rem;
    position: relative;
    margin: 8rem;
    &:after {
      content: '';
      position: absolute;
      left: -4px;
      right: -4px;
      bottom: -8px;
      height: 4px;
      background-color: var(--color);
    }
  }

  &-body {
    display: grid;
    gap: 2rem;
    grid-template-rows: repeat(2, 260px);
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 4rem;
    &.c-12 {
      grid-template-rows: repeat(4, 260px);
    }
    .left {
      grid-area: 1/1/3/2;
    }
    .item {
      @extend .fd;
      position: relative;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .officeImg {
        height: 80%;
      }
      &-name {
        font-size: 2rem;
        color: white;
        position: absolute;
        left: 1rem;
        bottom: 1rem;
      }
      &-intro {
        height: 20%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h3 {
          font-size: 2rem;
        }
        span {
          font-size: 2rem;
          color: #b2b2b2;
        }
      }
    }
  }
}
</style>
