<template>
  <div class="mobile" @click.stop="isMobile = true">
    <span class="icon icon-dianhua"></span>
    拨打电话
    <div class="pop" v-if="isMobile">
      <div class="min">
        <img :src="orCode" alt="" />
        <span class="label">微信扫码打电话</span>
        <span class="tips">-扫码失败可拨打-</span>
        <span class="number">{{ showMobile }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'mobile',
  props: {
    mobile: String,
    orCode: String,
  },
  data() {
    return {
      isMobile: false,
    };
  },
  computed: {
    showMobile() {
      if (this.mobile) return this.mobile.replace(',', '转');
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile {
  display: inline-block;
  width: 112px;
  height: 36px;
  line-height: 36px;
  color: #f8513c;
  font-size: 14px;
  background: #fff7e5;
  text-align: center;
  margin-left: 1em;
  position: relative;
  cursor: pointer;
  .icon {
    color: #ffb501;
    font-size: 20px;
    display: inline-block;
    margin-top: -1px;
  }
  .pop {
    position: absolute;
    left: 117px;
    top: -100px;
    z-index: 9999;
  }
  .min {
    width: 210px;
    padding: 20px 0;
    position: relative;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999;
    filter: drop-shadow(0 0 1rem #666);
    img {
      width: 110px;
      height: 110px;
      margin-bottom: 4px;
    }
    .label {
      font-size: 14px;
      color: #333;
      line-height: 34px;
    }
    .tips {
      font-size: 12px;
      color: #bebebe;
      line-height: 20px;
    }
    .number {
      font-size: 16px;
      color: var(--color);
      line-height: 20px;
      margin-top: 6px;
      font-weight: bold;
    }
    &:after {
      content: '';
      border: 10px solid transparent;
      border-right-color: white;
      position: absolute;
      top: 105px;
      left: -20px;
      filter: drop-shadow(0 0 1rem #666);
    }
    &:before {
      content: '';
      top: 0;
      left: 0;
      right: 0;
      width: 2rem;
      height: 200px;
      position: absolute;
      z-index: 999;
      background: #fff;
      border-radius: 6px;
    }
  }
}
</style>
