<template>
  <div class="fd card" @click="detail">
    <div class="card-img">
      <img :src="obj.fileImg" alt="封面" />
      <img v-if="obj.panorama" src="https://static.ajl.cn/web/360.png" class="panorama" alt="全景图标" />
    </div>
    <div class="card-body">
      <div class="line">
        <span class="line-name">{{ obj.buildingName }}</span>
        <span class="line-price">
          <span>{{ obj.total }}</span>
          <sub>套</sub>
        </span>
      </div>
      <div class="line">
        <span class="icon icon-office"></span>
        <span>90天成交{{ obj.tradeNum }}套·{{ obj.rentNum }}套正在出租·{{ obj.saleNum }}套正在出售</span>
      </div>
      <div class="line">
        <span class="icon icon-map"></span>
        <span class="line-text">
          <span class="btn">{{ obj.regionName }}</span> - <span class="btn">{{ obj.cateCircleName }}</span>
        </span>
        <span style="margin-left: auto">{{ obj.averagePrice }}</span>
      </div>
      <div class="line">
        <span class="icon icon-chart-pie"></span>
        <span>{{ obj.releaseTime }} · 近30天内共有{{ obj.collectNum }}人关注</span>
        <span class="btn more">查看详情</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'house-list-card',
  props: { obj: Object },
  methods: {
    detail() {
      const query = { id: this.obj.id, from: this.$route.name ?? '' };
      const { href } = this.$router.resolve({ name: 'house.edifice-detail', query });
      window.open(href, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  flex: 1;
  height: 200px;
  margin-bottom: 2rem;
  display: grid;
  padding: 2rem;
  grid-template-columns: 230px 1fr;
  place-content: center;
  align-content: stretch;
  border-bottom: 1px solid rgba(black, 0.15);
  cursor: pointer;
  &:hover {
    background-color: white;
    border-bottom-color: transparent;
  }

  &-img {
    border-radius: 6px;
    overflow: hidden;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .panorama {
      width: 30px;
      height: 30px;
      position: absolute;
      left: 10px;
      bottom: 10px;
      animation-name: ani;
      animation-duration: 2.6s;
      animation-iteration-count: infinite;
    }
  }

  &-body {
    padding: 1rem 2rem;

    &:hover {
      .line > .more {
        opacity: 1;
      }
    }

    .line {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;
      font-size: 2rem;

      &-name {
        font-size: 3rem !important;
      }

      &-price {
        margin-left: auto;
        display: flex;
        align-items: flex-end;
        color: red;
        span {
          margin-right: 0.5rem;
          font-weight: bold;
          font-size: 4rem;
          line-height: 1;
        }
        sub {
          font-size: 2rem;
        }
      }

      &-area {
        padding: 0.5rem 1rem;
        border: 1px solid #ccc;
        margin: 0 -1px -1px 0;
        opacity: 0.6;
        &:hover {
          color: var(--color);
        }
        &:nth-child(1) {
          border-radius: 0.5rem 0 0 0.5rem;
        }

        &:nth-last-child(2) {
          font-weight: bold;
          border-radius: 0 0.5rem 0.5rem 0;
        }

        &:hover {
          border-color: var(--color);
        }
      }

      .icon {
        margin-right: 0.5rem;
        font-size: 3rem;
      }

      .more {
        @extend .dye;
        margin-left: auto;
        opacity: 0;
        padding: 1rem 2rem;
        border-radius: 6px;
      }
    }
  }
}
@keyframes ani {
  70% {
    transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(360deg) scale(1.2);
  }
}
</style>
