<template>
  <div class="house-card" @click="detail">
    <div class="img">
      <img :src="item.fileImg" alt="封面" />
      <img v-if="item.panorama" src="https://static.ajl.cn/web/360.png" alt="全景图标" class="panorama" />
    </div>
    <div class="area">
      <span class="area-label">{{ $cov(item.buildArea).num }}</span>
      <span class="area-unit">㎡</span>
    </div>
    <div class="price">
      <span>{{ $cov(item.salePrice).num }}</span>
      <span class="price-unit">{{ $cov(item.salePrice).unit }}</span>
    </div>
    <div class="price">
      <span>{{ $cov(item.rentPrice).num }}</span>
      <span class="price-unit">{{ $cov(item.rentPrice).unit }}</span>
    </div>
    <div class="orientation" v-if="root.name !== 'factory'">{{ item.renovationName }}</div>
    <div class="update">{{ item.releaseTime }}</div>
  </div>
</template>

<script>
export default {
  name: 'house-card',
  props: { item: Object },
  methods: {
    detail() {
      this.$router.push({ name: this.root.name + '.house-detail', query: { id: this.item.id, from: this.$route.query.from } });
    },
  },
};
</script>

<style lang="scss" scoped>
.house-card {
  display: grid;
  grid-template-columns: 100px 1fr 1fr 1fr 60px 60px;
  padding: 10px 0;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: rgba(0, 0, 0, 0.03);
  }
  .img {
    height: 60px;
    position: relative;
    text-align: left;
    img {
      width: 96px;
      height: 100%;
      border-radius: 6px;
      margin: 0 auto;
    }
    .panorama {
      width: 15px;
      height: 15px;
      position: absolute;
      left: 15px;
      bottom: 5px;
      animation-name: ani;
      animation-duration: 2.6s;
      animation-iteration-count: infinite;
    }
  }
  .area {
    display: flex;
    align-items: center;
    justify-content: center;
    &-label {
      font-size: 20px;
      margin-right: 4px;
    }
    &-unit {
      font-size: 12px;
    }
  }
  .price {
    font-size: 20px;
    color: #cc2929;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      display: block;
    }
    &-unit {
      font-size: 12px;
      margin-left: 4px;
    }
  }
  .orientation {
    font-size: 12px;
    color: #000;
  }
  .update {
    font-size: 12px;
    color: #b2b2b2;
  }
}
@keyframes ani {
  70% {
    transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(360deg) scale(1.2);
  }
}
</style>
