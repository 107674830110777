<template>
  <nav-top v-if="showNav" />
  <router-view v-if="showBody" />
  <nav-end v-if="showEnd" />
</template>
<script>
import 'animate.css';

export default {
  data() {
    return {
      showNav: false,
      showBody: false,
      showEnd: false,
    };
  },
  async mounted() {
    const { data } = await http('office/base-data', {}, 86400);
    app.dict = data;
    this.showNav = true;
    await this.$nextTick();
    this.showBody = true;
    await this.$nextTick();
    this.showEnd = true;
  },
};
</script>
<style lang="scss">
#app {
  min-height: 100vh;
  //overflow-x: hidden;
}
.filter {
  margin: 0 auto;
  width: 1050px;
  padding: 2rem;
  .line {
    display: flex;
    padding: 0.5rem 2rem;
    position: relative;

    span {
      padding: 1rem;
    }

    li {
      padding: 1rem;

      &:hover {
        color: var(--color);
      }
    }

    &:after {
      content: '';
      width: 1px;
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      background-color: #eee;
      left: 100px;
    }

    &:hover {
      background-color: rgba(skyblue, 0.1);
    }

    &-name {
      display: inline-block;
      font-weight: bold;
      font-size: 2rem;
      width: 100px;
    }

    .in {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      list-style: none;
      padding-left: 2rem;

      li {
        font-size: 2rem;
        padding: 1rem 2.6rem 1rem 0;
      }
    }

    .box {
      flex: 1;
    }
  }

  .isActive {
    color: var(--color);
  }
}
</style>
