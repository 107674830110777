<template>
  <div class="base pb-4 mb-4" v-if="showList.length > 0">
    <div class="base-title f-5 px-2 mb-4">
      <span>{{ root.projectName }}推荐</span>
    </div>
    <hr />
    <div class="list mY-4">
      <office-building-edifice class="list-item" v-for="a in showList" :key="a.id" :obj="a" />
    </div>
  </div>
  <a-not v-else>{{ root.projectName }}推荐</a-not>
</template>

<script>
export default {
  name: 'surrounding-buildings',
  props: { list: Array, id: Number, lat: Number, lng: Number },
  computed: {
    showList() {
      let arr = this.list.filter((_) => _.id - this.id).slice(0, 3);
      if (arr.length === 0) return [];
      arr.map((_) => (_.distance = this.getDistance(Number(_.lat), Number(_.lng))));
      return arr.sort(this.compare('distance'));
    },
  },
  methods: {
    getDistance(lat1, lng1) {
      const lat2 = this.lat;
      const lng2 = this.lng;
      const rad = (du) => (du * Math.PI) / 180.0;
      const EARTH_RADIUS = 6378137;
      const radLng1 = rad(lng1);
      const radLat1 = rad(lat1);
      const radLng2 = rad(lng2);
      const radLat2 = rad(lat2);
      const latDiff = radLat1 - radLat2;
      const lngDiff = radLng1 - radLng2;
      let distance = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(latDiff / 2), 2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(lngDiff / 2), 2)));
      distance = distance * EARTH_RADIUS;
      distance = Math.round(distance * 10000) / 10000;
      return parseInt(distance);
    },
    compare(property) {
      return function (a, b) {
        let v1 = a[property];
        let v2 = b[property];
        return v1 - v2;
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.base {
  display: block;
  width: 680px;
  text-align: left;
  border-bottom: 1px solid #eee;
  background-color: white;
  padding: 30px;
  &-title {
    display: flex;
    align-items: flex-end;
    position: relative;
    .tip {
      font-size: 12px;
      color: gray;
      padding-left: 1rem;
    }
    .btn {
      cursor: pointer;
      margin-left: auto;
    }
    &:before {
      content: '';
      position: absolute;
      top: 4px;
      bottom: 4px;
      left: 0;
      width: 2px;
      background-color: var(--color);
    }
  }
  .list {
    display: grid;
    height: 210px;
    overflow: auto;
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>
