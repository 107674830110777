import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
window.app = createApp(App);
Array.prototype.toggle = function (item) {
  if (this.includes(item)) {
    this.splice(this.indexOf(item), 1);
  } else {
    this.push(item);
  }
  return this;
};
app.mixin({
  computed: {
    isHome() {
      return this.$route.matched.length === 1;
    },
    isOne() {
      return this.$route.fullPath.match('first') || this.$route.name.match('building-detail');
    },
    isSale() {
      return this.$attrs.tradeType > 1 || this.$route.fullPath.match('sale');
    },
    root() {
      const root = this.$route.matched[0] ?? {};
      switch (root.name) {
        case 'house':
          root.buildingUse = '1,2,3';
          root.projectName = '楼盘';
          root.buildingName = '小区';
          root.title = '房源';
          break;
        case 'office':
          root.buildingUse = 4;
          root.projectName = '大厦';
          root.buildingName = '大厦';
          root.title = '写字楼';
          break;
        case 'shop':
          root.buildingUse = 5;
          root.projectName = '楼盘';
          root.buildingName = '楼盘';
          root.title = '商铺';
          break;
        case 'factory':
          root.buildingUse = '7,9';
          root.projectName = '楼盘';
          root.buildingName = '楼盘';
          root.title = '厂房土地';
          break;
        default:
          break;
      }
      return root;
    },
  },
});
const requireJs = require.context('@/utils/', false, /\w+\.js$/);
requireJs.keys().forEach((fileName) => {
  const config = requireJs(fileName);
  if (config.default) config.default(app);
});

app.use(router);
app.mount('#app');
