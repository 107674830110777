<template>
  <div class="filter">
    <div class="line">
      <span class="line-name">位置</span>
      <div class="box">
        <!--        <ul class="in">-->
        <!--          <li class="btn" :class="{ isActive: isRegion }" @click="isRegion = true">区域</li>-->
        <!--          <li class="btn" :class="{ isActive: !isRegion }" @click="isRegion = false">地铁线</li>-->
        <!--        </ul>-->
        <ul class="in" v-show="isRegion">
          <li class="btn" :class="{ isActive: regionIndex === '' }" @click="choose('region', '', '')">全市区域</li>
          <li class="btn" :class="{ isActive: regionIndex === i }" v-for="(a, i) in region" :key="a.code" @click="getCircle(a.code, i)">{{ a.name }}</li>
        </ul>
        <hr v-show="cateCircleList.length" />
        <ul class="in" v-show="cateCircleList.length">
          <template v-for="(a, i) in cateCircleList" :key="a.code">
            <li class="btn" :class="{ isActive: cateCircleIndex === i }" @click="chooseCircle(a.code, i)">{{ a.name }}</li>
          </template>
        </ul>
        <ul class="in" v-show="!isRegion">
          <li class="btn">地铁沿线</li>
          <li class="btn">1号线</li>
          <li class="btn">2号线</li>
          <li class="btn">3号线</li>
          <li class="btn">4号线</li>
          <li class="btn">5号线</li>
        </ul>
      </div>
    </div>
    <div class="line">
      <span class="line-name">面积</span>
      <div class="box">
        <ul class="in">
          <li class="btn" :class="{ isActive: isArea }" @click="isArea = true">空间</li>
          <li class="btn" :class="{ isActive: !isArea }" @click="isArea = false">人数</li>
        </ul>
        <hr />
        <ul class="in" v-show="isArea">
          <li class="btn" :class="{ isActive: areaIndex === '' }" @click="choose('area', '', '')">空间不限</li>
          <li class="btn" :class="{ isActive: areaIndex === i }" @click="choose('area', i, a)" v-for="(a, i) in area" :key="i">{{ a.name }}</li>
        </ul>
        <ul class="in" v-show="!isArea">
          <li class="btn" :class="{ isActive: peopleIndex === '' }" @click="choose('people', '', '')">人数不限</li>
          <li class="btn" :class="{ isActive: peopleIndex === i }" @click="choose('people', i, a)" v-for="(a, i) in people" :key="i">{{ a.name }}</li>
        </ul>
      </div>
    </div>
    <div class="line">
      <span class="line-name">{{ isSale ? '售价' : '租价' }}</span>
      <ul class="in">
        <li class="btn" :class="{ isActive: priceIndex === '' }" @click="choose('price', '', '')">{{ isSale ? '售价' : '租价' }}不限</li>
        <li class="btn" :class="{ isActive: priceIndex === i }" @click="choose('price', i, a)" v-for="(a, i) in isSale ? salePrice : rentPrice" :key="i">{{ a.name }}</li>
      </ul>
    </div>
    <div class="line">
      <span class="line-name">装修</span>
      <ul class="in">
        <li class="btn" :class="{ isActive: renovationIndex === '' }" @click="choose('renovation', '', '')">装修不限</li>
        <li class="btn" :class="{ isActive: renovationIndex === i }" @click="choose('renovation', i, a)" v-for="(a, i) in renovation" :key="i">{{ a.name }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'office-filter',
  data() {
    return {
      isArea: true,
      isRegion: true,
      isSale: false,
      area: [], //面积
      areaIndex: '',
      renovation: [], //装修
      renovationIndex: '',
      salePrice: [], //售价
      rentPrice: [], //租价
      priceIndex: '',
      people: [], //人数
      peopleIndex: '',
      region: [], //区域一级
      regionIndex: '',
      cateCircleBase: [], //所有商圈
      cateCircleList: [], //展示的商圈
      cateCircleIndex: '',
      metro: [], //地铁一级
      obj: {
        page: '',
        pageSize: '',
        tradeType: 1,
        buildingUse: 4,
        region: '', //区域
        cateCircle: '', //商圈
        houseUse: '', //联合办公/开放工位/写字楼houseUse4
        buildArea: [], //写字楼面积
        renovation: '', //装修
        price: [], //出售价格
        rentPrice: [], //出租价格
      },
    };
  },
  methods: {
    search() {
      clearTimeout(this.oisId);
      this.oisId = setTimeout(() => {
        this.$emit('search', this.obj);
      }, 50);
    },
    choose(m, i, a) {
      if (m === 'region') {
        this.cateCircleList = [];
        this.obj.region = '';
        this.obj.cateCircle = '';
        this.regionIndex = i;
      }
      if (m === 'area') {
        this.areaIndex = i;
        if (a) {
          this.obj.buildArea = [{ min: a.min, max: a.max }];
        } else {
          this.obj.buildArea = [];
        }
      }
      if (m === 'people') {
        this.peopleIndex = i;
        if (a) {
          this.obj.people = [{ min: a.min, max: a.max }];
        } else {
          this.obj.people = [];
        }
      }
      if (m === 'price') {
        this.priceIndex = i;
        let v = this.isSale ? 'price' : 'rentPrice';
        if (a) {
          this.obj[v] = [{ min: a.min, max: a.max }];
        } else {
          this.obj[v] = [];
        }
      }
      if (m === 'renovation') {
        this.renovationIndex = i;
        if (a) {
          this.obj.renovation = a.code;
        } else {
          this.obj.renovation = '';
        }
      }
      this.search();
    },
    async getBaseData() {
      app.dict.BaseData.map((_) => {
        if (_.dicCode === 'renovation') {
          this.renovation = _.subData;
        }
        if (_.dicCode === 'areaList') {
          this.area = _.subData;
        }
        if (_.dicCode === 'salePrice') {
          this.salePrice = _.subData;
        }
        if (_.dicCode === 'rentPrice') {
          this.rentPrice = _.subData;
        }
        if (_.dicCode === 'people') {
          this.people = _.subData;
        }
      });
      this.region = app.dict.AreaData ?? [];
      this.cateCircleBase = app.dict.TradingAreaData ?? [];
    },
    getCircle(code, i) {
      this.obj.cateCircle = '';
      this.cateCircleIndex = null;
      const arr = [];
      this.cateCircleBase.map((a) => a.parentCode === code && arr.push(a));
      this.cateCircleList = arr;
      this.regionIndex = i;
      this.obj.region = code;
      this.search();
    },
    chooseCircle(code, i) {
      this.cateCircleIndex = i;
      this.obj.cateCircle = code;
      this.search();
    },
  },
  async created() {
    await this.getBaseData();
    if (this.$route.name === 'office.sale' || this.$route.name === 'office.first-sale') this.isSale = true;
    if (this.$route.query.regionIndex) {
      this.regionIndex = this.$route.query.regionIndex * 1;
      this.obj.region = this.region[this.regionIndex].code;
    }
    if (this.$route.query.areaIndex) {
      this.areaIndex = this.$route.query.areaIndex * 1;
      this.obj.buildArea = [{ min: this.area[this.areaIndex].min, max: this.area[this.areaIndex].max }];
    }
    if (this.$route.query.region) {
      this.obj.region = this.$route.query.region;
      this.region.map((_) => {
        if (_.code === this.$route.query.region) {
          let index = this.region.indexOf(_);
          this.regionIndex = index;
          if (this.$route.query.cateCircle) {
            this.getCircle(_.code, index);
            this.obj.cateCircle = this.$route.query.cateCircle;
            this.cateCircleList.map((a) => {
              if (a.code === this.$route.query.cateCircle) {
                let i = this.cateCircleList.indexOf(a);
                this.cateCircleIndex = i;
              }
            });
          }
        }
      });
    }
    if (this.$route.query.content) this.obj.content = this.$route.query.content;
    if (this.$route.name.includes('sale')) this.obj.tradeType = 2;
    this.search();
  },
};
</script>
