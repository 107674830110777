<template>
  <a-container>
    <div class="body">
      <!--      出租-->
      <div class="base pb-4 mb-4" v-if="$route.query.from.includes('rent') && !isOne">
        <div class="base-title f-5 px-2 mb-4">房源配套设施</div>
        <div class="base-list">
          <div class="base-list-item" v-for="a in obj.matchingName" :key="a.name">
            <div class="pic"><img :src="a.url" :alt="a.name" /></div>
            <span>{{ a.name }}</span>
          </div>
        </div>
      </div>
      <!--      出售-->
      <div class="base pb-4 mb-4" v-if="!isOne">
        <div class="base-title f-5 px-2 mb-4">{{ root.title }}信息</div>
        <hr class="my-2" />
        <div class="base-body">
          <!--          新房-->
          <template v-if="isOne">
            <div class="base-item f-4 m-2">
              <span class="base-item-title">{{ root.title }}编号</span>
              <p class="base-item-sub">{{ obj.firstHouseNo || '暂无' }}</p>
            </div>
            <div class="base-item f-4 m-2">
              <span class="base-item-title">{{ root.title }}用途</span>
              <p class="base-item-sub">{{ obj.buildingUse || '暂无' }}</p>
            </div>
            <div class="base-item f-4 m-2">
              <span class="base-item-title">建筑类型</span>
              <p class="base-item-sub">{{ obj.buildingData?.blockTypeName || '暂无' }}</p>
            </div>
          </template>
          <!--          二手房-->
          <template v-if="!isOne">
            <!--            住宅-->
            <template v-if="root.name === 'house'">
              <template v-if="isSale">
                <div class="title m-2">基本属性</div>
                <div class="base-item f-4 m-2">
                  <span class="base-item-title">房屋户型</span>
                  <p class="base-item-sub">{{ obj.houseLayout || '暂无' }}</p>
                </div>
                <div class="base-item f-4 m-2">
                  <span class="base-item-title">所在楼层</span>
                  <p class="base-item-sub">{{ obj.floor || '--' }}|{{ obj.totalFloor || '--' }}</p>
                </div>
                <span></span>
                <div class="base-item f-4 m-2">
                  <span class="base-item-title">建筑面积</span>
                  <p class="base-item-sub">{{ obj.buildArea || '暂无' }}</p>
                </div>
                <div class="base-item f-4 m-2">
                  <span class="base-item-title">户型结构</span>
                  <p class="base-item-sub">{{ obj.architecturalTypeName || '暂无' }}</p>
                </div>
                <span></span>
                <div class="base-item f-4 m-2">
                  <span class="base-item-title">建筑类型</span>
                  <p class="base-item-sub">{{ obj.buildingData?.blockTypeName || '暂无' }}</p>
                </div>
                <div class="base-item f-4 m-2">
                  <span class="base-item-title">房屋朝向</span>
                  <p class="base-item-sub">{{ obj.orientationName || '暂无' }}</p>
                </div>
                <span></span>
                <div class="base-item f-4 m-2">
                  <span class="base-item-title">装修情况</span>
                  <p class="base-item-sub">{{ obj.renovationName || '暂无' }}</p>
                </div>
                <hr v-col:1-4 class="my-2" />
                <div class="title m-2">交易属性</div>
                <div class="base-item f-4 m-2">
                  <span class="base-item-title">建筑年代</span>
                  <p class="base-item-sub">{{ obj.buildYear || '暂无' }}</p>
                </div>
                <div class="base-item f-4 m-2">
                  <span class="base-item-title">产权性质</span>
                  <p class="base-item-sub">{{ obj.propertyNatureName || '暂无' }}</p>
                </div>
                <span></span>
                <div class="base-item f-4 m-2">
                  <span class="base-item-title">房屋用途</span>
                  <p class="base-item-sub">{{ obj.houseUseName || '暂无' }}</p>
                </div>
              </template>
              <template v-else>
                <div class="base-item f-4 m-2">
                  <span class="base-item-title">面积</span>
                  <p class="base-item-sub">{{ obj.buildArea || '暂无' }}</p>
                </div>
                <div class="base-item f-4 m-2">
                  <span class="base-item-title">朝向</span>
                  <p class="base-item-sub">{{ obj.orientationName || '暂无' }}</p>
                </div>
                <div class="base-item f-4 m-2">
                  <span class="base-item-title">维护</span>
                  <p class="base-item-sub">{{ obj.releaseTime || '暂无' }}</p>
                </div>
                <div class="base-item f-4 m-2">
                  <span class="base-item-title">入住</span>
                  <p class="base-item-sub">随时入住</p>
                </div>
                <div class="base-item f-4 m-2">
                  <span class="base-item-title">楼层</span>
                  <p class="base-item-sub">{{ obj.floor || '--' }}|{{ obj.totalFloor || '--' }}</p>
                </div>
                <div class="base-item f-4 m-2">
                  <span class="base-item-title">电梯</span>
                  <p class="base-item-sub">{{ obj.isElevator || '暂无' }}</p>
                </div>
                <div class="base-item f-4 m-2">
                  <span class="base-item-title">用水</span>
                  <p class="base-item-sub">{{ obj.buildingData?.waterSupplyName || '暂无' }}</p>
                </div>
                <div class="base-item f-4 m-2">
                  <span class="base-item-title">用电</span>
                  <p class="base-item-sub">{{ obj.buildingData?.powerSupplyName || '暂无' }}</p>
                </div>
                <div class="base-item f-4 m-2">
                  <span class="base-item-title">燃气</span>
                  <p class="base-item-sub">{{ obj.buildingData?.fuelGasName || '暂无' }}</p>
                </div>
                <div class="base-item f-4 m-2">
                  <span class="base-item-title">采暖</span>
                  <p class="base-item-sub">{{ obj.buildingData?.warmSupplyName || '暂无' }}</p>
                </div>
                <div class="base-item f-4 m-2">
                  <span class="base-item-title">租期</span>
                  <p class="base-item-sub">咨询经纪人</p>
                </div>
                <div class="base-item f-4 m-2">
                  <span class="base-item-title">看房</span>
                  <p class="base-item-sub">预约看房</p>
                </div>
              </template>
            </template>
            <!--            非住宅-->
            <template v-if="root.name !== 'house'">
              <div class="base-item f-4 m-2" v-if="root.name === 'shop'">
                <span class="base-item-title">{{ root.title }}用途</span>
                <p class="base-item-sub">{{ buildingUseName || '暂无' }}</p>
              </div>
              <div class="base-item f-4 m-2">
                <span class="base-item-title">更新时间</span>
                <p class="base-item-sub">{{ releaseTime }}</p>
              </div>
              <div class="base-item f-4 m-2">
                <span class="base-item-title">看房时间</span>
                <p class="base-item-sub">预约</p>
              </div>
              <div class="base-item f-4 m-2" v-if="root.name === 'office'">
                <span class="base-item-title">房源楼层</span>
                <p class="base-item-sub">{{ floor }}</p>
              </div>
              <div class="base-item f-4 m-2" v-if="buildingUseName !== '土地'">
                <span class="base-item-title">房源层高</span>
                <p class="base-item-sub">{{ floorHeight ? floorHeight + '米' : '暂无' }}</p>
              </div>
              <div class="base-item f-4 m-2">
                <span class="base-item-title">产权情况</span>
                <p class="base-item-sub">{{ ownershipName || '暂无' }}</p>
              </div>
              <div class="base-item f-4 m-2" v-if="root.name === 'office'">
                <span class="base-item-title">房源级别</span>
                <p class="base-item-sub">{{ officeLevelName || '暂无' }}</p>
              </div>
              <div class="base-item f-4 m-2" v-if="['shop', 'factory'].includes(root.name)">
                <span class="base-item-title">行业</span>
                <p class="base-item-sub">{{ shopIndustryName || '暂无' }}</p>
              </div>
              <div class="base-item f-4 m-2" v-if="['shop'].includes(root.name)">
                <span class="base-item-title">地段</span>
                <p class="base-item-sub">{{ shopLocationName || '暂无' }}</p>
              </div>
            </template>
          </template>
        </div>
        <p v-if="isSale" class="my-4 f-2" v-color:#ccc>
          注：1.页面展示的"户型图、规划用途、产权性质、共有情况、建筑年代、产权年限、建筑结构、户型结构、电梯、供暖、上次交易、购房年限、抵押情况”等信息仅供参考，购房时请以房本信息为准。2.与距离相关的数据来源于百度地图，仅供参考。
        </p>
      </div>

      <div class="base pb-4 mb-4">
        <div class="base-title f-5 px-2 mb-4">
          <span>竞争力分析</span>
          <span class="tip">(3km范围内的数据对比)</span>
        </div>
        <hr class="my-2" />
        <div class="childTitle">周边配套</div>
        <div class="line2"></div>
        <div class="info-item">
          <span class="info-item-tag">快餐/餐厅</span>
          <span class="info-item-content">500米范围内{{ dining }}家</span>
        </div>
        <div class="childTitle">交通出行</div>
        <div class="line2"></div>
        <div class="info-item">
          <span class="info-item-tag metro-tag">最近地铁/公交</span>
          <span class="info-item-content">500米范围内{{ tran }}个</span>
        </div>
        <template v-if="!isOne">
          <div class="childTitle">房源稀缺性</div>
          <div class="line2"></div>
          <div class="info-item">
            <span class="info-item-tag scarce-tag">稀缺户型</span>
            <span class="info-item-content">{{ scarce }}</span>
          </div>
          <div class="childTitle">价格优势</div>
          <div class="line2"></div>
          <div class="info-item">
            <span class="info-item-tag price-tag">均价户型</span>
            <span class="info-item-content">{{ priceAdvance }}</span>
          </div>
          <div class="childTitle">房源热度</div>
          <div class="line2"></div>
          <div class="info-item">
            <span class="info-item-tag hot-tag">热门户型</span>
            <span class="info-item-content">{{ hot }}</span>
          </div>
        </template>
      </div>
      <div class="base pb-4 mb-4">
        <div class="base-title f-5 px-2 mb-4">房源图片</div>
        <div class="base-body img">
          <img v-for="a in obj.fileImg" :key="a.url" :src="a.url" alt="" />
        </div>
        <hr class="my-2" />
      </div>
      <div class="base pb-4 mb-4">
        <div class="base-title f-5 px-2 mb-4">周边配套</div>
        <a-map v-size:100%*260> </a-map>
        <hr class="my-2" />
      </div>
      <div class="base pb-4 mb-4">
        <div class="base-title f-5 px-2 mb-4">
          <span>{{ buildingName }}</span>
          <span class="btn" @click="checkMore">查看{{ root.buildingName }}详情></span>
        </div>
        <hr class="my-2" />
        <div class="building-box">
          <div class="building-box-left">
            <!--            出租出售价格显示不同-->
            <template v-if="$route.query.from.includes('rent')">
              <div class="building-box-left-item">
                <span class="label">租金范围</span>
                <span class="con" style="color: #cc2929">{{ buildingData?.rentPrice }}</span>
              </div>
            </template>
            <template v-else>
              <div class="building-box-left-item">
                <span class="label">{{ root.buildingName }}均价</span>
                <span class="con" style="color: #cc2929">{{ buildingData?.averagePrice || '暂无' }}</span>
              </div>
            </template>
            <div class="building-box-left-item">
              <span class="label">建筑时间</span>
              <span class="con">{{ buildingData?.year || '暂无' }}</span>
            </div>
            <div class="building-box-left-item">
              <span class="label">车位数量</span>
              <span class="con">{{ buildingData?.parking || '暂无' }}</span>
            </div>
            <div class="building-box-left-item">
              <span class="label">物业费</span>
              <span class="con">{{ buildingData?.propertyPrice || '暂无' }}</span>
            </div>
            <div class="building-box-left-item">
              <span class="label">{{ countName }}</span>
              <span class="con">{{ $route.query.from.includes('rent') ? buildingData?.saleHouseCount : buildingData?.rentHouseCount }}套</span>
            </div>
          </div>
          <img :src="buildingData?.fileImg" class="img" alt="" />
        </div>
      </div>
      <template v-if="!isOne">
        <!--        户型-->
        <office-house-type v-if="divisionData?.length && $route.query.from.includes('sale') && root.name !== 'house'" :divisionData="divisionData" />
      </template>
      <office-building-surrounding :list="list" :id="id" :lat="lat" :lng="lng" />
    </div>
    <div class="side">
      <div class="side-box">
        <template v-if="maintainUser?.length > 0">
          <a-agent class="side-box-agent" v-for="a in maintainUser" :key="a.userId" v-bind="a" :relationId="relationId" :type="type" />
        </template>
        <a-agent v-else class="side-box-agent" v-bind="maintainUser" ref="agent" :type="type" :relationId="relationId" />
        <a-mobile />
      </div>
    </div>
  </a-container>
</template>
<script>
import AMapLoader from '@amap/amap-jsapi-loader';
export default {
  props: {
    houseNo: String,
    firstHouseNo: String,
    releaseTime: String,
    floor: String,
    buildingUse: String,
    floorHeight: String,
    ownershipName: String,
    officeLevelName: String,
    buildingName: String,
    buildingUseName: String,
    buildingData: Object,
    tradeType: Number,
    scarce: String,
    houseUseName: String,
    priceAdvance: String,
    hot: String,
    lng: String,
    lat: String,
    divisionData: Array,
    cateCircle: String,
    id: Number,
    shopIndustryName: Number,
    projectId: Number,
    maintainUser: [Array, Object],
    shopLocationName: String,
  },
  data() {
    return {
      dining: 0,
      tran: 0,
      list: [],
    };
  },
  computed: {
    obj() {
      return { ...this.$attrs, ...this.$props };
    },
    countName() {
      if (this.$route.query.from.includes('rent')) return '在租户型';
      if (this.$route.query.from.includes('sale')) return '在售户型';
      return '租售户型';
    },
    relationId() {
      if (this.isOne) return this.projectId; //一手房源传对应的项目id
      return this.id; //默认房源id
    },
    type() {
      if (this.isOne) return 2;
      return 1; //默认房源
    },
  },
  watch: {
    cateCircle: {
      handler(v) {
        if (!v) return false;
        this.getList();
      },
      immediate: true,
    },
  },
  methods: {
    async checkMore() {
      const { href } = await this.$router.resolve({
        name: this.$route.matched[0].name + '.edifice-detail',
        query: { id: this.buildingData?.buildingId, from: this.root.name + '.building' },
      });
      window.open(href, '_blank');
    },
    async initMap() {
      const center = [this.lng, this.lat];
      const ops = { plugins: ['AMap.PlaceSearch'], key: '4153df3e44ed05588f05f048b662789b' };
      const aMap = await AMapLoader.load(ops);
      const Place = aMap.PlaceSearch;
      new Place({ type: '餐厅' }).searchNearBy('', center, 500, (status, result) => {
        this.dining = result.poiList?.count;
      });
      new Place({ type: '地铁,公交' }).searchNearBy('', center, 500, (status, result) => {
        this.tran = result.poiList?.count;
      });
    },
    async getList() {
      let url = isQian ? 'house-mini/list' : 'office/list';
      if (this.isOne) url = isQian ? 'project-mini/list' : 'office/project-list';
      const { list } = await http(url, { isHot: 1, cateCircle: this.cateCircle, tradeType: this.$route.query.from.includes('rent') ? 1 : 2, buildingUse: this.root.buildingUse });
      this.list = list;
    },
  },
  mounted() {
    this.$lazy(this.$el.querySelectorAll('img'));
    window._AMapSecurityConfig = window._AMapSecurityConfig ?? {};
    window._AMapSecurityConfig.securityJsCode = '617ddd3b53db34838222a636de836c8a';
    this.initMap();
  },
};
</script>
<style lang="scss" scoped>
.container {
  background-color: $bg-gray;
}
.base {
  width: 680px;
  text-align: left;
  border-bottom: 1px solid #eee;
  background-color: white;
  padding: 30px;
  &-title {
    display: flex;
    align-items: flex-end;
    position: relative;
    .tip {
      font-size: 12px;
      color: gray;
      padding-left: 1rem;
    }
    .btn {
      font-size: 12px;
      color: var(--color);
      cursor: pointer;
      margin-left: auto;
    }
    &:before {
      content: '';
      position: absolute;
      top: 4px;
      bottom: 4px;
      left: 0;
      width: 2px;
      background-color: var(--color);
    }
  }
  &-list {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 1rem;
    &-item {
      display: inline-grid;
      place-content: center;
      margin-bottom: 2rem;
      .pic {
        width: 30px;
        height: 30px;
        margin: 1rem auto;
        overflow: hidden;
        img {
          width: inherit;
          height: inherit;
          overflow: hidden;
          filter: drop-shadow(30px 0 var(--color));
          transform: translateX(-30px);
        }
      }

      span {
        text-align: center;
      }
    }
  }
  &-body {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    &.img {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem;
      img {
        width: 100%;
        height: 240px;
        object-fit: cover;
      }
    }
  }
  &-item {
    width: 100%;
    display: flex;

    &-title {
      flex: 0 0 6em;
      text-align: left;
      font-size: 12px;
      color: #b2b2b2;
    }
    &-sub {
      flex: 1;
      text-align: left;
      font-size: 12px;
      color: #4a4a4a;
    }
  }

  .childTitle {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .info-item {
    display: flex;
    margin-bottom: 10px;

    &-tag {
      width: 100px;
      height: 26px;
      line-height: 26px;
      border-radius: 2px;
      font-size: 12px;
      text-align: center;
      margin-right: 10px;
      color: #f0b030;
      background: #fcf5e8;
      padding: 2px 1rem;
    }

    &-content {
      font-size: 12px;
      color: #4a4a4a;
      margin-left: 20px;
      line-height: 1.67;
      flex: 1;
      margin-top: 4px;
    }

    .metro-tag {
      color: #17a1e6;
      background-color: #e7f5fc;
    }

    .scarce-tag {
      color: #8d88c2;
      background-color: #f2f2f6;
    }

    .price-tag {
      color: #e67b60;
      background-color: #fcf2ef;
    }

    .hot-tag {
      color: #2fc296;
      background-color: #e6f6f1;
    }
  }

  .building-box {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 200px;
      height: 125px;
      border-radius: 6px;
      margin-top: 16px;
    }
  }

  .building-box-left-item {
    margin: 20px 0 10px 0;

    .label {
      display: inline-block;
      width: 78px;
      height: 17px;
      font-size: 12px;
      color: #b2b2b2;
    }

    .con {
      display: inline-block;
      font-size: 12px;
      height: 17px;
    }
  }
}

.side {
  width: 360px;
  height: 720px;
  position: sticky;
  top: 170px;
  z-index: 99;

  &-box {
    position: relative;
    top: -130px;
  }
}
</style>
