<template>
  <div class="bg-light ps-6">
    <ul class="nav" ref="nav">
      <template v-for="a in menu" :key="a">
        <li class="btn color" :class="{ ac: ac === a }" @click="change(a)">{{ a }}</li>
      </template>
    </ul>
  </div>
  <div class="bg-gray">
    <div class="detail-body pb-8">
      <div class="body">
        <div class="flag" data-flag="楼盘简介"></div>
        <!--        小区简介-->
        <building-info v-if="$route.name.match('edifice-detail')" v-bind="{ ...$attrs, ...$props }" />
        <office-building-introduce v-else v-bind="$attrs" />
        <hr />
        <!--        新房才有-->
        <template v-if="isOne">
          <div class="flag" data-flag="户型介绍"></div>
          <div class="p-4 bg-light">
            <a-title>户型介绍</a-title>
            <layout-list :list="$attrs.layout" :price="$attrs.averagePrice" />
          </div>
          <hr />
        </template>
        <!--        小区在售在租户型-->
        <template v-if="$route.name.match('edifice-detail')">
          <div class="flag" data-flag="租售户型"></div>
          <office-edifice-house-type :divisionData="divisionData.house" v-if="divisionData.house.length" />
          <a-not v-else>租售户型</a-not>
        </template>
        <template v-else>
          <!--        其他页面分出租出售-->
          <div v-if="tradeType - 1 === 0" class="flag" data-flag="在租户型"></div>
          <div v-else class="flag" data-flag="在售户型"></div>
          <office-building-type :divisionData="divisionData" :tradeType="tradeType" />
          <hr />
        </template>
        <!--        只有小区有项目-->
        <template v-if="$route.name.match('edifice-detail')">
          <div class="flag" data-flag="项目"></div>
          <div class="p-4 bg-light">
            <office-edifice-project-type v-if="divisionData.project?.length > 0" :divisionData="divisionData.project" />
            <a-not v-else>项目</a-not>
          </div>
          <hr />
        </template>
        <!--        小区没有动态-->
        <template v-if="!$route.name.match('edifice-detail')">
          <div class="flag" data-flag="楼盘动态"></div>
          <div class="p-4 bg-light">
            <a-title>楼盘动态</a-title>
            <div class="list" v-if="1">
              <div class="p4-8 a-l" v-for="a in dynamic" :key="a">
                <div class="m-2">{{ a.content }}</div>
                <div class="m-2" v-color:gray>{{ a.createTime }}</div>
                <hr />
              </div>
            </div>
            <a-not v-else>楼盘动态</a-not>
          </div>
          <hr />
        </template>
        <div class="flag" data-flag="服务带看"></div>
        <div class="p-4 bg-light">
          <a-title>服务带看</a-title>
          <template v-if="lookList?.length">
            <a-agent class="p-0 m-0" v-for="a in lookList" :isLook="true" :key="a.id" v-bind="a" :noOrder="true" />
          </template>
          <a-not v-else>服务带看</a-not>
        </div>
        <hr />
        <div class="flag" data-flag="周边配套"></div>
        <div class="p-4 bg-light">
          <a-title>周边配套</a-title>
          <a-map class="mt-4" v-size:100%*300 :lat="lat" :lng="lng" />
        </div>
        <hr />
        <div class="flag" data-flag="楼盘推荐"></div>
        <office-building-surrounding :list="list" :id="id" :lat="lat" :lng="lng" />
        <hr />
      </div>
      <div class="side">
        <a-agent v-for="a in maintainUser" :key="a.userId" v-bind="a" type="2" :relationId="id" />
        <a-mobile />
      </div>
    </div>
  </div>
</template>
<script>
import LayoutList from './sub/layout-list';
import BuildingInfo from './sub/building-info';
export default {
  components: { BuildingInfo, LayoutList },
  props: {
    id: Number,
    lng: String,
    lat: String,
    buildingTypeName: String,
    year: String,
    propertyPrice: String,
    propertyName: String,
    totalTungNum: String,
    totalHouseholdNum: String,
    parkPrice: String,
    parking: String,
    waterSupplyName: String,
    powerSupplyName: String,
    lookList: Array,
    tradeType: Number,
    cateCircle: String,
    divisionData: Array,
    maintainUser: Object,
  },
  data() {
    return {
      ac: '',
      list: [],
      dynamic: [],
    };
  },
  computed: {
    menu() {
      if (this.$route.name.match('edifice-detail')) return ['楼盘简介', '租售户型', '服务带看', '周边配套', '楼盘推荐'];
      if (this.tradeType - 1 === 0) return ['楼盘简介', '户型介绍', '在租户型', '楼盘动态', '服务带看', '周边配套', '楼盘推荐'];
      if (this.tradeType - 2 === 0) return ['楼盘简介', '户型介绍', '在售户型', '楼盘动态', '服务带看', '周边配套', '楼盘推荐'];
      return [];
    },
  },
  watch: {
    cateCircle: {
      handler(v) {
        if (!v) return false;
        this.getList();
        this.getDynamic();
      },
      immediate: true,
    },
  },
  methods: {
    async getDynamic() {
      const { list } = await http('project-dynamic/list', { projectId: this.id });
      this.dynamic = list;
    },
    async getList() {
      let cateCircle = this.cateCircle;
      let url = isQian ? 'house-mini/list' : 'office/project-list';
      if (this.$route.name.includes('edifice-detail')) {
        url = 'office/list';
      }
      if (this.$route.fullPath.includes('house.building') && !this.$route.fullPath.includes('building-detail')) {
        url = 'house-building/list';
      }
      const { list } = await http(url, { isHot: 1, cateCircle, buildingUse: this.root.buildingUse });
      this.list = list;
    },
    async change(a) {
      this.ac = a;
      const flag = document.querySelector(`.flag[data-flag="${a}"]`).getBoundingClientRect();
      await this.$nextTick();
      const top = flag.top - document.documentElement.getBoundingClientRect().top - 140;
      document.body.scrollTop = top.toFixed(0) * 1;
    },
    listen() {
      for (const a of this.menu) {
        const flag = document.querySelector(`.flag[data-flag="${a}"]`)?.getBoundingClientRect();
        if (flag && flag.top * -1 + flag.height + 160 > 0) this.ac = a;
      }
    },
  },
  mounted() {
    setTimeout(() => document.addEventListener('scroll', this.listen, false), 200);
  },
  unmounted() {
    document.removeEventListener('scroll', this.listen, false);
  },
};
</script>
<style lang="scss" scoped>
.bg-light {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 99;
}

.nav {
  margin: auto;
  width: 1050px;
  display: flex;
  background-color: white;

  li {
    list-style: none;
    font-size: 2rem;
    padding: 2rem 0;
    margin-right: 6rem;

    &.ac {
      color: var(--color);
    }
  }
}

.detail-body {
  margin: auto;
  width: 1050px;
  display: flex;
  justify-content: space-between;

  .body {
    width: 680px;

    .flag {
      padding: 1rem;
    }
  }

  .side {
    width: 360px;
    position: sticky;
    top: 91px;
    height: 714px;
    z-index: 99;
  }
}

.map-box {
  background-color: #ffffff;
  padding-bottom: 6rem;
}

.warp {
  background-color: #ffffff;
}
</style>
