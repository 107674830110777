<template>
  <div class="filter">
    <div class="line">
      <span class="line-name">位置</span>
      <div class="box">
        <ul class="in" v-show="isRegion">
          <li class="btn" :class="{ isActive: regionIndex === '' }" @click="choose('region', '', '')">全市区域</li>
          <li class="btn" :class="{ isActive: regionIndex === i }" v-for="(a, i) in region" :key="a.code" @click="getCircle(a.code, i)">{{ a.name }}</li>
        </ul>
        <hr v-show="cateCircleList.length" />
        <ul class="in" v-show="cateCircleList.length">
          <template v-for="(a, i) in cateCircleList" :key="a.code">
            <li class="btn" :class="{ isActive: cateCircleIndex === i }" @click="chooseCircle(a.code, i)">{{ a.name }}</li>
          </template>
        </ul>
      </div>
    </div>
    <div class="line">
      <span class="line-name">均价</span>
      <ul class="in">
        <li class="btn" :class="{ isActive: priceIndex === '' }" @click="choose('price', '', '')">均价不限</li>
        <li class="btn" :class="{ isActive: priceIndex === i }" @click="choose('price', i, a)" v-for="(a, i) in averagePrice" :key="i">{{ a.name }}</li>
      </ul>
    </div>
    <div class="line">
      <span class="line-name">楼龄</span>
      <ul class="in">
        <li class="btn" :class="{ isActive: areaIndex === '' }" @click="choose('age', '', '')">楼龄不限</li>
        <li class="btn" :class="{ isActive: areaIndex === i }" @click="choose('age', i, a)" v-for="(a, i) in buildAge" :key="i">{{ a.name }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'house-filter',
  data() {
    return {
      isRegion: true,
      area: [], //面积
      areaIndex: '',
      averagePrice: [],
      priceIndex: '',
      people: [], //人数
      peopleIndex: '',
      region: [], //区域一级
      regionIndex: '',
      cateCircleBase: [], //所有商圈
      cateCircleList: [], //展示的商圈
      cateCircleIndex: '',
      buildAge: [], //地铁一级
      obj: {
        page: '',
        pageSize: '',
        tradeType: 1,
        region: '', //区域
        cateCircle: '', //商圈
        buildAge: 0,
        averagePrice: [],
        content: '',
      },
    };
  },
  methods: {
    search() {
      clearTimeout(this.oisId);
      this.oisId = setTimeout(() => {
        this.$emit('search', this.obj);
      }, 50);
    },
    choose(m, i, a) {
      if (m === 'region') {
        this.cateCircleList = [];
        this.obj.region = '';
        this.obj.cateCircle = '';
        this.regionIndex = i;
      }
      if (m === 'age') {
        this.areaIndex = i;
        this.obj.buildAge = a.code ?? 0;
      }
      if (m === 'price') {
        this.priceIndex = i;
        this.obj.averagePrice = a ? [{ min: a.min, max: a.max }] : [];
      }
      this.search();
    },
    async getBaseData() {
      app.dict.BaseData?.map(({ dicCode, subData }) => {
        if (dicCode === 'areaList') this.area = subData;
        if (dicCode === 'averagePrice') this.averagePrice = subData;
        if (dicCode === 'people') this.people = subData;
        if (dicCode === 'buildAge') this.buildAge = subData;
      });
      this.region = app.dict.AreaData ?? [];
      this.cateCircleBase = app.dict.TradingAreaData ?? [];
    },
    getCircle(code, i) {
      this.obj.cateCircle = '';
      this.cateCircleIndex = null;
      const arr = [];
      this.cateCircleBase.map((a) => a.parentCode === code && arr.push(a));
      this.cateCircleList = arr;
      this.regionIndex = i;
      this.obj.region = code;
      this.search();
    },
    chooseCircle(code, i) {
      this.cateCircleIndex = i;
      this.obj.cateCircle = code;
      this.search();
    },
    async init() {
      await this.getBaseData();
      this.search();
    },
  },
  created() {
    if (this.$route.query.content) this.obj.content = this.$route.query.content;
    this.init();
  },
};
</script>
