class Store {
  has(key) {
    return !!this.get(key);
  }
  set(key, value, expired = 86400) {
    expired = expired * 1000 + new Date().getTime();
    sessionStorage.setItem(key, JSON.stringify({ value, expired }));
    return true;
  }
  get(key) {
    const { value, expired } = JSON.parse(sessionStorage.getItem(key) ?? JSON.stringify({}));
    if (expired > new Date().getTime()) return value;
    this.delete(key);
    return null;
  }
  delete(key) {
    return sessionStorage.removeItem(key);
  }
}

window.store = new Store();
