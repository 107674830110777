<template>
  <div class="base p-4 bg-light">
    <div v-col:1-3>
      <a-title>小区简介</a-title>
    </div>
    <div class="base-item f-4 m-2">
      <span class="base-item-title">建筑类型</span>
      <p class="base-item-sub">{{ $attrs.blockTypeName || '暂无' }}</p>
    </div>
    <div class="base-item f-4 m-2">
      <span class="base-item-title">建筑年代</span>
      <p class="base-item-sub">{{ $attrs.year || '--' }}年</p>
    </div>
    <div class="base-item f-4 m-2">
      <span class="base-item-title">物业费用</span>
      <p class="base-item-sub">{{ $attrs.propertyPrice || '暂无' }}</p>
    </div>
    <div class="base-item f-4 m-2">
      <span class="base-item-title">物业公司</span>
      <p class="base-item-sub">{{ $attrs.propertyName || '暂无' }}</p>
    </div>
    <div class="base-item f-4 m-2">
      <span class="base-item-title">开发商</span>
      <p class="base-item-sub">{{ $attrs.blackHole || '暂无' }}</p>
    </div>
    <div class="base-item f-4 m-2">
      <span class="base-item-title">楼栋总数</span>
      <p class="base-item-sub">{{ $attrs.totalTungNum || '--' }}栋</p>
    </div>
    <div class="base-item f-4 m-2">
      <span class="base-item-title">房屋总数</span>
      <p class="base-item-sub">{{ $attrs.totalHouseholdNum || '--' }}套</p>
    </div>
    <div class="base-item f-4 m-2">
      <span class="base-item-title">车位数</span>
      <p class="base-item-sub">{{ $attrs.parking || '暂无' }}</p>
    </div>
    <div class="base-item f-4 m-2">
      <span class="base-item-title">停车费</span>
      <p class="base-item-sub">{{ $attrs.parkPrice || '暂无' }}</p>
    </div>
    <div class="base-item f-4 m-2">
      <span class="base-item-title">水电</span>
      <p class="base-item-sub">{{ $attrs.waterSupplyName || '暂无' }}|{{ $attrs.powerSupplyName || '暂无' }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'building-info',
};
</script>

<style lang="scss" scoped>
.base {
  text-align: left;
  border-bottom: 1px solid #eee;
  background-color: white;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  &-item {
    width: 100%;
    display: flex;
    &-title {
      flex: 0 0 6em;
      text-align: left;
      font-size: 12px;
      color: #b2b2b2;
    }

    &-sub {
      flex: 1;
      text-align: left;
      font-size: 12px;
      color: #4a4a4a;
    }
  }
}
</style>
