<template>
  <div class="modal" @click.self="hide">
    <div class="modal-box">
      <span class="icon icon-close modal-box-close btn" @click="hide"></span>
      <component :is="c" v-bind="p" @hide="hide" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    v: Object,
    c: [Function, Object],
    p: Object,
    hide: Function,
  },
  mounted() {
    document.documentElement.style.overflow = 'hidden';
  },
  unmounted() {
    document.documentElement.style.overflow = '';
  },
};
</script>
<style lang="scss">
.modal {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-content: center;
  background-color: rgb(black, 0.6);
  &-box {
    position: relative;
    border-radius: 6px;
    overflow: hidden;
    &-close {
      position: absolute;
      z-index: 99;
      right: 0;
      top: 0;
      font-size: 2rem;
      padding: 2rem;
    }
  }
}
</style>
