<template>
  <div class="carousel">
    <div class="box" :style="`background-image: url('${active.url}')`" @click="preview">
      <img v-if="active.type === 'VR'" class="ani" src="https://static.ajl.cn/web/360.png" alt="play" @click="panorama" />
      <video v-if="active.type === 'video'" ref="video" autoplay controls="controls" class="video" :src="active.videoUrl"></video>
    </div>
    <div class="nav">
      <div class="icon icon-left icon-arrow-left-filling" @click="scroll(0)"></div>
      <div class="icon icon-right icon-arrow-right-filling" @click="scroll(1)"></div>
      <div class="scroll" ref="scroll">
        <div v-for="(a, i) in fileImg" :key="a.url" class="thumb" :style="`background-image: url('${a.url}')`" @click="change(a, i)">
          <span v-if="['video'].includes(a.type)" class="play ani"></span>
          <span v-if="['VR'].includes(a.type)" class="play panorama ani"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'viewerjs/dist/viewer.css';
import Viewer from 'viewerjs';

export default {
  name: 'aCarousel',
  props: { fileImg: Array },
  data() {
    return {
      active: {},
      gallery: null,
    };
  },
  watch: {
    fileImg(v) {
      this.active = v[0];
    },
  },
  methods: {
    scroll(e) {
      let ele = this.$refs.scroll;
      if (e) {
        ele.scrollLeft += 60;
      } else {
        ele.scrollLeft -= 60;
      }
    },
    change(a, i) {
      this.active = { ...a, index: i };
    },
    preview() {
      if (!['image', 'layout'].includes(this.active.type)) return false;
      const imgsEl = document.createElement('div');
      for (const a of this.fileImg) {
        const img = new Image();
        img.src = a.url;
        imgsEl.append(img);
      }
      if (!this.gallery) this.gallery = new Viewer(imgsEl, { title: false });
      this.gallery.view(this.active.index);
    },
    panorama() {
      window.open(this.active.panoramaUrl);
    },
  },
  mounted() {
    this.active = this.fileImg[0] ?? {};
  },
};
</script>

<style lang="scss" scoped>
.carousel {
  box-shadow: 0 0 1em #ccc;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: inline-flex;
  flex-direction: column;
  .box {
    flex: 1;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .video {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .icon {
      width: 40px;
    }
  }

  .nav {
    flex: 0 0 60px;
    display: flex;
    position: relative;
    padding: 1px 20px;

    .scroll {
      flex: 1;
      display: flex;
      overflow: auto;
      position: relative;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .thumb {
      flex: 0 0 60px;
      display: inline-block;
      margin: 0 2px;
      background-size: cover;
      background-position: center;
      position: relative;

      &:hover {
        box-shadow: 0 0 0 1px var(--color) inset;
      }

      .play {
        position: absolute;
        left: 10px;
        right: 10px;
        top: 10px;
        bottom: 10px;
        background-image: url('https://static.ajl.cn/web/063.png');
        background-size: contain;
      }

      .panorama {
        background-image: url('https://static.ajl.cn/web/360.png') !important;
      }
    }

    .icon {
      color: white;
      position: absolute;
      width: 20px;
      background-color: rgba(black, 0.7);
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover {
        background-color: var(--color);
      }

      &-left {
        left: 0;
      }

      &-right {
        right: 0;
      }
    }
  }
}

.ani {
  animation-name: ani;
  animation-duration: 2.6s;
  animation-iteration-count: infinite;
}

@keyframes ani {
  70% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
