export default function (app) {
  app.config.globalProperties.$lazy = function (imgList) {
    //图片懒加载
    const obs = new IntersectionObserver((entries) => {
      for (const a of entries) {
        if (a.isIntersecting) {
          const img = a.target;
          img.src = img.getAttribute('data-src');
          img.removeAttribute('data-src');
          obs.unobserve(img);
        }
      }
    });
    for (const img of imgList) {
      if (img.src) {
        img.setAttribute('data-src', img.src);
        img.src = '';
        obs.observe(img);
      }
    }
  };
}
