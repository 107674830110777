export default [
  {
    path: '/factory',
    name: 'factory',
    component: () => import('@/views/Factory/index'),
    children: [
      {
        name: 'sale',
        component: () => import('@/views/Factory/list'),
      },
      {
        name: 'rent',
        component: () => import('@/views/Factory/list'),
      },
      {
        name: 'first-sale',
        component: () => import('@/views/Factory/list'),
      },
      {
        name: 'first-rent',
        component: () => import('@/views/Factory/list'),
      },
      {
        name: 'building-detail', //一手项目详情
        component: () => import('@/views/Office/buildingDetail'),
      },
      {
        name: 'house-detail',
        component: () => import('@/views/Office/houseDetail'),
      },
      {
        name: 'room-detail',
        component: () => import('@/views/Office/roomDetail'),
      },
      {
        name: 'first-detail',
        component: () => import('@/views/Office/houseDetail'),
      },
      {
        name: 'edifice-detail', //大厦详情
        component: () => import('@/views/Office/buildingDetail'),
      },
    ],
  },
];
