<template>
  <div class="card" v-if="id">
    <div class="card-top">
      <div class="card-top-left">
        <img :src="headPic" alt="" />
        <div class="info">
          <span class="info-name">{{ userName }}</span>
          <span class="info-time">评价时间：{{ createdAt }}</span>
        </div>
      </div>
      <div class="card-top-right">
        <span class="label">{{ score }}分</span>
        <div class="star-box">
          <span class="icon icon-favorite-filling" :style="{ color: 5 >= i ? 'red' : '' }" v-for="i in 5" :key="i"></span>
        </div>
      </div>
    </div>
    <p class="card-content">{{ text }}</p>
    <div class="card-bottom">
      <span class="icon icon-xiaoxi"></span>
      <span class="num" style="display: inline-block; margin-right: 8px">{{ commentNum }}</span>
      <span class="icon icon-good"></span>
      <span>{{ goodsNum }}</span>
    </div>
    <div class="reply-box" v-if="list.length > 0">
      <span class="reply-box-title">全部回复({{ list.length }})</span>
      <div class="reply-box-item" v-for="a in isMore ? list : list.slice(0, 5)" :key="a.id">
        <div class="top">
          <img :src="a.headPic" alt="" />
          <div class="right">
            <span class="name">{{ a.userName }}</span>
          </div>
        </div>
        <p class="content">{{ a.text }}</p>
        <p class="bottom">{{ a.createdAt }}</p>
      </div>
      <p class="more" v-if="list.length > 5">展开全部回复</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'comment-card',
  props: {
    id: Number,
    userName: String,
    headPic: String,
    createdAt: String,
    score: String,
    text: String,
    commentNum: Number,
    goodsNum: Number,
  },
  data() {
    return {
      list: [],
      isMore: false,
    };
  },
  methods: {
    async getList() {
      let url = 'mini-comment/detail-page';
      const { data } = await http(url, { id: this.id });
      this.list = data.list;
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
.card {
  padding: 14px 0;
  border-bottom: 1px solid #e6e6e6;
  &-top {
    display: flex;
    justify-content: space-between;
    &-left {
      display: flex;
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
      .info {
        padding-left: 16px;
        span {
          display: block;
        }
        &-name {
          font-size: 16px;
          font-weight: bold;
          line-height: 28px;
        }
        &-time {
          font-size: 12px;
          color: #888;
        }
      }
    }
    &-right {
      display: flex;
      align-items: center;
      .label {
        padding-right: 7px;
      }
      .star-box {
        display: flex;
        .icon {
          margin-left: 2px;
          font-size: 16px;
          color: #e6e6e6;
        }
      }
    }
  }
  &-content {
    padding: 7px 0 7px 35px;
    font-size: 14px;
    color: #333;
    line-height: 23px;
  }
  &-bottom {
    text-align: right;
    .icon {
      font-size: 14px;
      margin-right: 2px;
      cursor: pointer;
    }
  }
  .reply-box {
    padding: 7px 80px;
    &-title {
      font-size: 14px;
    }
    &-item {
      padding: 7px 0;
      .top {
        display: flex;
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
        .right {
          padding-left: 7px;
          font-size: 12px;
        }
      }
      .content {
        padding: 5px 35px;
      }
      .bottom {
        padding-left: 35px;
        color: #888;
        font-size: 12px;
      }
    }
    .more {
      font-size: 12px;
      color: var(--color);
      text-align: center;
    }
  }
}
</style>
