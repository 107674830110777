<template>
  <div class="warp" :style="styleObj">
    <div ref="map" class="map"></div>
    <div v-if="!hide" class="nearby">
      <ul class="nearby-nav">
        <li v-for="a in nearby.nav" :key="a.name" :class="{ ac: nearby.sub === a.sub }" @click="changeNav(a)">{{ a.name }}</li>
        <li class="icon icon-column-vertical" @click="more = !more"></li>
      </ul>
      <template v-if="more">
        <ul class="nearby-sub">
          <li v-for="a in nearby.sub" :key="a" :class="{ ac: nearby.text === a }" @click="search(a)">{{ a }}</li>
        </ul>
        <div class="list">
          <div v-for="(a, i) in nearby.poiList" :key="a.id" class="card" @click="changeMarker(a.id)">
            <span class="list-name">{{ i + 1 }}丶{{ a.name }}</span>
            <p class="list-describe">{{ a.address }}</p>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader';
export default {
  name: 'AMap',
  props: {
    lng: Number,
    lat: Number,
    latLng: String,
    text: { type: String, default: '🍁' },
    width: { type: String, default: '100%' },
    height: { type: String, default: '100%' },
    hide: Boolean,
  },
  data() {
    return {
      more: false,
      nearby: {
        // '交通', '教育', '医疗', '购物', '生活', '娱乐'
        nav: [
          { name: '交通', sub: ['地铁', '公交'] },
          { name: '医疗', sub: ['医院', '药店'] },
          { name: '购物', sub: ['商场', '超市', '市场'] },
          { name: '生活', sub: ['餐饮', '银行', '公园', '电影院'] },
        ],
        sub: [],
        text: '',
        poiList: [],
      },
    };
  },
  computed: {
    styleObj() {
      return {
        width: this.width,
        height: this.height,
        overflow: 'hidden',
      };
    },
  },
  watch: {
    map() {
      this.initMap();
    },
  },
  methods: {
    changeMarker(id) {
      const markerList = this.placeSearch.render?.markerList?.getMarkers();
      for (let a of markerList ?? []) {
        for (const i in a) if (a[i] === id) a.emit('click');
      }
    },
    changeNav(a) {
      if (this.hide) return;
      this.nearby.sub = a.sub;
      this.search(a.sub[0]);
    },
    search(e) {
      this.nearby.text = e;
      let distance = 1200;
      if (e === '医院') distance = 3000;
      if (e === '景点') distance = 5000;
      this.placeSearch?.render?.clear();
      const ops = { map: this.map, autoFitView: true, type: e };
      this.placeSearch = new this.AMap.PlaceSearch(ops);
      this.placeSearch.searchNearBy('', this.center, distance, (status, result) => {
        this.nearby.poiList = result?.poiList?.pois ?? [];
      });
    },
    async initMap() {
      let lat = this.lat || 31.31;
      let lng = this.lng || 120.6;
      if (this.latLng) lat = this.latLng.split(',')[1] || 31.31;
      if (this.latLng) lng = this.latLng.split(',')[0] || 120.6;
      this.center = [lng, lat];
      this.AMap = await AMapLoader.load({
        key: '4153df3e44ed05588f05f048b662789b',
        plugins: ['AMap.PlaceSearch'],
      });
      const mapEl = this.$refs.map;
      console.log(this.$refs.map);
      this.map = new this.AMap.Map(mapEl, { viewMode: '3D', zoom: 15, center: this.center, resizeEnable: true, mapStyle: 'macaron' });
      const style = {
        borderRadius: '.25rem',
        backgroundColor: 'white',
        width: '10rem',
        boxShadow: '0 2px 6px 0 rgba(114, 124, 245, .5)',
        textAlign: 'center',
        fontSize: '14px',
      };
      const text = new this.AMap.Text({
        text: this.text,
        anchor: 'center',
        draggable: true,
        cursor: 'pointer',
        angle: 10,
        style,
        position: [lng, lat],
      });
      text.setMap(this.map);
      this.changeNav(this.nearby.nav[0]);
    },
  },
  mounted() {
    window._AMapSecurityConfig = window._AMapSecurityConfig ?? {};
    window._AMapSecurityConfig.securityJsCode = '617ddd3b53db34838222a636de836c8a';
    this.initMap();
  },
};
</script>
<style lang="scss" scoped>
.warp {
  position: relative;
  border-radius: 0 0 0.5em 0.5em;

  .map {
    position: absolute !important;
    top: -50px;
    bottom: -20px;
    left: -230px;
    right: 0;
  }
  .nearby {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    z-index: 1;
    width: 260px;
    max-height: 300px;
    border-radius: 0.5em;
    backdrop-filter: blur(5px);
    background-color: rgba(white, 0.3);
    box-shadow: 0 0 1em black;
    overflow: hidden;

    ul {
      display: flex;

      li {
        padding: 0.5em;
        cursor: pointer;
        font-size: 2rem;

        &.ac {
          color: var(--color);
          background-color: rgba(white, 0.7);
        }
      }
    }

    &-nav {
      justify-content: space-evenly;
      background-color: rgba(black, 0.1);
    }

    &-sub {
      border-bottom: 1px solid rgba(black, 0.1);
      padding-left: 1em;

      li {
        padding-left: 0.5em;
        padding-right: 0.5em;
      }
    }

    .list {
      height: 240px;
      overflow: auto;

      .card {
        background-color: rgba(white, 0.3);
        padding: 0 1em;
        margin: 1em 0;
        cursor: pointer;
      }

      &-name {
        font-weight: bold;
        font-size: 2rem;
        display: block;
        text-align: left;
      }

      &-describe {
        color: grey;
        display: block;
        text-align: left;
        font-size: 2rem;
      }
    }
  }
}
</style>
