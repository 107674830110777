<template>
  <ul class="hot-office">
    <li>热门写字楼：</li>
    <li v-for="a in list" :key="a">{{ a }}</li>
  </ul>
</template>

<script>
export default {
  name: 'hot-office',
  data() {
    return {
      list: ['恒润国际广场', '和枫科创园', '金月恒玺国际', '天都大厦', '中盛艾美', '加城大厦', '奕桥Bridge+', '天安云谷'],
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.hot-office {
  display: flex;
  background-color: $bg-gray;
  list-style: none;
  opacity: 0.6;
  li {
    margin: 2rem 1rem;
    font-size: 2rem;
  }
}
</style>
