<template>
  <div class="list d-f1">
    <div class="order">
      <div class="order-total">
        共找到 <b v-color:red class="px-1">{{ data.count }}</b> 套{{ isOne ? '楼盘' : '房源' }}
      </div>
      <div class="order-body">
        <span @click="order(1, !sortType)" :class="{ ac: data.orderType === 1 }">
          <span>默认排序</span>
          <span></span>
        </span>
        <template v-if="isOne">
          <span @click="order(2, !sortType)" :class="{ ac: data.orderType === 2 }">
            <span>均价</span>
            <span class="pl-1">{{ !sortType ? '↑' : '↓' }}</span>
          </span>
          <span @click="order(3, !sortType)" :class="{ ac: data.orderType === 3 }">
            <span>开盘</span>
            <span class="pl-1">{{ !sortType ? '↑' : '↓' }}</span>
          </span>
        </template>
        <template v-else>
          <span @click="order(2, !sortType)" :class="{ ac: data.orderType === 2 }">
            <span>最新发布</span>
            <span class="pl-1">{{ !sortType ? '↑' : '↓' }}</span>
          </span>
          <template v-if="tradeType === 1">
            <span @click="order(3, !sortType)" :class="{ ac: data.orderType === 3 }">
              <span>租金</span>
              <span class="pl-1">{{ !sortType ? '↑' : '↓' }}</span>
            </span>
            <span @click="order(4, !sortType)" :class="{ ac: data.orderType === 4 }">
              <span>面积</span>
              <span class="pl-1">{{ !sortType ? '↑' : '↓' }}</span>
            </span>
          </template>
          <template v-if="tradeType === 2">
            <span @click="order(3, !sortType)" :class="{ ac: data.orderType === 3 }">
              <span>总价</span>
              <span class="pl-1">{{ !sortType ? '↑' : '↓' }}</span>
            </span>
            <span @click="order(4, !sortType)" :class="{ ac: data.orderType === 4 }">
              <span>单价</span>
              <span class="pl-1">{{ !sortType ? '↑' : '↓' }}</span>
            </span>
            <span @click="order(5, !sortType)" :class="{ ac: data.orderType === 5 }">
              <span>面积</span>
              <span class="pl-1">{{ !sortType ? '↑' : '↓' }}</span>
            </span>
          </template>
        </template>
      </div>
    </div>
    <hr class="mb-4" />
    <house-list-card v-for="a in list" :key="a.id" :obj="a" />
    <a-pager @update:page="pager" v-bind="data"></a-pager>
  </div>
</template>

<script>
export default {
  name: 'house-list',
  props: { list: Array, data: Object },
  data() {
    return { tradeType: 1, sortType: false };
  },
  methods: {
    order(orderType, sortType) {
      this.sortType = sortType;
      const obj = { ...this.data };
      obj.orderType = orderType;
      obj.sortType = sortType ? 2 : 1;
      this.$emit('update:data', obj);
      this.$emit('search');
    },
    pager(e) {
      this.$emit('update:data', { ...this.data, page: e });
      this.$emit('search');
    },
  },
  created() {
    if (this.$route.name.includes('sale')) this.tradeType = 2;
  },
};
</script>
<style lang="scss" scoped>
.order {
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  font-size: 2rem;
  background-color: white;

  &-body {
    > span {
      margin: 1rem 2rem;
      padding: 1rem 0;

      span:last-of-type {
        opacity: 0;
      }

      &.ac {
        color: var(--color);
        border-bottom: 2px solid;
        span:last-of-type {
          opacity: 1;
        }
      }
    }
  }
}
</style>
