<template>
  <div class="mini">
    <img :src="url" alt="" />
    <h3 class="title">{{ aliasName }}小程序</h3>
    <span class="tip">随时随地查看新上房源</span>
  </div>
</template>

<script>
export default {
  name: 'min',
  props: { url: String, aliasName: String },
};
</script>

<style lang="scss" scoped>
.mini {
  padding: 20px;
  width: 180px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0 0 1rem #666);
  border-radius: 6px;
  position: relative;
  img {
    width: 125px;
    height: 125px;
  }
  .title {
    font-size: 16px;
    color: #000;
    font-weight: 500;
    margin-top: 10px;
  }
  .tip {
    font-size: 14px;
    color: #b4b6bf;
  }
  &:after {
    content: '';
    border: 20px transparent solid;
    border-bottom-color: white;
    position: absolute;
    top: -40px;
    left: 70px;
    filter: drop-shadow(0 0 1rem #666);
  }
  &:before {
    content: '';
    top: 0;
    left: 0;
    right: 0;
    height: 2rem;
    position: absolute;
    z-index: 11;
    background: #fff;
    border-radius: 6px;
  }
}
</style>
