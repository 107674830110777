<template>
  <div class="banner">
    <div class="banner-body">
      <div class="banner-filter">
        <dl>
          <dt>选择区域</dt>
          <dd v-for="(a, i) in district" :key="a.code" @click="go(i, 'region')">{{ a.name }}</dd>
        </dl>
        <dl>
          <dt>选择面积</dt>
          <div class="range">
            <template v-for="(a, i) in area" :key="a.code">
              <span class="range-item" @click="go(i, 'area')">{{ a }}</span>
            </template>
          </div>
        </dl>
        <div class="banner-filter-btn">查找写字楼</div>
      </div>
      <div class="city">
        <div class="city-name">苏州站</div>
        <div class="city-pinyin">suzhou</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
const router = useRouter();
const district = ref([]);
const area = ref([]);
const getBase = async () => {
  app.dict.BaseData.map((_) => {
    if (_.dicCode === 'areaList') {
      const arr = [];
      _.subData.map((a) => arr.push(a.min));
      area.value = arr;
    }
  });
  district.value = app.dict.AreaData ?? [];
};
const go = (i, m) => {
  let query = {};
  if (m === 'region') query.regionIndex = i;
  if (m === 'area') query.areaIndex = i;
  router.push({ name: 'office.rent', query });
};
getBase();
</script>

<style lang="scss" scoped>
.banner {
  background-image: url('https://static.ajl.cn/web/cbd.jpg');
  height: calc(100vh - 60px);
  min-height: 600px;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  &-body {
    height: inherit;
    min-height: inherit;
    margin: auto;
    width: 1050px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-filter {
    width: 400px;
    height: 350px;
    background-color: white;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    dl {
      display: grid;
      gap: 2rem;
      grid-template-columns: repeat(auto-fill, minmax(6rem, 1fr));
      position: relative;
      padding: 6rem 2rem 2rem;
      margin: 1rem 2rem;

      dt {
        position: absolute;
        top: 2rem;
        left: 2rem;
        font-size: 2rem;
      }

      dd {
        font-size: 2rem;
        opacity: 0.6;
        text-align: left;
        cursor: pointer;

        &:hover {
          color: var(--color);
          opacity: 1;
        }
      }

      .range {
        grid-column-start: 1;
        grid-column-end: 6;
        display: flex;
        justify-content: space-between;

        &-item {
          flex: 1;
          padding-top: 3rem;
          font-size: 2rem;
          position: relative;
          height: 4rem;
          cursor: pointer;
          opacity: 0.6;
          text-align: left;
          left: -1rem;
          &:nth-child(1) {
            text-indent: 1rem;
          }
          &:nth-child(6):after {
            content: '∞';
            position: absolute;
            right: -1rem;
          }
          &:hover {
            color: var(--color);
            &:before {
              background-color: var(--color);
            }
          }
          &:before {
            content: '';
            position: absolute;
            z-index: 0;
            top: -0rem;
            left: 1rem;
            width: 90%;
            height: 2rem;
            background-color: #ccc;
          }
        }
      }
    }
    &-btn {
      width: 90%;
      margin: 4rem auto;
      color: white;
      background-color: var(--color);
      font-size: 2rem;
      padding: 1rem;
      border-radius: 6px;
      cursor: pointer;
    }
  }
  .city {
    color: #eee;
    filter: drop-shadow(2px 2px 6px black);
    &-name {
      font-size: 12rem;
    }
    &-pinyin {
      font-size: 8rem;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      &:before {
        content: '◇';
      }
      &:after {
        content: '◇';
      }
    }
  }
}
</style>
