<template>
  <div class="filter">
    <div class="line">
      <span class="line-name">位置</span>
      <div class="box">
        <ul class="in">
          <li class="btn" :class="{ isActive: obj.region.length === 0 }" @click="obj.region = ''">全市区域</li>
          <template v-for="a in region" :key="a.code">
            <li class="btn" :class="{ isActive: obj.region === a.code }" @click="obj.region = a.code">{{ a.name }}</li>
          </template>
        </ul>
        <hr v-if="obj.region" />
        <ul class="in" v-if="obj.region">
          <template v-for="b in circle.filter((a) => a.parentCode === obj.region)" :key="b.code">
            <li class="btn" :class="{ isActive: obj.cateCircle === b.code }" @click="obj.cateCircle = b.code">{{ b.name }}</li>
          </template>
        </ul>
      </div>
    </div>
    <div class="line" v-if="obj.tradeType === 1">
      <span class="line-name">租价</span>
      <ul class="in">
        <li class="btn" :class="{ isActive: obj.rentPrice.length === 0 }" @click="obj.rentPrice = []">租价不限</li>
        <template v-for="a in dict('rentPrice')" :key="a.code">
          <li class="btn" :class="{ isActive: obj.rentPrice.includes(a) }" @click="obj.rentPrice.toggle(a)">{{ a.name }}</li>
        </template>
      </ul>
    </div>
    <template v-if="obj.tradeType === 2">
      <template v-if="isOne">
        <div class="line">
          <span class="line-name">均价</span>
          <ul class="in">
            <li class="btn" :class="{ isActive: obj.averagePrice.length === 0 }" @click="obj.averagePrice = []">均价不限</li>
            <template v-for="a in dict('averagePrice')" :key="a.code">
              <li class="btn" :class="{ isActive: obj.averagePrice.includes(a) }" @click="obj.averagePrice.toggle(a)">{{ a.name }}</li>
            </template>
          </ul>
        </div>
        <div class="line">
          <span class="line-name">总价</span>
          <ul class="in">
            <li class="btn" :class="{ isActive: obj.totalPrice.length === 0 }" @click="obj.totalPrice = []">总价不限</li>
            <template v-for="a in dict('salePrice')" :key="a.code">
              <li class="btn" :class="{ isActive: obj.totalPrice.includes(a) }" @click="obj.totalPrice.toggle(a)">{{ a.name }}</li>
            </template>
          </ul>
        </div>
      </template>
      <template v-else>
        <div class="line">
          <span class="line-name">售价</span>
          <ul class="in">
            <li class="btn" :class="{ isActive: obj.price.length === 0 }" @click="obj.price = []">售价不限</li>
            <template v-for="a in dict('salePrice')" :key="a.code">
              <li class="btn" :class="{ isActive: obj.price.includes(a) }" @click="obj.price.toggle(a)">{{ a.name }}</li>
            </template>
          </ul>
        </div>
      </template>
    </template>
    <div class="line">
      <span class="line-name">面积</span>
      <ul class="in">
        <li class="btn" :class="{ isActive: obj.buildArea.length === 0 }" @click="obj.buildArea = []">面积不限</li>
        <template v-for="a in areaList" :key="a.code">
          <li class="btn" :class="{ isActive: obj.buildArea.includes(a) }" @click="obj.buildArea.toggle(a)">{{ a.name }}</li>
        </template>
      </ul>
    </div>
    <template v-if="more">
      <div class="line">
        <span class="line-name">户型</span>
        <ul class="in">
          <li class="btn" :class="{ isActive: obj.room.length === 0 }" @click="obj.room = []">户型不限</li>
          <template v-for="a in dict('roomList')" :key="a.code">
            <li class="btn" :class="{ isActive: obj.room.includes(a) }" @click="obj.room.toggle(a)">{{ a.name }}</li>
          </template>
        </ul>
      </div>
      <div class="line" v-if="!isOne">
        <span class="line-name">朝向</span>
        <ul class="in">
          <li class="btn" :class="{ isActive: obj.orientation.length === 0 }" @click="obj.orientation = []">朝向不限</li>
          <template v-for="a in dict('orientation')" :key="a.code">
            <li class="btn" :class="{ isActive: obj.orientation.includes(a) }" @click="obj.orientation.toggle(a)">{{ a.name }}</li>
          </template>
        </ul>
      </div>
      <div class="line" v-if="!isOne">
        <span class="line-name">楼龄</span>
        <ul class="in">
          <li class="btn" :class="{ isActive: obj.buildAge.length === 0 }" @click="obj.buildAge = []">楼龄不限</li>
          <template v-for="a in dict('buildAge')" :key="a.code">
            <li class="btn" :class="{ isActive: obj.buildAge.includes(a) }" @click="obj.buildAge.toggle(a)">{{ a.name }}</li>
          </template>
        </ul>
      </div>
      <div class="line" v-if="!isOne">
        <span class="line-name">楼层</span>
        <ul class="in">
          <li class="btn" :class="{ isActive: obj.floorType.length === 0 }" @click="obj.floorType = []">楼层不限</li>
          <template v-for="a in dict('floorType')" :key="a.code">
            <li class="btn" :class="{ isActive: obj.floorType.includes(a) }" @click="obj.floorType.toggle(a)">{{ a.name }}</li>
          </template>
        </ul>
      </div>
      <div class="line">
        <span class="line-name">装修</span>
        <ul class="in">
          <li class="btn" :class="{ isActive: obj.renovation.length === 0 }" @click="obj.renovation = []">装修不限</li>
          <template v-for="a in dict('renovation')" :key="a.code">
            <li class="btn" :class="{ isActive: obj.renovation.includes(a) }" @click="obj.renovation.toggle(a)">{{ a.name }}</li>
          </template>
        </ul>
      </div>
      <div class="line">
        <span class="line-name">用途</span>
        <ul class="in">
          <li class="btn" :class="{ isActive: obj.buildingUse.length === 0 }" @click="obj.buildingUse = []">用途不限</li>
          <template v-for="a in dict('buildingUse').filter((a) => a.code <= 3)" :key="a.code">
            <li class="btn" :class="{ isActive: obj.buildingUse.includes(a) }" @click="obj.buildingUse.toggle(a)">{{ a.name }}</li>
          </template>
        </ul>
      </div>
      <div class="line" v-if="!isOne">
        <span class="line-name">电梯</span>
        <ul class="in">
          <li class="btn" :class="{ isActive: obj.liftNum.length === 0 }" @click="obj.liftNum = ''">电梯不限</li>
          <template v-for="a in dict('liftNum')" :key="a.code">
            <li class="btn" :class="{ isActive: obj.liftNum === a.code }" @click="obj.liftNum = a.code">{{ a.name }}</li>
          </template>
        </ul>
      </div>
      <div class="line" v-if="obj.tradeType === 2 && !isOne">
        <span class="line-name">权属</span>
        <ul class="in">
          <li class="btn" :class="{ isActive: obj.propertyNature.length === 0 }" @click="obj.propertyNature = []">权属不限</li>
          <template v-for="a in dict('propertyNature')" :key="a.code">
            <li class="btn" :class="{ isActive: obj.propertyNature.includes(a) }" @click="obj.propertyNature.toggle(a)">{{ a.name }}</li>
          </template>
        </ul>
      </div>
      <template v-if="isOne">
        <div class="line">
          <span class="line-name">状态</span>
          <ul class="in">
            <li class="btn" :class="{ isActive: obj.projectStatus.length === 0 }" @click="obj.projectStatus = []">状态不限</li>
            <template v-for="a in dict('projectStatus')" :key="a.code">
              <li class="btn" :class="{ isActive: obj.projectStatus.includes(a) }" @click="obj.projectStatus.toggle(a)">{{ a.name }}</li>
            </template>
          </ul>
        </div>
        <div class="line">
          <span class="line-name">开盘</span>
          <ul class="in">
            <li class="btn" :class="{ isActive: obj.openningDate.length === 0 }" @click="obj.openningDate = ''">开盘不限</li>
            <template v-for="a in dict('openDate')" :key="a.code">
              <li class="btn" :class="{ isActive: obj.openningDate === a.code }" @click="obj.openningDate = a.code">{{ a.name }}</li>
            </template>
          </ul>
        </div>
      </template>
    </template>
    <a-exp v-model="more"></a-exp>
  </div>
</template>

<script>
export default {
  name: 'house-filter',
  data() {
    return {
      region: app.dict.AreaData,
      circle: app.dict.TradingAreaData,
      areaList: this.dict('areaList'),
      more: false,
      obj: {
        page: '',
        pageSize: '',
        tradeType: 0,
        buildingUse: [],
        region: '',
        cateCircle: [],
        houseUse: '',
        renovation: [],
        liftNum: [],
        orientation: [],
        averagePrice: [],
        totalPrice: [],
        propertyNature: [],
        openDate: [],
        buildAge: [],
        openningDate: [],
        projectStatus: [],
        floorType: [],
        room: [],
        buildArea: [],
        price: [], //出售价格
        rentPrice: [], //出租价格
      },
    };
  },
  watch: {
    obj: {
      handler() {
        this.search();
      },
      deep: true,
    },
    'obj.region'() {
      this.obj.cateCircle = '';
    },
  },
  methods: {
    dict(code) {
      return app.dict.BaseData.filter((a) => a.dicCode === code)[0]['subData'];
    },
    search() {
      clearTimeout(this.oisId);
      this.oisId = setTimeout(() => {
        const obj = { ...this.obj };
        obj.room = obj.room.map((a) => a.code).toString();
        obj.buildAge = obj.buildAge.map((a) => a.code).toString();
        obj.orientation = obj.orientation.map((a) => a.code).toString();
        obj.buildingUse = obj.buildingUse.map((a) => a.code).toString();
        obj.renovation = obj.renovation.map((a) => a.code).toString();
        obj.propertyNature = obj.propertyNature.map((a) => a.code).toString();
        obj.floorType = obj.floorType.map((a) => a.code).toString();
        obj.projectStatus = obj.projectStatus.map((a) => a.code).toString();
        if (!obj.buildingUse) obj.buildingUse = '1,2,3';
        this.$emit('search', obj);
      }, 50);
    },
    async init() {
      this.obj.tradeType = this.$route.name.match('sale') ? 2 : 1;
      if (this.$route.query.content) this.obj.content = this.$route.query.content;
    },
  },
  created() {
    this.init();
    if (this.$route.query.region) this.obj.region = this.$route.query.region;
    if (this.$route.query.cateCircle) this.obj.cateCircle = this.$route.query.cateCircle;
    if (this.$route.query.regionIndex) this.obj.region = this.region[this.$route.query.regionIndex].code;
    if (this.$route.query.areaIndex) this.obj.buildArea = [this.areaList[this.$route.query.areaIndex]];
  },
};
</script>
