<template>
  <!--  二手房-->
  <template v-if="qs.title === '租房'">
    <template v-for="a of list" :key="a.id">
      <house-list-card :obj="a" type="rent" />
    </template>
  </template>
  <template v-else-if="qs.title === '二手房源'">
    <template v-for="a of list" :key="a.id">
      <house-list-card :obj="a" type="sale" />
    </template>
  </template>
  <template v-else-if="qs.title === '写字楼出租'">
    <template v-for="a of list" :key="a.id">
      <office-list-card :obj="a" type="rent" />
    </template>
  </template>
  <template v-else-if="qs.title === '写字楼出售'">
    <template v-for="a of list" :key="a.id">
      <office-list-card :obj="a" type="sale" />
    </template>
  </template>
  <template v-else-if="qs.title === '商铺出租'">
    <template v-for="a of list" :key="a.id">
      <shop-list-card :obj="a" type="rent" />
    </template>
  </template>
  <template v-else-if="qs.title === '商铺出售'">
    <template v-for="a of list" :key="a.id">
      <shop-list-card :obj="a" type="sale" />
    </template>
  </template>
  <template v-else-if="qs.title === '厂房土地出租'">
    <template v-for="a of list" :key="a.id">
      <factory-list-card :obj="a" type="rent" />
    </template>
  </template>
  <template v-else-if="qs.title === '厂房土地出售'">
    <template v-for="a of list" :key="a.id">
      <factory-list-card :obj="a" type="sale" />
    </template>
  </template>
  <!--  新房-->
  <template v-else-if="qs.title === '新房'">
    <template v-for="a of list" :key="a.id">
      <house-list-card :obj="a" type="first" />
    </template>
  </template>
  <template v-else-if="qs.title === '一手写字楼出租'">
    <template v-for="a of list" :key="a.id">
      <office-list-card :obj="a" type="first" />
    </template>
  </template>
  <template v-else-if="qs.title === '一手商铺出售'">
    <template v-for="a of list" :key="a.id">
      <shop-list-card :obj="a" type="first" />
    </template>
  </template>
  <template v-else-if="qs.title === '一手厂房土地出租'">
    <template v-for="a of list" :key="a.id">
      <factory-list-card :obj="a" type="first" />
    </template>
  </template>
  <a-pager @update:page="pager" v-bind="obj" :topEl="topEl" />
</template>

<script setup>
import { ref } from 'vue';
const p = defineProps({ qs: Object, topEl: Element, id: Number });
const list = ref([]);
const obj = ref({ page: 1, pageSize: 5 });
async function getList() {
  Object.assign(obj.value, p.qs.where);
  if (p.id) obj.value.userId = p.id;
  if (/新房|一手/.test(p.qs.title)) {
    const rs = await http('office/project-list', obj.value);
    list.value = rs.list;
    Object.assign(obj.value, rs.data);
  } else {
    const rs = await http('office/list', obj.value);
    list.value = rs.list;
    Object.assign(obj.value, rs.data);
  }
}
async function pager(e) {
  obj.value.page = e;
  await getList();
}
getList();
</script>

<style scoped></style>
