<template>
  <div class="fd card" @click="detail">
    <div class="card-img">
      <img :src="obj.fileImg" alt="封面" />
      <img v-if="obj.panorama" src="https://static.ajl.cn/web/360.png" class="panorama" alt="全景图标" />
    </div>
    <div class="card-body">
      <div class="line">
        <span class="line-name" v-if="$route.fullPath.includes('first') || type === 'first'">{{ obj.title }}</span>
        <span class="line-name" v-else>{{ obj.buildingName }}</span>
        <span class="line-price" v-if="$route.fullPath.includes('first') || type === 'first'">
          <span>{{ $cov(obj.priceSingleSet).num || '-' }}</span>
          <sub>{{ $cov(obj.priceSingleSet).unit }}</sub>
        </span>
        <span class="line-price" v-else>
          <template v-if="$route.name.includes('rent') || type === 'rent'">
            <span>{{ $cov(obj.rentPrice).num || '-' }}</span>
            <sub>{{ $cov(obj.rentPrice).unit }}</sub>
          </template>
          <template v-else>
            <span>{{ $cov(obj.salePrice).num || '-' }}</span>
            <sub>{{ $cov(obj.salePrice).unit }}</sub>
          </template>
        </span>
      </div>
      <div class="line">
        <span class="icon icon-office"></span>
        <span>{{ obj.buildArea || obj.area }}</span>
        <span>·</span>
        <span>{{ obj.buildingUseName }}</span>
      </div>
      <div class="line">
        <span class="icon icon-map"></span>
        <span class="line-text">
          <span class="btn">{{ obj.regionName }}</span> - <span class="btn">{{ obj.cateCircleName }}</span>
        </span>
      </div>
      <div class="line">
        <span class="icon icon-chart-pie"></span>
        <span>{{ obj.releaseTime }} · 近30天内共有{{ obj.collectNum }}人关注</span>
      </div>
      <div class="line">
        <template v-if="obj.division?.length">
          <span class="line-area btn" v-for="a in obj.division.slice(0, 6)" :key="a" @click="childDetail(a.id)">{{ a.area }}</span>
          <span class="line-area btn line-area-more" @click="detail">···</span>
        </template>
        <span class="btn more">查看详情</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'factory-list-card',
  props: { obj: Object, type: String },
  methods: {
    detail() {
      let from = this.$route.name ?? '';
      if (this.type === 'sale' || this.type === 'rent') from = this.root.name + '.' + this.type;
      if (this.type === 'first') from = this.root.name + '.' + 'first-rent';
      const query = { id: this.obj.id, from };
      if (query.from.includes('first')) return this.$router.push({ name: 'factory.building-detail', query });
      const { href } = this.$router.resolve({ name: 'factory.house-detail', query });
      window.open(href, '_blank');
    },
    childDetail(id) {
      let name = 'factory.room-detail';
      let from = this.$route.name ?? '';
      if (this.type === 'sale' || this.type === 'rent') from = this.root.name + '.' + this.type;
      if (this.type === 'first') from = this.root.name + '.' + 'first-rent';
      if (from.includes('first')) name = 'factory.first-detail';
      const { href } = this.$router.resolve({ name, query: { id, from } });
      window.open(href, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  flex: 1;
  height: 230px;
  margin-bottom: 2rem;
  display: grid;
  padding: 2rem;
  grid-template-columns: 230px 1fr;
  place-content: center;
  align-content: stretch;
  border-bottom: 1px solid rgba(black, 0.15);
  cursor: pointer;
  &:hover {
    background-color: white;
    border-bottom-color: transparent;
  }

  &-img {
    border-radius: 6px;
    overflow: hidden;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .panorama {
      width: 30px;
      height: 30px;
      position: absolute;
      left: 10px;
      bottom: 10px;
      animation-name: ani;
      animation-duration: 2.6s;
      animation-iteration-count: infinite;
    }
  }

  &-body {
    padding: 1rem 2rem;

    &:hover {
      .line > .more {
        opacity: 1;
      }
    }

    .line {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;
      font-size: 2rem;

      &-name {
        font-size: 3rem !important;
      }

      &-price {
        margin-left: auto;
        display: flex;
        align-items: flex-end;
        color: red;

        span {
          margin-right: 0.5rem;
          font-weight: bold;
          font-size: 4rem;
        }

        sub {
          font-size: 2rem;
          padding-bottom: 6px;
        }
      }

      &-area {
        padding: 0.5rem 1rem;
        border: 1px solid #ccc;
        margin: 0 -1px -1px 0;
        opacity: 0.6;
        &:hover {
          color: var(--color);
        }
        &:nth-child(1) {
          border-radius: 0.5rem 0 0 0.5rem;
        }

        &:nth-last-child(2) {
          font-weight: bold;
          border-radius: 0 0.5rem 0.5rem 0;
        }

        &:hover {
          border-color: var(--color);
        }
      }

      .icon {
        margin-right: 0.5rem;
        font-size: 3rem;
      }

      .more {
        @extend .dye;
        margin-left: auto;
        opacity: 0;
        padding: 1rem 2rem;
        border-radius: 6px;
      }
    }
  }
}
@keyframes ani {
  70% {
    transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(360deg) scale(1.2);
  }
}
</style>
