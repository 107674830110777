<template>
  <div class="list d-f1">
    <factory-list-card v-for="a in list" :key="a.id" :obj="a" />
    <a-pager @update:page="pager" v-bind="data"></a-pager>
  </div>
</template>

<script>
export default {
  name: 'factory-list',
  props: { list: Array, data: Object },
  methods: {
    pager(e) {
      this.$emit('update:data', { ...this.data, page: e });
      this.$emit('search');
    },
  },
};
</script>
